import React, { useEffect, useState } from 'react';
import { useStytch } from '@stytch/nextjs';

// from https://stytch.com/docs/sdks/javascript-sdk#session-management_methods_refresh
// this isn't really needed anymore with our initial session length of 3 days, but
// leaving in case we want to use it in the future
export const RefreshSession = () => {
  const stytchClient = useStytch();
  const maxRefreshes = 50;
  const [numberOfRefreshes, setNumberOfRefreshes] = useState(0);

  useEffect(() => {
    console.log('trying to refresh session...');
    const refresh = () => {
      if (stytchClient.session.getSync() && numberOfRefreshes < maxRefreshes) {
        console.log('refreshing!');
        stytchClient.session.authenticate({
          session_duration_minutes: 120,
        });
        setNumberOfRefreshes(numberOfRefreshes + 1);
      }
    };
    // Refresh session every 2 days
    const interval = setInterval(refresh, 1000 * 60 * 60 * 24 * 2);
    return () => clearInterval(interval);
  }, [numberOfRefreshes, stytchClient]);

  return <></>;
};
