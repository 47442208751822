import { Switch } from '@headlessui/react';

interface ToggleProps {
  enabled: boolean;
  setEnabled: (active: boolean) => void;
  disabled?: boolean;
  small?: boolean;
}

export default function Toggle({ enabled, setEnabled, disabled, small }: ToggleProps) {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={`${enabled ? 'bg-therablue' : 'bg-gray-200'} relative inline-flex items-center rounded-full${
        disabled ? ' cursor-default opacity-60' : ' cursor-pointer'
      }${small ? ' h-5 w-10' : ' h-6 w-11'}`}
      disabled={disabled}
    >
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block transform rounded-full bg-white transition ${small ? 'h-3 w-3' : 'h-4 w-4'}`}
      />
    </Switch>
  );
}
