import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useStytch, useStytchSession } from '@stytch/nextjs';
import Head from 'next/head';

import LoadingScreen from 'components/container-components/LoadingScreen';
import { THERA_SuperAdmin, THERA_SkipEmailVerify, dummyEmail } from 'pages/super-admin';
import { AuthRoutes } from 'routes';
import { useUser } from 'context/user';
import { captureErrorInSentry, logError } from 'utils/error';
import theraToast from 'components/generic-components/toast/TheraToast';

const OAUTH_TOKEN = 'oauth';
const MAGIC_LINKS_TOKEN = 'magic_links';
const LOGIN_TOKEN = 'login';
export const SESSION_DURATION_MINUTES = 60 * 24 * 3;

const SuperAdminPassword = process.env.NEXT_PUBLIC_THERA_SUPER_ADMIN_PWD;
const defaultErrorMessage = 'Something went wrong. Redirecting to login page...';

const errorMessageByType = {
  magic_link_not_found: 'The login link you used is invalid or has expired. Redirecting to login page...',
  oauth_token_not_found: 'The login link you used is invalid or has expired. Redirecting to login page...',
  oauth_config_not_found: 'The login method you used is invalid. Redirecting to login page...',
  oauth_user_registration_not_found: 'The login link you used is invalid or has expired. Redirecting to login page...',
};

const authenticationOptions = {
  session_duration_minutes: SESSION_DURATION_MINUTES,
};

const Authenticate = () => {
  const stytch = useStytch();
  const { logout } = useUser();
  const { session } = useStytchSession();
  const router = useRouter();
  const superAdminPwd = router?.query?.superAdmin?.toString();
  const stytch_token_type = router?.query?.stytch_token_type?.toString();
  const token = router?.query?.token?.toString();
  const email = router?.query?.email?.toString();

  useEffect(() => {
    if (token) {
      (async () => {
        // logout existing session
        if (session) {
          await logout(false);
        }

        let authenticationPromise;
        if (stytch_token_type === OAUTH_TOKEN) {
          authenticationPromise = stytch.oauth.authenticate(token, authenticationOptions);
        } else if (stytch_token_type === MAGIC_LINKS_TOKEN || stytch_token_type === LOGIN_TOKEN) {
          authenticationPromise = stytch.magicLinks.authenticate(token, authenticationOptions);
        } else {
          const err = new Error('Invalid stytch_token_type');
          captureErrorInSentry(err, { token, stytch_token_type }, 'Invalid stytch_token_type');
          return;
        }

        authenticationPromise
          .then((_res) => {
            // Remove query parameters from the URL
            const { pathname } = router;
            if (superAdminPwd === SuperAdminPassword) {
              try {
                localStorage.setItem(THERA_SuperAdmin, 'true');
                localStorage.setItem(THERA_SkipEmailVerify, dummyEmail);
              } catch (error) {
                console.error(error);
                theraToast.error('Something went wrong while setting super admin mode');
              }
              // reload page to reflect super admin mode
              window.location.replace('/');
            } else {
              router.replace(pathname, pathname, { shallow: true });
            }
          })
          .catch((error) => {
            logError(error);
            // if the email is known, then resend the magic link
            if (email) {
              stytch.magicLinks.email
                .send(email, {
                  login_magic_link_url: window.location.origin + AuthRoutes.Authenticate + '?email=' + email,
                  login_expiration_minutes: SESSION_DURATION_MINUTES,
                })
                .then(() => {
                  theraToast.error(
                    'This link is expired. A new login link has been sent to your email. Please use that.',
                    {
                      id: 'authentication-failed',
                      duration: 10000,
                    },
                  );
                })
                .catch((err) => {
                  logError(err);
                  theraToast.error('Failed to send login link. Please try again or contact support', {
                    id: 'authentication-failed',
                    duration: 4000,
                  });
                });
              // redirect to login page in 2 seconds
              setTimeout(() => {
                router.push(AuthRoutes.SignIn);
              }, 3000);
              return;
            }
            if (session) {
              // router.push('/');
              return;
            }
            const maybeUsedOrExpiredMagicLink = error?.error_type === 'unable_to_auth_magic_link';

            if (maybeUsedOrExpiredMagicLink) {
              router.push(AuthRoutes.SignIn);
              theraToast.error(
                'The login link is invalid. Please enter email below and click on Forgot password to receive new link on email.',
                {
                  id: 'authentication-failed',
                  duration: 6000,
                },
              );
              return;
            }
            theraToast.error(
              `[Failed to authenticate]: ${errorMessageByType[error.error_type] || defaultErrorMessage}`,
              {
                id: 'authentication-failed',
                duration: 4000,
              },
            );
            // redirect to login page in 2 seconds
            setTimeout(() => {
              router.push(AuthRoutes.SignIn);
            }, 3000);
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stytch_token_type, token]);

  return (
    <div>
      <Head>
        <title>Authentication | Thera</title>
      </Head>
      <LoadingScreen />
    </div>
  );
};

export default Authenticate;
