export function generateRandomString(length = 6): string {
  let result = '';
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const removeUnderscoresFromString = (input: string, capitalizeFirstWordFirstLetter = true) => {
  if (!input) {
    return '';
  }
  // Split the string by underscores
  const words = input.split('_');
  // Capitalize the first letter of the first word only if the flag is true
  if (capitalizeFirstWordFirstLetter) words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  // Join the words with spaces
  return words.join(' ');
};

export const capitalizeFirstLetter = (value: string) => {
  if (!value || typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeOnlyFirstLetter = (weekday: string) => {
  return weekday.charAt(0).toUpperCase() + weekday.slice(1).toLowerCase();
};

export const convertToTitleCase = (value: string) => {
  if (!value) return '';
  return value
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getPlural = (count: number, singularForm: string) => {
  if (count > 1) {
    return singularForm + 's';
  }
  return singularForm;
};
export function removeUnderscoreAndCapitalizeFirstLetters(inputString: string): string {
  const words = inputString.split('_');
  const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return formattedWords.join(' ');
}

export function getInitialsFromName(name: string): string {
  if (!name) {
    return '';
  }
  const nameParts = name.trim().split(' ');
  let initials = '';
  if (nameParts.length === 1) {
    // If there's only one part in the name (likely the first name), return its first two letters.
    initials = nameParts[0].substring(0, 2);
  } else if (nameParts.length >= 2) {
    // If there are at least two parts (first name and last name), return the initials of both.
    const firstNameInitial = nameParts[0].charAt(0);
    const lastNameInitial = nameParts[nameParts.length - 1].charAt(0);
    initials = firstNameInitial + lastNameInitial;
  } else {
    // Handle the case where there are no name parts provided.
    return '';
  }
  return initials.toUpperCase();
}

export const maskCharacters = (input: string, charactersToIgnore = 4) => {
  if (!input) return '';

  const regexPattern = new RegExp(`\\w(?=\\w{${charactersToIgnore}})`, 'g');
  return input.replace(regexPattern, 'X');
};

export function containsNumbers(inputString: string): boolean {
  const regex = /[0-9]/; // Regular expression to match any digit (0-9)

  // Use the test method to check if the input string contains any numbers
  return regex.test(inputString);
}
