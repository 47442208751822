import LoadingSpinner from "icons/LoadingSpinner";

export default function LoadingScreen() {
  return (
    <div className="flex h-screen items-center justify-center">
      <LoadingSpinner pageLoader />
    </div>
  );
}

export const Loader = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};
