import React, { useEffect, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useNavigation } from './NavigationContext';
import { NavItem } from './navigations';
import { MainNavItem } from './MainNavItem';
import MainNavRightSide from './MainNavRightSide';
import { useUser } from 'context/user';
import { useCompany } from 'context/company';
import CopyableText from 'components/generic-components/copyableText';
import { EmployeeType } from 'lib/constants';
import { usePermissions } from 'hooks/usePermissions';
import { showIDToCopy } from 'utils/user';
import { checkIfManagerWithoutTeams } from 'utils/affiliations';

const Navbar = ({ isFallback = false }: { isFallback?: boolean }) => {
  const router = useRouter();
  const { user, roleType, affiliationsByCompany, currentCompany, permissionsVersion } = useUser() || {};
  const { company } = useCompany() || {};
  const { isOrgAdmin } = usePermissions();
  const isWorker = roleType === 'worker';
  const isManager = roleType === 'manager';
  const isManagerWithoutTeams = checkIfManagerWithoutTeams(roleType, affiliationsByCompany, currentCompany);

  const {
    navigation,
    activeSubNav: _activeSubNav,
    setActiveMainNav,
    setActiveSubNav,
    setActiveSubSideNav,
  } = useNavigation();

  const isZealEmployee = isWorker && user?.userEmploymentType === EmployeeType.US_EMPLOYEE;
  const isContractor = isWorker && user?.userEmploymentType === EmployeeType.CONTRACTOR;
  const isEOREmployee = isWorker && user?.userEmploymentType === EmployeeType.EMPLOYEE;
  const isW2Admin = useMemo(() => isManager && !!company?.w2Payroll?.zealId, [isManager, company]);

  const showCompanyIDToCopy = useMemo(() => {
    return showIDToCopy(company);
  }, [company]);

  const handleFallbackRouting = (href: string) => {
    if (!isFallback) return;
    window.location.replace(`${window.location.origin}${href}`);
  };

  useEffect(() => {
    let newActiveMainNav = -1;
    let newActiveSubNav = -1;
    let newActiveSubSideNav = -1;

    navigation?.forEach((nav: NavItem, i) => {
      if (!nav.subNavs) {
        if (nav.href === router.pathname) {
          newActiveMainNav = i;
          return;
        }
      } else {
        nav.subNavs.forEach((subNav, j) => {
          if (nav.isTabbedQuery) {
            if (subNav.href === router.asPath) {
              newActiveMainNav = i;
              newActiveSubNav = j;
              return;
            }
          }
          if (subNav.href === router.pathname) {
            newActiveMainNav = i;
            newActiveSubNav = j;
            return;
          }

          subNav?.subNavs?.forEach((subSideNav, k) => {
            if (subSideNav.href === router.pathname) {
              newActiveMainNav = i;
              newActiveSubNav = j;
              newActiveSubSideNav = k;
              return;
            }
          });
        });
      }
    });

    setActiveMainNav(newActiveMainNav);
    setActiveSubNav(newActiveSubNav);
    setActiveSubSideNav(newActiveSubSideNav);
  }, [navigation, router, setActiveMainNav, setActiveSubNav, setActiveSubSideNav]);

  return (
    <>
      <div id="mainNav-wrapper" className="h-[64px] border-b border-neutral-200 bg-white px-6 py-3">
        <div className={`flex w-full  items-center justify-between`}>
          <div className="flex items-center">
            <div className="mr-6 flex flex-shrink-0 items-center">
              <Link href="/home" onClick={() => handleFallbackRouting('/home')}>
                <img className="mr-3 h-6 w-auto" src="/logo/thera-logo.svg" alt="Thera-logo" />
              </Link>
            </div>
            <ul className="flex items-center space-x-3">
              {navigation?.map((item, index) => {
                if (item.showInMainNav === false) {
                  return null;
                }
                if (item.onlyShownTo === 'managerWithTeams' && isManagerWithoutTeams && permissionsVersion === 2) {
                  return null;
                }
                if (item.onlyShownTo === 'employee' && !isZealEmployee) {
                  return null;
                }
                if (item.onlyShownTo === 'eorEmployee' && !isEOREmployee) {
                  return null;
                }
                if (item.onlyShownTo === 'contractor' && isEOREmployee) {
                  return null;
                }
                if (item.onlyShownTo === 'contractor' && !isContractor) {
                  return null;
                }
                if (item.onlyShownTo === 'w2Admin' && !isW2Admin) {
                  return null;
                }
                if (item.onlyShownTo === 'orgAdmin' && !isOrgAdmin && roleType === 'manager') {
                  return null;
                }
                return <MainNavItem key={item.name} item={item} index={index} />;
              })}
            </ul>
            {showCompanyIDToCopy && <CopyableText text={`${company?.companyID}`} containerClassName="ml-4" />}
          </div>
          <MainNavRightSide isFallback={isFallback} handleFallbackRouting={handleFallbackRouting} />
        </div>
      </div>
    </>
  );
};

export default Navbar;
