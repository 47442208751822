import { Tooltip } from 'flowbite-react';
import CopyIcon from 'icons/CopyIcon';
import React, { useState, useEffect } from 'react';

interface IProps {
  text: string;
  showCopyIcon?: boolean;
  containerClassName?: string;
  showBorder?: boolean;
  textClassName?: string;
}

const CopyableText: React.FC<IProps> = ({
  text,
  containerClassName,
  textClassName,
  showCopyIcon = true,
  showBorder = true,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isDemoEnv, setIsDemoEnv] = useState(true);

  const handleCopyClick = (e) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(text)
      .then(() => setIsCopied(true))
      .catch((err) => console.error('Could not copy text: ', err));
  };

  useEffect(() => {
    if (isCopied) {
      const timeoutId = setTimeout(() => {
        setIsCopied(false);
      }, 1200);

      return () => clearTimeout(timeoutId);
    }
  }, [isCopied]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (window.location.hostname === 'demo.app.getthera.com') setIsDemoEnv(true);
    else setIsDemoEnv(false);
  }, []);

  if (isDemoEnv) {
    return null;
  }

  return (
    <div
      onClick={handleCopyClick}
      className={`flex items-center${containerClassName ? ` ${containerClassName}` : ''}`}
      role="button"
    >
      <Tooltip content="Click to Copy" className="!text-xs font-medium shadow-md">
        <span
          className={`${
            showBorder ? 'rounded-md border border-dotted border-therablue-light p-1 ' : ''
          }flex cursor-pointer items-center text-xs ${textClassName ? textClassName : ''}`}
        >
          {text}
          {showCopyIcon && <CopyIcon className="ml-1" />}
        </span>
      </Tooltip>
      {isCopied && <span className={`ml-2 text-xs ${textClassName ? textClassName : ''}`}>Copied!</span>}
    </div>
  );
};

export default CopyableText;
