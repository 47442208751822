export const invoiceStatusTextStyle = {
  OPEN: { text: 'Open', style: 'bg-gray-100 text-gray-600' },
  SUCCEEDED: { text: 'Paid', style: 'bg-green-100 text-green-600' },
  PAID: { text: 'Paid', style: 'bg-green-100 text-green-600' },
  VOID: { text: 'Void', style: 'bg-red-100 text-red-600' },
  FAILED: { text: 'Failed', style: 'bg-red-100 text-red-600' },
  PROCESSING: { text: 'Processing', style: 'bg-orange-100 text-orange-600' },
  PAYMENT_FAILED_BUT_TRANSFER_SUCCEEDED: { text: 'Paid', style: 'bg-green-100 text-green-600' },
  PAYMENT_SUCCEEDED_BUT_TRANSFER_FAILED: { text: 'Failed', style: 'bg-red-100 text-red-600' },
  TRANSFER_SUCCEEDED: { text: 'Paid', style: 'bg-green-100 text-green-600' },
  TRANSFER_FAILED: { text: 'Failed', style: 'bg-red-100 text-red-600' },
  OVERDUE: { text: 'Overdue', style: 'border border-red-200 text-red-600' },
  DUE: { text: 'Due', style: 'border border-neutral-200 text-neutral-600' },
  NOT_DUE: { text: 'Not Due', style: 'border border-neutral-200 text-neutral-600' },
};

export const workerTransactionTextStyle = {
  PENDING: {
    text: 'Pending',
    style: 'text-amber-600 bg-amber-100',
  },
  POSTED: {
    text: 'Completed',
    style: 'text-green-600 bg-green-100',
  },
};

export const chargeStatusTextStyle = {
  succeeded: { text: 'Paid', style: 'bg-green-100 text-green-600' },
  failed: { text: 'Failed', style: 'bg-red-100 text-red-600' },
  processing: { text: 'Processing', style: 'bg-orange-100 text-orange-600' },
};

export const enum ContractType {
  FIXED_RATE = 'FIXED_RATE',
  FLEXIBLE_RATE = 'FLEXIBLE_RATE',
  MILESTONE = 'MILESTONE',
}

export const contractTypeLabel = {
  FLEXIBLE_RATE: 'PAY AS YOU GO',
  FIXED_RATE: 'FIXED RATE',
  MILESTONE: 'MILESTONE',
};

export const ItemTypeInvoices = {
  EXPENSE: 'EXPENSE',
  COMMISSION: 'COMMISSION',
  BONUS: 'BONUS',
  OTHER: 'OTHER',
  OVERTIME: 'OVERTIME',
};

export const enum InvoiceStatus {
  PROCESSING = 'PROCESSING',
  OPEN = 'OPEN',
  VOID = 'VOID',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PAYMENT_FAILED_BUT_TRANSFER_SUCCEEDED = 'PAYMENT_FAILED_BUT_TRANSFER_SUCCEEDED', // success
  PAYMENT_SUCCEEDED_BUT_TRANSFER_FAILED = 'PAYMENT_SUCCEEDED_BUT_TRANSFER_FAILED', // failure
  TRANSFER_FAILED = 'TRANSFER_FAILED', // failure
  TRANSFER_SUCCEEDED = 'TRANSFER_SUCCEEDED', // success
}

export const enum InvoiceApprovalStatus {
  UNAPPROVED = 'UNAPPROVED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  CANCELLED = 'CANCELLED',
}

export const payrollStatusTextStyle = {
  processed: { text: 'Processed', style: 'bg-green-100 text-green-600' },
  'pre-processed': { text: 'Processed', style: 'bg-green-100 text-green-600' },
  pending: { text: 'Pending', style: 'bg-orange-100 text-orange-600' },
  voided: { text: 'Voided', style: 'bg-red-100 text-red-600' },
  failed: { text: 'Failed', style: 'bg-red-100 text-red-600' },
};

export const enum PayrollHistoryStatus {
  PROCESSED = 'processed',
  PENDING = 'pending',
  PREPROCESSED = 'pre-processed',
  VOIDED = 'voided',
  FAILED = 'failed',
}

export const enum StripeOnboardingStatus {
  STRIPE_LINK_COMPLETED = 'STRIPE_LINK_COMPLETED',
  STRIPE_ACCOUNT_MADE = 'STRIPE_ACCOUNT_MADE',
  INVITED = 'INVITED',
}

export const enum EmployeeCheckApprovalStatus {
  APPROVED = 'APPROVED',
  ACTION_NEEDED = 'ACTION_NEEDED',
}

export const employeeCheckApprovalStatusTextStyle = {
  APPROVED: { text: 'Approved', style: 'bg-green-100 text-green-600' },
  ACTION_NEEDED: { text: 'Action Needed', style: 'bg-amber-100 text-amber-700' },
};

export const enum EmployeeType {
  EMPLOYEE = 'EMPLOYEE',
  US_EMPLOYEE = 'US_EMPLOYEE',
  CONTRACTOR = 'CONTRACTOR',
}

export const EmployeeTypeTextStyle = {
  EMPLOYEE: { text: 'Employee', style: 'bg-neutral-100' },
  US_EMPLOYEE: { text: 'US Employee', style: 'bg-neutral-100' },
  CONTRACTOR: { text: 'Contractor', style: 'bg-white' },
};

export const payFrequencyDropdownValuesWithoutDaily = [
  { label: 'Weekly', id: 'weekly', value: 'weekly' },
  { label: 'Biweekly', id: 'biweekly', value: 'biweekly' },
  { label: 'Semimonthly', id: 'semimonthly', value: 'semimonthly' },
  { label: 'Monthly', id: 'monthly', value: 'monthly' },
];

export const payFrequencyDropdownValues = [
  { label: 'Daily', id: 'daily', value: 'daily' },
  ...payFrequencyDropdownValuesWithoutDaily,
];

export const enum OnboardingStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
}
export const onboardingStatusTextStyle = {
  COMPLETE: { text: 'Onboarded', style: 'bg-green-100 w-fit' },
  INCOMPLETE: { text: 'Onboarding Incomplete', style: 'bg-amber-100 w-fit' },
  INACTIVE: { text: 'Inactive', style: 'bg-gray-100 w-fit text-neutral-500' },
};

export const enum EmployeePaystubStatus {
  PROCESSED = 'processed',
  PENDING = 'pending',
  PREPROCESSED = 'pre-processed',
  VOIDED = 'voided',
  FAILED = 'failed',
}

export const employeePaystubStatusTextStyle = {
  processed: { text: 'Processed', style: 'bg-green-100 text-green-600' },
  'pre-processed': { text: 'Processed', style: 'bg-green-100 text-green-600' },
  pending: { text: 'Pending', style: 'bg-orange-100 text-orange-600' },
  voided: { text: 'Voided', style: 'bg-red-100 text-red-600' },
  failed: { text: 'Failed', style: 'bg-red-100 text-red-600' },
};

export const US_States = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  DC: 'District of Columbia',
  AS: 'American Samoa',
  GU: 'Guam',
  MP: 'Northern Mariana Islands',
  PR: 'Puerto Rico',
  UM: 'U.S. Minor Outlying Islands',
  VI: 'U.S. Virgin Islands',
};

export const zealEmployeeStatusMapping = {
  live: 'Active',
  leave: 'On Leave',
  terminated: 'Terminated',
};

export const deductions = {
  '401k': {
    name: '401k',
    type: 'preTax',
    description:
      'A 401(k) plan is a tax-qualified, defined-contribution pension account that provides for pretax retirement savings.',
  },
  hsa: {
    name: 'HSA',
    type: 'preTax',
    description:
      'A health savings account (HSA) is a tax-advantaged medical savings account enrolled in a high-deductible health plan. Funds accumulate and can be used to pay for qualified medical expenses without tax liabilities.',
  },
  garnishment: {
    name: 'Garnishment',
    type: 'postTax',
    description:
      'A Wage Garnishment is a court-mandated withholding of an employee’s earnings from a paycheck for use toward debts. Common sources include: Child support, federal debt, state debt, student loans, and credit card debt. This serves as a post-tax deduction.',
  },
  miscellaneous: {
    name: 'Miscellaneous',
    type: 'postTax',
    description: 'Use this type for a generic post-tax deduction on the employee check.',
  },
  section_125: {
    name: 'Section 125',
    type: 'preTax',
    description:
      'A benefit belonging to a Section 125 plan that allows employees to choose between different types of qualified benefits.',
  },
  roth_401k: {
    name: 'Roth 401k',
    type: 'postTax',
    description:
      "The Roth 401(k) is a defined-contribution pension account that requires that the income tax be paid immediately, so the employee's real net income is reduced by the amount earmarked for savings. Employers can match employee contributions to a roth 401k, but those employer contributions must be matched in a separate traditional 401k deduction.",
  },
  '403b': {
    name: '403(b)',
    type: 'preTax',
    description:
      "A 403(b) plan (tax-sheltered annuity plan or TSA) is a retirement plan offered by public schools, non-profit organizations, and government entities. It's similar to a 401(k) plan maintained by a for-profit entity. Just as with a 401(k) plan, a 403(b) plan lets employees defer some of their salary into individual accounts.",
  },
  simple_ira: {
    name: 'Simple IRA',
    type: 'preTax',
    description:
      'A SIMPLE IRA plan (Savings Incentive Match PLan for Employees) allows employees and employers to contribute to traditional IRAs set up for employees by usually smaller businesses or companies.',
  },
  roth_ira: {
    name: 'Roth IRA',
    type: 'postTax',
    description:
      'A Individual Retirement Account to which you contribute after-tax dollars. Your contributions and earnings can grow tax-free, and you can withdraw them tax- and penalty-free after age 59½ and once the account has been open for five years.',
  },
};

export const earnings = {
  reimbursements: {
    name: 'Reimbursements',
    type: 'reimbursement',
    description:
      'A reimbursement is a payment made to an employee for expenses incurred on behalf of the company. This can include travel expenses, office supplies, or other expenses that the employee paid for out of pocket.',
  },
};

export const rateMap = {
  HOURLY: ' / hour',
  DAILY: ' / day',
  WEEKLY: ' / week',
  BIWEEKLY: ' / 2 weeks',
  MONTHLY: ' / month',
  MILESTONE: ' / task',
  SEMIMONTHLY: ' / every 15 days',
};

export const contractTypeMap = {
  FIXED_RATE: 'fixed',
  FLEXIBLE_RATE: 'pay as you go',
  MILESTONE: 'milestone',
};

export const wageTypeToWorkUnitType = {
  HOURLY: 'hour',
  DAILY: 'day',
  WEEKLY: 'week',
  MONTHLY: 'month',
  MILESTONE: 'task',
};

export const wageTypeMapping = {
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  BIWEEKLY: 'Bi-Weekly',
  MONTHLY: 'Monthly',
  SEMIMONTHLY: 'Semi Monthly',
  MILESTONE: 'Milestone',
};

export const employeeStatusLabel = {
  ACTIVE: 'Active' as const,
  INACTIVE: 'Inactive' as const,
};

export enum KycDocumentType {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  RESIDENT_CARD = 'RESIDENT_CARD',
  UNKNOWN = 'UNKNOWN',
}

export enum AiPriseVerificationStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  REVIEW = 'REVIEW',
  UNKNOWN = 'UNKNOWN',
}

export enum WithdrawalAccountType {
  BANK_TRANSFER = 'BANK_TRANSFER',
  WISE = 'WISE',
  PAYONEER = 'PAYONEER',
}

export enum TheraTeamRoles {
  role_admin = 'role_admin',
  role_people_manager = 'role_people_manager',
  role_people_manager_and_payer = 'role_people_manager_and_payer',
  role_viewer_sensitive = 'role_viewer_sensitive',
}

export enum TheraOrgRoles {
  role_admin = 'role_admin',
  role_manager = 'role_manager',
}

export const TheraTeamRolesDesc = {
  [TheraTeamRoles.role_admin]: {
    name: 'Team Admin',
    description: 'Can create, edit, and manage contracts, pay invoices and manage team settings.',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
  [TheraTeamRoles.role_people_manager]: {
    name: 'People Manager',
    description: 'Can create, edit, and manage contracts.',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
  [TheraTeamRoles.role_people_manager_and_payer]: {
    name: 'People Manager and Payer',
    description: 'Can create, edit, and manage contracts, and pay invoices.',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
  [TheraTeamRoles.role_viewer_sensitive]: {
    name: 'Viewer',
    description: 'Can view contractors and information.',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
};

export const TheraOrgRolesDesc = {
  [TheraOrgRoles.role_admin]: {
    name: 'Admin',
    description: '',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
  [TheraOrgRoles.role_manager]: {
    name: 'Manager',
    description: '',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
};

export const TheraManagerExtraPermissions = {
  perm_adjust_payroll_and_payments: {
    id: 'perm_adjust_payroll_and_payments',
    name: 'Can adjust payment items',
    description: 'Can Open Invoices.',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
  perm_approve_payroll_and_payments: {
    id: 'perm_approve_payroll_and_payments',
    name: 'Can approve payment items',
    description: 'Can approve or deny invoices before payment.',
    learnMoreLink: 'https://intercom.help/thera/en/articles/8464059-what-are-the-different-roles-for-managers-in-thera',
  },
};
