import React from 'react';

const ComplianceDocsIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.45833 0.708984H3.875C2.49429 0.708984 1.375 1.82827 1.375 3.20898V12.7923C1.375 14.173 2.49429 15.2923 3.875 15.2923H10.125C11.5057 15.2923 12.625 14.173 12.625 12.7923V4.87565M8.45833 0.708984V4.04232C8.45833 4.50255 8.83143 4.87565 9.29167 4.87565H12.625M8.45833 0.708984L12.625 4.87565"
        stroke="#1F2937"
        strokeWidth="1.04167"
      />
      <path d="M3.875 7.375H7.20833" stroke="#1F2937" strokeWidth="1.04167" strokeLinecap="round" />
      <path d="M3.875 9.875H10.5417" stroke="#1F2937" strokeWidth="1.04167" strokeLinecap="round" />
    </svg>
  );
};

export default ComplianceDocsIcon;
