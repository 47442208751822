import posthog from "posthog-js";

export function addLibrary(urlOfTheLibrary: string) {
  if (!window) {
    console.warn('window is not defined');
    return;
  }
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

export const canRunPosthog = (): boolean => {
  if (
    // avoiding running on local
    typeof window !== 'undefined' &&
    !window.location.host.includes('127.0.0.1') &&
    !window.location.host.includes('localhost')
  )
    return true;
  return false;
};

/**
 * This utility function is used to run posthog only on production
 * and not on localhost or when window is not defined
*/

type PosthogMethod = (eventName: string, options?: Record<string, any>) => void;

export const runPosthog = <T extends PosthogMethod>(
  methodName: keyof { [key: string]: T },
  eventName?: string,
  options?: Record<string, any>,
): void => {
  if (
    // avoiding running on local
    typeof window !== 'undefined' &&
    !window.location.host.includes('127.0.0.1') &&
    !window.location.host.includes('localhost')
  ) {
    const method = posthog[methodName];
    if (method) {
      try {
        method(eventName, options);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.warn(`Posthog method "${methodName}" not found`);
    }
  } else {
    console.info('Not running posthog on localhost or when window is not defined');
  }
};
