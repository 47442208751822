import { zealApi } from 'services/config';
import {
  IZealCompany,
  IZealEmployee,
  IZealEmployeeBankAccount,
  IZealEmployeeBasicDetails,
  IZealPaySchedule,
  IZealCompleteEmployerOnboarding,
} from 'types';

// companies API
export const createZealCompany = async (company: IZealCompany) => {
  const url = `/companies`;
  return await zealApi({
    url: `?zealUrl=${url}&requirePartnerIDinBody=true`,
    method: 'POST',
    data: company,
  });
};

export const signZealEmployerAuthorizationDocs = async (data: IZealCompleteEmployerOnboarding) => {
  const url = `/companies/authorization_documents`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: data,
  });
};

export const completeZealCompanyOnboarding = async (companyID: string) => {
  const url = `/companies/onboardCompany`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      companyID,
    },
  });
};

export const getZealCompanyOnboardingLink = async ({
  zealId,
  companyID,
  base_url,
}: {
  zealId: string;
  companyID: string;
  base_url?: string;
}) => {
  let url = `/companies/onboard?companyID=${zealId}&webhook_correlation_id=${companyID}`;
  if (base_url) {
    url += `&base_url=${base_url}`;
  }
  return await zealApi({
    url: `?zealUrl=${url}&requirePartnerID=true`,
    method: 'GET',
  });
};

export const getZealCompany = async (zealId: string) => {
  const url = `/companies?companyID=${zealId}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

// taxableLocations API
export const resolveTaxableLocation = async ({
  street1,
  street2 = '',
  city,
  state,
  zip,
}: {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
}) => {
  const url = `/taxableLocations`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      street1,
      street2,
      city,
      state,
      zip,
    },
  });
};

export const getTaxableLocationByID = async (taxableLocationID: string) => {
  const url = `/taxableLocations?taxableLocationID=${taxableLocationID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

// workLocations API
export const addWorkLocation = async ({
  companyID,
  taxableLocationID,
  name,
  work_site_id,
}: {
  companyID: string;
  taxableLocationID: string;
  name: string;
  work_site_id: string;
}) => {
  const url = `/workLocations`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      taxableLocationID,
      name,
      work_site_id,
      companyID,
    },
  });
};

export const patchWorkLocation = async ({
  workLocationID,
  companyID,
  name,
  work_site_id,
}: {
  workLocationID: string;
  companyID: string;
  name: string;
  work_site_id: string;
}) => {
  const url = `/workLocations`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'PATCH',
    data: {
      workLocationID,
      companyID,
      name,
      work_site_id,
    },
  });
};

// Empoloyees API
export const createZealEmployee = async (zealId: string, employeeData: IZealEmployee) => {
  const url = `/employees`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      companyID: zealId,
      new_employees: [employeeData],
    },
  });
};

export const getZealEmployee = async (zealId: string, employeeID: string) => {
  const url = `/employees?companyID=${zealId}&employeeID=${employeeID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

export const updateZealEmployeeBasicDetails = async (zealId: string, employeeData: IZealEmployeeBasicDetails) => {
  const url = `/employees`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'PATCH',
    data: {
      companyID: zealId,
      employee: employeeData,
    },
  });
};

export const getEmployeeOnboardingLink = async ({
  zealId,
  employeeID,
  profile = true,
  employee_acct = false,
  i9_form = true,
  id_scan = true,
  payment_method_required = true,
}: {
  zealId: string;
  employeeID: string;
  profile?: boolean;
  employee_acct?: boolean;
  i9_form?: boolean;
  id_scan?: boolean;
  payment_method_required?: boolean;
}) => {
  const url = `/employees/onboard`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      companyID: zealId,
      employeeID,
      profile,
      employee_acct,
      i9_form,
      id_scan,
      payment_method_required,
    },
  });
};

export const sendEmployeeOnboardingLinkEmail = async ({
  zealId,
  employeeID,
  profile = true,
  employee_acct = false,
  i9_form = true,
  id_scan = true,
  payment_method_required = true,
}: {
  zealId: string;
  employeeID: string;
  profile?: boolean;
  employee_acct?: boolean;
  i9_form?: boolean;
  id_scan?: boolean;
  payment_method_required?: boolean;
}) => {
  const url = `/sendLink`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      companyID: zealId,
      employees: [
        {
          employeeID,
          profile,
          employee_acct,
          i9_form,
          id_scan,
          payment_method_required,
        },
      ],
    },
  });
};

export const getBankAccountByEmployeeID = async (employeeID: string, zealId: string) => {
  const url = `/bankaccount?id=${employeeID}&companyID=${zealId}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

export const updateBankAccountByEmployeeID = async (data: IZealEmployeeBankAccount) => {
  const url = `/bankaccount`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'PATCH',
    data,
  });
};

export const getZealEmployeeW4PDFs = async ({
  zealId,
  zealEmployeeID,
  jurisdiction,
}: {
  zealId: string;
  zealEmployeeID: string;
  jurisdiction: string;
}) => {
  const url = `/paperwork/employeePaperwork`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      companyID: zealId,
      employeeID: zealEmployeeID,
      jurisdiction,
    },
    responseType: 'blob',
  });
};

export const updateZealCompanyInformation = async (
  companyID: string,
  data: { first_name?: string; last_name?: string },
) => {
  const url = `/companies`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'PATCH',
    data: { companyID, ...data },
  });
};

export const getEmployerTaxesPageLink = async (companyID: string) => {
  const url = `/authLinks/taxes`;
  return await zealApi({
    url: `?zealUrl=${url}&requirePartnerIDinBody=true`,
    method: 'POST',
    data: {
      companyID: companyID,
      showSidebar: false,
    },
  });
};

export const generateEmployeeTaxDefinitions = async ({ jurisdictions }: { jurisdictions: string[] }) => {
  const url = `/employees/getTaxParameterDefinitions`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      jurisdictions: jurisdictions,
    },
  });
};

export const getEmployeeTaxParameters = async ({
  employeeID,
  companyID,
  jurisdictions,
}: {
  employeeID: string;
  companyID: string;
  jurisdictions: string[];
}) => {
  const url = `/employees/getTaxParameterSummary`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      employeeID: employeeID,
      companyID: companyID,
      jurisdictions: jurisdictions,
    },
  });
};

export const setEmployeeTaxParameters = async ({
  employeeID,
  companyID,
  federalParameters,
  stateParameters,
}: {
  employeeID: string;
  companyID: string;
  federalParameters: { code: string; jurisdiction: string; value: string }[];
  stateParameters: { code: string; jurisdiction: string; value: string }[];
}) => {
  const url = `/employees/setTaxParameters`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      employeeID: employeeID,
      companyID: companyID,
      federalParameters: federalParameters,
      stateParameters: stateParameters,
    },
  });
};

export const getZealEmployerCheckByID = async (companyID: string, employerCheckID: string) => {
  const url = `/employerCheck?companyID=${companyID}&employerCheckID=${employerCheckID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

export const getZealEmployeeCheckByEmployeeCheckID = async (companyID: string, employeeCheckID: string) => {
  const url = `/employeeCheck?companyID=${companyID}&employeeCheckID=${employeeCheckID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

export const getZealEmployeeCheckByEmployee = async (companyID: string, employeeID: string) => {
  const url = `/employeeCheck?employeeID=${employeeID}&companyID=${companyID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

export const getAllReportingPeriodsByPayFrequency = async (companyID: string, pay_schedule: IZealPaySchedule) => {
  const url = `/reportingPeriod?companyID=${companyID}&pay_schedule=${pay_schedule}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
  });
};

export const getReportingPeriodByReportingPeriodID = async (companyID: string, reportingPeriodID: string) => {
  const url = `/reportingPeriod?companyID=${companyID}&reportingPeriodID=${reportingPeriodID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
  });
};

export const getPaystubLink = async (companyID: string, employeeCheckID: string) => {
  const url = `/paystubLink?companyID=${companyID}&employeeCheckID=${employeeCheckID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};

export const getPreviewJobIDForEmployeeChecks = async (companyID: string, employeeCheckIDs: string[]) => {
  const url = `/preview/checks`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'POST',
    data: {
      companyID: companyID,
      checks: employeeCheckIDs,
    },
  });
};

export const getPreviewEmployeeChecksDataByJobID = async (jobID: string) => {
  const url = `/preview?job_id=${jobID}`;
  return await zealApi({
    url: `?zealUrl=${url}`,
    method: 'GET',
  });
};
