import OrganisationIcon from 'icons/OrganisationIcon';

export default function NoTeam() {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-[18px]">
      <div className="rounded-md border border-neutral-200 bg-neutral-50 p-3">
        <OrganisationIcon className="h-8 w-8" />
      </div>
      <h1 className="text-2xl font-medium text-neutral-800">No team assigned</h1>
      <div className="flex flex-col items-center rounded-lg bg-neutral-50 p-4">
        <p className="text-base font-medium text-neutral-800">
          Contact your Organization Admin to assign you to a team.
        </p>
        <p className="text-sm font-normal text-neutral-500">
          Once you're on a team, you'll be able to manage your team's payroll and more!
        </p>
      </div>
    </div>
  );
}
