import { getCookie } from 'utils/cookie';
import axios, { CancelToken, CancelTokenSource } from 'axios';

export const apiBaseUrl = process.env.NEXT_PUBLIC_BACKEND_URL;

if (!apiBaseUrl) {
  // throw new Error("NEXT_PUBLIC_BACKEND_URL is not defined, app won't work!");
  console.error("NEXT_PUBLIC_BACKEND_URL is not defined, app won't work!");
}

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  transitional: {
    silentJSONParsing: false,
  },
  responseType: 'json',
});

export const axiosSourceMap: Map<string, CancelTokenSource[]> = new Map();

/**
 * stop ongoing api calls,
 * cancel all ongoing axios requests
 */
export const cancelAxiosRequests = () => {
  try {
    axiosSourceMap.forEach((sources) => {
      if (Array.isArray(sources)) {
        sources.forEach((source) => source.cancel());
      }
    });
    axiosSourceMap.clear(); // Clear the map
  } catch (error) {
    console.error('Error while canceling axios requests', error);
  }
};

// Generate a unique CancelToken and store its source in axiosSourceMap
function generateCancelToken(key: string): CancelToken {
  const source = axios.CancelToken.source();

  // If there are already sources associated with this key, add this one
  if (axiosSourceMap.has(key)) {
    axiosSourceMap.get(key).push(source);
  } else {
    // Else create a new array with this source
    axiosSourceMap.set(key, [source]);
  }

  return source.token;
}

// Function to remove a source from axiosSourceMap
function removeSource(key: string, source: CancelToken) {
  if (axiosSourceMap.has(key)) {
    const sources = axiosSourceMap.get(key);
    const index = sources.findIndex((s) => s.token === source);

    if (index !== -1) {
      sources.splice(index, 1);
    }

    if (sources.length === 0) {
      axiosSourceMap.delete(key);
    } else {
      axiosSourceMap.set(key, sources);
    }
  }
}

class CustomError extends Error {
  httpStatus?: number;
  status?: number;
  isAxiosError?: boolean;
  code?: string;
}

const errorHandler = (error: any): Promise<never> => {
  if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
    console.info('Request timed out');
  }
  if (error?.response) {
    const status = error.response.status || 500;
    let customError;
    let message;

    if (typeof error.response.data === 'object' && error.response.data !== null) {
      message = error.response.data.message || error.message;
      customError = Object.assign(new CustomError(message), error.response.data);
    } else {
      message = `Server error with status code ${status}`;
      customError = new CustomError(message);
    }

    customError.httpStatus = status;
    return Promise.reject(customError);
  }

  const defaultError = new CustomError(error.message);
  defaultError.status = error.status || 500;
  defaultError.httpStatus = 500;
  defaultError.code = error.code || 'ERR_UNKNOWN'; // ERR_CANCELED for axios cancel
  defaultError.isAxiosError = error.isAxiosError;

  return Promise.reject(defaultError);
};

const successHandler = (response) => {
  const res = response.data;
  if (
    res &&
    response.status &&
    typeof res !== 'string' &&
    typeof res !== 'number' &&
    typeof res !== 'boolean' &&
    !Array.isArray(res)
  ) {
    res.httpStatus = response.status || 200;
  }
  return res;
};

//on successful response
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

// Promise<AxiosResponse<any, any>>
export function callApi(url, method, params, text = false): Promise<any> {
  method = method.toUpperCase();
  url =
    method !== 'GET' ? url : params && Object.keys(params)?.length > 0 ? `${url}?${new URLSearchParams(params)}` : url;
  const jwt = getCookie('stytch_session_jwt');
  /* if (!jwt) {
    return Promise.reject(new Error('UnAuthorized'));
  } */
  const key = `${method}-${url}-${JSON.stringify(params)}`;
  const cancelToken = generateCancelToken(key);
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
  // if the response is text, set the responseType to text
  // but again set it to json as default for the instance
  // this also needs to be taken care for other types such as blob, arraybuffer etc
  if (text) axiosInstance.defaults.responseType = 'text';
  else axiosInstance.defaults.responseType = 'json';
  return axiosInstance({
    url,
    method: method,
    data: params,
    cancelToken,
  }).finally(() => {
    // remove the cancel token source when the request is completed
    removeSource(key, cancelToken);
  });
}

export function uploadFile(url, method, file, params = {}, text = false): Promise<any> {
  const jwt = getCookie('stytch_session_jwt');
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
  if (text) axiosInstance.defaults.responseType = 'text';
  else axiosInstance.defaults.responseType = 'json';
  const key = `${method}-${url}-${JSON.stringify(params)}`;
  const cancelToken = generateCancelToken(key);
  const formData = new FormData();
  formData.append('file', file, file.name);
  for (const paramsKey in params) {
    formData.append(paramsKey, params[paramsKey]);
  }

  // Set the 'Content-Type' header for the instance to 'multipart/form-data'
  // axiosInstance.defaults.headers['Content-Type'] = 'multipart/form-data';

  return axiosInstance({
    url,
    method: method.toUpperCase(),
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken,
  }).finally(() => {
    // remove the cancel token source when the request is completed
    removeSource(key, cancelToken);
  });
}

// Zeal APIs wrapper, interceptors and instances
export interface ZealApiResponse {
  httpStatus: number;
  success: boolean;
  data?: any;
  errors?: any;
  url?: string;
}

// type ZealApiBlobResponse = 'Blob | MediaSource';
const zealApiInstanceBackend = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ZEAL_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_ZEAL_API_KEY}`,
  },
});

const zealApiInstanceFrontend = axios.create({
  baseURL: '/api/zeal',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

zealApiInstanceFrontend.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

zealApiInstanceBackend.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

// Zeal API wrapper
export const zealApi = ({
  url,
  method = 'GET',
  data,
  responseType = 'json',
  isBackend = false,
}: {
  url: string;
  method?: string;
  data?: any;
  isBackend?: boolean;
  responseType?: 'json' | 'text' | 'blob' | 'arraybuffer';
}): Promise<ZealApiResponse> => {
  method = method.toUpperCase();
  if (isBackend) {
    return zealApiInstanceBackend({
      url,
      method,
      data,
      responseType,
    });
  }
  return zealApiInstanceFrontend({
    url,
    method,
    data,
    responseType,
  });
};
