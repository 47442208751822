import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import Link from 'next/link';

import LoginLayout from 'components/container-components/layout/LoginLayout';
import { emailRegex } from 'utils/regex';
import Button from 'components/generic-components/button';
import { CommonRoutes } from 'routes';
import LoadingScreen from 'components/container-components/LoadingScreen';
import Toggle from 'components/generic-components/Toggle';
import theraToast from 'components/generic-components/toast/TheraToast';

export const THERA_SkipEmailVerify = 'thera__skipVerifyEmail';
export const THERA_SuperAdmin = 'isThera__superAdmin';
export const dummyEmail = `dummyEmailForSU@thera.com`;
const SuperAdminPassword = process.env.NEXT_PUBLIC_THERA_SUPER_ADMIN_PWD;
const isAdvancedSuperAdminMode = process.env.NEXT_PUBLIC_THERA_IS_ADVANCED_SUPER_ADMIN_MODE === 'true';

/**
 * Workflows:
 * Advanced Super Admin Mode: This mode is used to skip email verification for a particular email. Configured via env variable.
 */

const SignUpPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState(!isAdvancedSuperAdminMode ? dummyEmail : '');
  const [isEmailSet, setIsEmailSet] = useState(!isAdvancedSuperAdminMode ? true : false);
  const [authPassword, setAuthPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(false);

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const applySkipVerify = useCallback(() => {
    const _email = isAdvancedSuperAdminMode ? email : dummyEmail;
    // check if email is valid
    if (!emailRegex.test(_email)) {
      console.error('email', _email);
      theraToast.error('Please enter a valid email address');
      return;
    }
    // check if local storage is available
    if (!localStorage) {
      theraToast.error('Local storage is not available');
      return;
    }
    // save the email in local storage
    try {
      localStorage.setItem(THERA_SkipEmailVerify, _email);
      setIsEmailSet(true);
      theraToast.success(
        `You will now be able to skip email verifications ${isAdvancedSuperAdminMode ? 'for this email' : ''}`,
      );
      window.location.reload();
    } catch (error) {
      theraToast.error('Something went wrong while saving the email in local storage');
      console.error(error);
    }
  }, [email]);

  const handleAuth = () => {
    // const password = document.getElementById('password') as HTMLInputElement;
    if (authPassword === SuperAdminPassword) {
      try {
        localStorage.setItem(THERA_SuperAdmin, 'true');
        theraToast.success('Great! You are ready to enable super admin mode on this browser');
      } catch (error) {
        console.error(error);
        theraToast.error('Something went wrong while switching to super admin mode');
      }
      setIsAuthenticated(true);
    } else {
      theraToast.error('Wrong password');
    }
  };

  const turnOffSuperAdmin = () => {
    try {
      localStorage.removeItem(THERA_SuperAdmin);
      localStorage.removeItem(THERA_SkipEmailVerify);
      theraToast.success('You are now in normal mode on this browser');
      window.location.reload();
    } catch (error) {
      console.error(error);
      theraToast.error('Something went wrong while switching to normal mode');
    }
    setIsAuthenticated(false);
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      if (localStorage && localStorage.getItem(THERA_SuperAdmin) === 'true') {
        setIsAuthenticated(true);
        if (localStorage.getItem(THERA_SkipEmailVerify)) {
          // console.log('email', localStorage.getItem(THERA_SkipEmailVerify));
          setEmail(localStorage.getItem(THERA_SkipEmailVerify));
          setIsEmailSet(true);
          if (!isAdvancedSuperAdminMode) {
            setIsToggleOn(true);
          }
        }
      }
    } catch (error) {
      console.error(error);
      theraToast.error('Something went wrong while checking super admin mode');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const removeEmail = () => {
    try {
      localStorage.removeItem(THERA_SkipEmailVerify);
      theraToast.success(isAdvancedSuperAdminMode ? 'Email removed from local storage' : 'Super admin mode disabled!');
      window.location.reload();
    } catch (error) {
      console.error(error);
      theraToast.error(
        isAdvancedSuperAdminMode
          ? 'Something went wrong while removing the email from local storage'
          : 'Something went wrong while disabling super admin mode',
      );
    }
    setEmail('');
  };

  const onToggle = (active: boolean) => {
    setIsToggleOn(active);
    if (active) {
      applySkipVerify();
    } else {
      removeEmail();
    }
  };

  if (!SuperAdminPassword) {
    return (
      <LoginLayout pageTitle="Super Admin" showRightContainer={false}>
        <div className="mt-6 text-center">
          <p className="text-base font-normal text-neutral-800">
            Please set the environment variable{' '}
            <span className="rounded-sm bg-gray-100 px-2 py-1 font-medium">NEXT_PUBLIC_THERA_SUPER_ADMIN_PWD</span> to
            access this page.
          </p>
        </div>
      </LoginLayout>
    );
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <LoginLayout pageTitle="Super Admin" showRightContainer={false}>
        <div className="mt-6 text-center">
          {isAuthenticated ? (
            <>
              {isAdvancedSuperAdminMode ? (
                <>
                  {isEmailSet ? (
                    <>
                      <p className="text-base font-normal text-neutral-800">
                        You are now in super admin mode on this browser. You can now skip email verifications for{' '}
                        <span className="rounded-sm bg-gray-100 px-2 py-1 font-medium">{email}</span>
                      </p>
                      <div className="mt-4">
                        <Button onClick={removeEmail} variant="plain" className="underline">
                          Remove Email
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="text-base font-normal text-neutral-800">
                        Please enter the email address for which you want to skip the email verification.
                      </p>
                      <div className="mt-4">
                        <input
                          type="email"
                          className="form-input text-base"
                          name="email"
                          id="email"
                          placeholder="Enter the Email"
                          onChange={handleEmail}
                          value={email}
                        />
                        <Button onClick={applySkipVerify} className="mt-4">
                          <span className="text-base">Apply Skip Verification</span>
                        </Button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="mt-4 flex items-center justify-between">
                    <div className="mr-2 font-semibold text-gray-900">Super Admin Mode</div>
                    <Toggle enabled={isToggleOn} setEnabled={onToggle} />
                  </div>
                </>
              )}
              <div className="mt-6 flex items-center justify-center space-x-3 text-sm">
                <Link href={CommonRoutes.Dashboard} className="underline">
                  Back to Thera
                </Link>
                <Button onClick={turnOffSuperAdmin} variant="outline">
                  Logout from Super Admin Mode
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="text-base font-normal text-neutral-800">
                Please authenticate yourself to access this page.
              </p>
              <div className="mt-4">
                <input
                  aria-label="Password"
                  type="password"
                  className="form-input text-base"
                  name="password"
                  id="password"
                  placeholder="Enter the Password"
                  value={authPassword}
                  onChange={(e) => setAuthPassword(e.target.value)}
                />
              </div>
              <div className="mt-6 flex items-center justify-center space-x-3 text-sm">
                <Link href={CommonRoutes.Dashboard} className="underline">
                  Back to Thera
                </Link>
                <Button onClick={handleAuth}>
                  <span className="text-base">Authenticate</span>
                </Button>
              </div>
            </>
          )}
        </div>
      </LoginLayout>
    </>
  );
};

export default SignUpPage;
