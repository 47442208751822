import React from 'react'

const Payroll = ({ className=''}) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.285 4.71404V18.4274M7.85667 15.207L8.86117 15.9601C10.1994 16.9646 12.3695 16.9646 13.7088 15.9601C15.0482 14.9556 15.0482 13.3283 13.7088 12.3238C13.0403 11.821 12.1627 11.5707 11.285 11.5707C10.4565 11.5707 9.62797 11.3193 8.99602 10.8176C7.7321 9.81311 7.7321 8.1858 8.99602 7.1813C10.2599 6.1768 12.3101 6.1768 13.574 7.1813L14.0482 7.55841M21.57 11.5707C21.57 12.9214 21.304 14.2588 20.7871 15.5066C20.2702 16.7544 19.5126 17.8882 18.5576 18.8433C17.6025 19.7983 16.4687 20.5559 15.2209 21.0728C13.9731 21.5897 12.6356 21.8557 11.285 21.8557C9.93435 21.8557 8.59693 21.5897 7.3491 21.0728C6.10127 20.5559 4.96746 19.7983 4.01241 18.8433C3.05736 17.8882 2.29977 16.7544 1.7829 15.5066C1.26603 14.2588 1 12.9214 1 11.5707C1 8.84295 2.08359 6.22692 4.01241 4.29811C5.94122 2.3693 8.55725 1.28571 11.285 1.28571C14.0128 1.28571 16.6288 2.3693 18.5576 4.29811C20.4864 6.22692 21.57 8.84295 21.57 11.5707Z"
        stroke="#404040"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Payroll;
