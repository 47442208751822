// import cc from 'currency-codes';

export const sleep = (ms = 3000) => new Promise((r) => setTimeout(r, ms));

export function currencyAmountToString(amount: number, currencyCode: string) {
  // from these docs: https://stripe.com/docs/currencies#zero-decimal
  const zeroDecimalCurrencies = [
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
  ];
  const amountAsString = amount.toString();
  const stringLength = amountAsString.length;
  // const isZeroDecimal = zeroDecimalCurrencies.includes(currencyCode.toUpperCase());
  // const currencySymbol = cc.code(currencyCode).currency;

  return zeroDecimalCurrencies.includes(currencyCode.toUpperCase())
    ? amountAsString
    : amountAsString.substring(0, stringLength - 2) + '.' + amountAsString.substring(stringLength - 2);
}

export function convertBalanceCurrency(amount: number, currency: string) {
  const zeroDecimalCurrencies = [
    'BIF',
    'CLP',
    'DJF',
    'GNF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'PYG',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
  ];
  const amountAsString = amount.toString();
  return zeroDecimalCurrencies.includes(currency.toUpperCase())
    ? [amountAsString]
    : amountAsString === '0'
    ? ['0.', '00']
    : [`${amountAsString.slice(0, -2)}.`, amountAsString.slice(-2)];
}

export function toNiceDateTime(datetimeInMillis: number) {
  return new Date(datetimeInMillis).toLocaleString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
}

export function withLoadingSkeleton(condition, component, maxWidth = '') {
  return condition ? component() : <div className={`h-4 animate-pulse rounded bg-gray-200 ${maxWidth}`}></div>;
}

export function toGenericBank(bankData) {
  switch (bankData.type) {
    case 'us_bank_account':
      return {
        email: bankData.billing_details.email,
        bank_name: bankData.us_bank_account.bank_name,
        last_four: bankData.us_bank_account.last4,
      };
    case 'sepa_debit':
      return {
        email: bankData.billing_details.email,
        bank_name: 'SEPA Direct Debit',
        last_four: bankData.sepa_debit.last4,
      };
    case 'acss_debit':
      return {
        email: bankData.billing_details.email,
        bank_name: bankData.acss_debit.bank_name,
        last_four: bankData.acss_debit.last4,
      };
    case 'card':
      return {
        email: bankData.billing_details.email,
        bank_name: bankData.card.brand.toUpperCase(),
        last_four: bankData.card.last4,
      };
    default:
      return undefined;
  }
}

// found on stack overflow
export function hash(
  str,
  seed = undefined,
  // asString = false
) {
  /*jshint bitwise:false */
  let i,
    l,
    hval = seed === undefined ? 0x811c9dc5 : seed;

  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  /* if (asString) {
    // Convert to 8 digit hex string
    return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
  } */
  return hval >>> 0;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function calculateDuration(today, startDate) {
  const diff = today.diff(startDate, ['years', 'months', 'weeks', 'days']).toObject();
  const roundedDays = Math.floor(diff.days);
  const roundedWeeks = Math.floor(diff.weeks);
  const roundedMonths = Math.floor(diff.months);
  const roundedYears = Math.floor(diff.years);

  if (roundedYears > 0) {
    return `${roundedYears} Year${roundedYears > 1 ? 's' : ''} Ago`;
  } else if (roundedMonths > 0) {
    return `${roundedMonths} Month${roundedMonths > 1 ? 's' : ''} Ago`;
  } else if (roundedWeeks > 0) {
    return `${roundedWeeks} Week${roundedWeeks > 1 ? 's' : ''} Ago`;
  } else if (roundedDays > 0) {
    return `${roundedDays} Day${roundedDays > 1 ? 's' : ''} Ago`;
  } else {
    return `Today`;
  }
}

export function limitDecimals(s: string, decimalLimit: number) {
  if (decimalLimit === 0) {
    console.log(s.split('.'));
    return Math.floor(Number(s)).toString();
    // return s.split('.')[0];
  }
  return s
    .split('.')
    .map((el, i) => (i ? el.split('').slice(0, decimalLimit).join('') : el))
    .join('.');
}

export const getUserInitials = ({ firstName, lastName }) => {
  const firstLetter = firstName ? firstName.charAt(0) : '';
  const secondLetter = lastName ? lastName.charAt(0) : '';
  return `${firstLetter}${secondLetter}`;
};
