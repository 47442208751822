import {
  CompanyAffiliation,
  CompanyPermissions,
  TeamAndDetails,
  TeamRoleAndDetails,
} from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';
import { TheraTeamRolesDesc } from 'lib/constants';
import { logError } from './error';

export const getRoleLabel = (role: string) => {
  return TheraTeamRolesDesc[role]?.name;
};

export const getManagerRolesList = (companyPermissions: CompanyPermissions): string[] => {
  if (!companyPermissions?.teams) return [];
  const teamCount = Object.keys(companyPermissions.teams)?.length;
  if (teamCount === 0) return [];
  const list = [];
  for (const teamId in companyPermissions.teams) {
    const team = companyPermissions.teams[teamId];
    if (!team) {
      const error = new Error(`team not found for id [${teamId}]`);
      logError(error);
      continue;
    }
    const roleName = TheraTeamRolesDesc[team.role]?.name;
    list.push(roleName);
  }
  // return unique values
  return [...new Set(list)];
};

export const getManagerTeamsList = (
  companyPermissions: CompanyPermissions,
  teams: Record<string, TeamAndDetails>,
): string[] => {
  if (!companyPermissions?.teams) return [];
  const teamCount = Object.keys(companyPermissions.teams)?.length;
  if (teamCount === 0) return [];
  const list = [];
  for (const teamId in companyPermissions.teams) {
    const team = teams[teamId];
    if (!team) {
      const error = new Error(`team not found for id [${teamId}]`);
      logError(error);
      continue;
    }
    list.push(team.name);
  }
  return [...new Set(list)];
};

export const getTeamByContractId = (contractId: string, teams: Record<string, TeamAndDetails>) => {
  const teams_ = Object.entries(teams).map(([teamId, team]) => ({
    id: teamId,
    name: team.name,
    contractIds: team.contractIds,
  }));
  const team = teams_.find((team) => team.contractIds.includes(contractId));
  return {
    id: team?.id,
    name: team?.name,
  };
};

export const checkIfUserHasAccessToCompanyAndRole = (
  companyId: string,
  role: 'manager' | 'worker',
  affiliationsByCompany: Record<string, CompanyAffiliation>,
) => {
  if (!companyId || !role || !affiliationsByCompany) return false;
  const saved_company = affiliationsByCompany[companyId];
  if (!saved_company) return false;
  const hasAccess =
    (role === 'manager' && saved_company?.orgRoleAndDetails?.role === 'role_admin') ||
    (role === 'manager' && saved_company?.orgRoleAndDetails?.role === 'role_manager') ||
    (role === 'manager' && saved_company?.teams && Object.keys(saved_company?.teams).length > 0) ||
    (role === 'worker' && saved_company?.contracts?.length > 0);
  return hasAccess;
};

export const canMoveContractorsAmongTeams = ({
  permissionsVersion,
  affiliationsByCompany,
  currentCompanyId,
  isOrgAdmin,
  currentContractTeamId,
}) => {
  // disabled if the permissions version is 1
  if (permissionsVersion !== 2) return false;
  // return false if teams are not loaded
  if (!affiliationsByCompany) return false;
  // enable this for all org admins
  if (isOrgAdmin) return true;
  // if the user is a team admin, check if they have permissions on more than one team
  const teams = affiliationsByCompany?.[currentCompanyId]?.teams;
  if (!teams || !Object.keys(teams)?.length) return false;
  const teamsWhereUserIsAdmin: Record<string, TeamRoleAndDetails> = Object.keys(teams)
    .filter((teamId) => teams[teamId].role === 'role_admin')
    .reduce((result, teamId) => {
      result[teamId] = teams[teamId];
      return result;
    }, {});
  const isTeamAdminOfManyTeams = (teamsObj, targetTeamId) => {
    const teamIds = Object.keys(teamsObj);
    const numberOfTeams = teamIds.length;

    if (numberOfTeams > 1 && teamIds.includes(targetTeamId)) {
      return true;
    }

    return false;
  };
  const result = isTeamAdminOfManyTeams(teamsWhereUserIsAdmin, currentContractTeamId);

  if (result) {
    return true;
  } else {
    return false;
  }
};
