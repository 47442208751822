import { useEffect, useState } from 'react';
import Link from 'next/link';
import { throttle } from 'lodash';
import LoadingScreen from 'components/container-components/LoadingScreen';
import TheraMonitorWithBg from 'icons/TheraMonitorWithBg';

const DESKTOP_MIN_WIDTH = 768; // px

function MobileDeviceRestriction({ children }) {
  const [screenWidth, setScreenWidth] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    // Return a default value that would be considered as a mobile screen width
    return 0;
  });

  useEffect(() => {
    const updateWidth = () => setScreenWidth(window.innerWidth);
    const handleResize = throttle(updateWidth, 200); // update width at most every 200 ms

    // Now that the component has mounted, we can access `window` safely
    updateWidth();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Show a loading screen until the component has mounted
  if (!screenWidth) {
    return <LoadingScreen />;
  }

  return (
    <>
      {screenWidth < DESKTOP_MIN_WIDTH ? (
        <div className="fixed inset-0 mx-3 flex items-center justify-center">
          <div className="flex-col items-center">
            <Link href="/">
              <img className="mx-auto h-9" src="/logo/thera-logo.svg" alt="Thera-logo" />
            </Link>
            <h1 className="-tracking-1 my-8 justify-center text-center text-xl font-medium text-neutral-800">
              Thera is optimized for desktop
            </h1>
            <div className="flex rounded-lg bg-neutral-50 p-4 align-middle">
              <TheraMonitorWithBg />
              <div className="my-auto ml-4">
                <h4 className="-tracking-1 text-base font-medium text-neutral-800">
                  For the best experience, access Thera from a desktop.
                </h4>
                <p className="font-regular -tracking-1 text-sm text-neutral-500">
                  or try switching to the desktop view on your mobile browser{' '}
                </p>
              </div>
            </div>
            <Link href="mailto:support@getthera.com">
              {' '}
              <p className="font-regular -tracking-1 pt-4 text-center text-sm text-neutral-500">
                Have any questions? Reach out to <span className="underline">support@getthera.com</span>
              </p>
            </Link>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
}

export default MobileDeviceRestriction;
