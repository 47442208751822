import { useStytchSession, useStytchUser } from '@stytch/nextjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { AuthRoutes, CommonRoutes } from 'routes';
import { isSuperAdminMode, useUser } from 'context/user';
import LoadingScreen from './LoadingScreen';
import { THERA_SkipEmailVerify, THERA_SuperAdmin } from 'pages/super-admin';
import { cancelAxiosRequests } from 'services/config';
import theraToast from 'components/generic-components/toast/TheraToast';
import { checkIfNewInvitedUserWithoutPasswordAndNoOAuth } from 'utils/user';

const PRELOGIN_ROUTES = ['/', AuthRoutes.SuperAdmin, AuthRoutes.SignIn, AuthRoutes.SignUp, AuthRoutes.ResetPassword];
const ErrorRoutes = ['/404', '/500'];
export const initialTheraRoute_LS_key = 'initialTheraRoute';
export const initialTheraRouteTimestamp_LS_key = 'initialTheraRouteTimestamp';

export default function LoginRoutingHandler({ children }) {
  const [showChildren, setShowChildren] = useState(false);
  const { session, isInitialized } = useStytchSession();
  const { user: stytchUser } = useStytchUser();
  const router = useRouter();
  const { user, isLoggingOut, roleType } = useUser();
  const [isSuperAdminEnabled, setIsSuperAdminModeEnabled] = useState(false);
  const [isSuperAdminModeReady, setIsSuperAdminModeReady] = useState(false);
  const initialRoute = router.asPath;
  const isPreloginRoute = PRELOGIN_ROUTES.includes(router.pathname);
  const isNewInvitedUserWithoutPasswordAndNoOAuth = checkIfNewInvitedUserWithoutPasswordAndNoOAuth(stytchUser);

  // Super admin mode
  useEffect(() => {
    try {
      if (localStorage && localStorage.getItem(THERA_SuperAdmin) === 'true') {
        setIsSuperAdminModeEnabled(true);
        if (localStorage.getItem(THERA_SkipEmailVerify)) {
          setIsSuperAdminModeReady(true);
        }
      }
    } catch (error) {
      console.error(error);
      theraToast.error('Something went wrong while checking super admin mode');
    }
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    // Reset password is special-cased because we want to allow users to reset their password even if they're not logged in
    if (router.pathname === AuthRoutes.ResetPassword) {
      setShowChildren(true);
      return;
    }

    const determineRouting = async () => {
      if (!session) return;
      if (!user) return;
      if (router.pathname === AuthRoutes.SuperAdmin) return;

      if (isNewInvitedUserWithoutPasswordAndNoOAuth) {
        if (isSuperAdminMode()) {
          return await router.replace(CommonRoutes.Dashboard);
        }
        if (router.pathname === AuthRoutes.ResetPassword) {
          return;
        }
        return await router.replace({
          pathname: AuthRoutes.ResetPassword,
          query: { create_new_password: true },
        });
      }
      if (!user?.isEmailVerified) {
        if (router.pathname === AuthRoutes.EmailVerification) return;
        await router.replace({
          pathname: AuthRoutes.EmailVerification,
          query: { email: user.email },
        });
        return;
      }

      if (
        // admin
        roleType === 'manager' ||
        // or contractor/employee with onboarding done
        user?.isOnboarded
      ) {
        // check if local storage has initial route
        const initialRoute = localStorage && localStorage.getItem(initialTheraRoute_LS_key);
        const initialRouteTimestamp = localStorage && localStorage.getItem(initialTheraRouteTimestamp_LS_key);
        const currentTimestamp = new Date().getTime();
        const timeDifference = currentTimestamp - parseInt(initialRouteTimestamp);
        const isRecentRoute = timeDifference < 1000 * 60 * 5; // 5 minutes
        if (initialRoute && isRecentRoute) {
          // remove initial route from local storage
          localStorage.removeItem(initialTheraRoute_LS_key);
          localStorage.removeItem(initialTheraRouteTimestamp_LS_key);
          await router.replace(initialRoute);
        } else {
          await router.replace(CommonRoutes.Dashboard);
        }
      }
    };

    // Authenticate is special-cased because this is where we transition from not having a session to having one
    if (router.pathname === AuthRoutes.Authenticate) {
      setShowChildren(true);
      determineRouting();
      return;
    }

    if (isPreloginRoute) {
      if (session) {
        determineRouting();
      }
      setShowChildren(true);
    } else {
      if (!session) {
        // capture initial route, except for error routes
        try {
          if (!ErrorRoutes.includes(router.pathname) && !isLoggingOut) {
            const currentTimestamp = new Date().getTime();
            localStorage && localStorage.setItem(initialTheraRoute_LS_key, initialRoute);
            localStorage && localStorage.setItem(initialTheraRouteTimestamp_LS_key, currentTimestamp.toString());
          }
        } catch (error) {
          console.error(error);
        }
        cancelAxiosRequests();
        router.replace(AuthRoutes.SignIn);
      } else {
        // remove initial route from local storage
        localStorage.removeItem(initialTheraRoute_LS_key);
        localStorage.removeItem(initialTheraRouteTimestamp_LS_key);
        setShowChildren(true);
      }
    }
  }, [
    initialRoute,
    isInitialized,
    isLoggingOut,
    isNewInvitedUserWithoutPasswordAndNoOAuth,
    isPreloginRoute,
    roleType,
    router,
    session,
    user,
  ]);

  if (!isInitialized) return <LoadingScreen />;

  const goToSuperAdminPage = () => {
    router.push(AuthRoutes.SuperAdmin);
  };

  return (
    <>
      {isInitialized && showChildren ? (
        <>
          {isSuperAdminEnabled ? (
            <div
              onClick={goToSuperAdminPage}
              className={`fixed left-0 top-0 z-[9999] !h-2 !min-h-0 w-full cursor-pointer${
                isSuperAdminModeReady ? ' bg-therablue' : ' bg-orange-300'
              }`}
            />
          ) : null}
          {children}
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
