import React from 'react';

const PaymentMethodIcon = ({ className = '' }) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.5 8.99999C1.5 5.41754 1.5 3.62584 2.6134 2.51339C3.72585 1.39999 5.51755 1.39999 9.1 1.39999H12.9C16.4824 1.39999 18.2741 1.39999 19.3866 2.51339C20.5 3.62584 20.5 5.41754 20.5 8.99999C20.5 12.5824 20.5 14.3741 19.3866 15.4866C18.2741 16.6 16.4824 16.6 12.9 16.6H9.1C5.51755 16.6 3.72585 16.6 2.6134 15.4866C1.5 14.3741 1.5 12.5824 1.5 8.99999Z"
        stroke="black"
        strokeWidth="1.04"
      />
      <path
        d="M9.1 12.8H5.3M12.9 12.8H11.475M1.5 7.09999H20.5"
        stroke="black"
        strokeWidth="1.04"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PaymentMethodIcon;
