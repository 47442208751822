import React from 'react';
import { hash } from 'lib/miscellaneous';

const colorProfiles = [
  { bg: 'bg-red-100', text: 'text-red-700' },
  { bg: 'bg-orange-100', text: 'text-orange-700' },
  { bg: 'bg-amber-100', text: 'text-amber-700' },
  { bg: 'bg-yellow-100', text: 'text-yellow-700' },
  { bg: 'bg-lime-100', text: 'text-lime-700' },
  { bg: 'bg-green-100', text: 'text-green-700' },
  { bg: 'bg-emerald-100', text: 'text-emerald-700' },
  { bg: 'bg-teal-100', text: 'text-teal-700' },
  { bg: 'bg-cyan-100', text: 'text-cyan-700' },
  { bg: 'bg-sky-100', text: 'text-sky-700' },
  { bg: 'bg-blue-100', text: 'text-blue-700' },
  { bg: 'bg-indigo-100', text: 'text-indigo-700' },
  { bg: 'bg-violet-100', text: 'text-violet-700' },
  { bg: 'bg-purple-100', text: 'text-purple-700' },
  { bg: 'bg-fuchsia-100', text: 'text-fuchsia-700' },
  { bg: 'bg-pink-100', text: 'text-pink-700' },
  { bg: 'bg-rose-100', text: 'text-rose-700' },
];

/**
 * @param {string} text - The text to display in the circle, usually the initials of the user
 * @param {string} id - The id of the user, used to generate the color of the circle
 * @param {string} height - The height of the circle, as a tailwindcss class, e.g. 'h-8'
 * @param {string} width - The width of the circle, as a tailwindcss class, e.g. 'w-8'
 */
export default function ProfileCircle({
  text,
  id,
  height,
  width,
  textSize = 'text-xs',
  className = '',
  textClassName = '',
}) {
  function userToColors() {
    if (!id) {
      return colorProfiles[9];
    }
    const hashNumber = hash(id);
    const colorIndex = hashNumber % colorProfiles.length;
    return colorProfiles[colorIndex];
  }

  const colors = userToColors();

  return (
    <span
      className={`inline-flex items-center justify-center ${height} ${width} ${className} rounded-full ${
        colors.bg
      } min-w-[${Number(width.replace('w-', '')) * 4 + 'px'}]`}
    >
      <span
        className={`${textSize} inline-flex items-center justify-center font-medium leading-none ${colors.text} ${textClassName}`}
      >
        {text}
      </span>
    </span>
  );
}
