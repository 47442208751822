import React from 'react';

const Transactions = ({ className = '' }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.3132 11.8956L11.7374 1.47144M6.52529 0.892314L12.3165 0.892314L12.3165 6.68352M18.6868 6.1044L8.26265 16.5286M7.68353 11.3165L7.68353 17.1077L13.4747 17.1077"
        stroke="#1F2937"
        strokeWidth="1.04"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Transactions;
