import { ICompany, IEmployment } from 'types';
import { callApi } from '../config';

export const getEmployeeContracts = async (userID: string) => {
  const response: Promise<
    {
      contract: IEmployment;
      company: ICompany;
    }[]
  > = await callApi(`/companies/employees/${userID}/contracts`, 'get', {});
  return response;
};
