import { GetAllCompanyAffiliationsResponse } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';
import { apiBaseUrl, callApi } from '../config';

export const getUserProfile = async (id: string) => {
  if (!id) {
    throw new Error('getUserProfile: [id] is required');
  }
  return await callApi(apiBaseUrl + `/user`, 'get', { userID: id });
};

export const activateUserProfile = async (userID: string, contractID: string) => {
  return await callApi(apiBaseUrl + `/user/activate`, 'post', { userID, contractID });
};

export const deactivateUserProfile = async (userID: string, contractID: string) => {
  return await callApi(apiBaseUrl + `/user/deactivate`, 'post', { userID, contractID });
};

export const setFirstTimePassword = async (hash: string, email: string, hashType = 'bcrypt') => {
  if (!hash) {
    throw new Error('Password is required');
  }
  if (!email) {
    throw new Error('Email is required');
  }
  return await callApi(apiBaseUrl + `/user/set-first-time-password`, 'post', {
    hash,
    email,
    hashType,
  });
};

export const sendMagicLinkEmail = async (userId: string, requestUri: string) => {
  if (!userId) {
    throw new Error('userId is required');
  }
  if (!requestUri) {
    throw new Error('requestUri is required');
  }
  return await callApi(apiBaseUrl + `/email/login`, 'post', {
    userId,
    requestUri,
  });
};

export const getUserAffiliations = async (userID: string) => {
  const response: Promise<GetAllCompanyAffiliationsResponse> = await callApi(
    apiBaseUrl + `/users/${userID}/affiliations`,
    'get',
    {},
  );
  return response;
};
