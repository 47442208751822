import { TheraApiClient } from '@thera-hr/api-sdk';
import { getCookie } from 'utils/cookie';

const apiBaseUrl = process.env.NEXT_PUBLIC_BACKEND_URL;

export const createTheraClient = () => {
  const jwt = getCookie('stytch_session_jwt');

  const theraClient = new TheraApiClient({
    environment: apiBaseUrl,
    token: jwt,
  });

  return theraClient.api;
};

// const theraApi = createTheraClient();
// export default theraApi;
