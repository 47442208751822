import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useUser } from './user';
import { getCompany, getEmployerCompanyNames } from 'services/companyApi';
import { getCompanyEmployees } from 'services/companyApi';
import { ICompany, IEmployeeContract } from 'types';
import { formatErrorMessage, logError } from 'utils/error';
import theraToast from 'components/generic-components/toast/TheraToast';

// oceans company id for IAN in prod
export const IAN_CompanyID = 'f30c946c-2d46-4760-a620-d2e6255a855f';

type CompanyType = ICompany & {
  employeesAndEmployment: IEmployeeContract[];
  hasEOREmployees?: boolean;
  isIANCompany?: boolean;
};

type CompanyContextType = {
  company?: CompanyType;
  setCompany?: (company: CompanyType) => void;
  employerCompanyNames: string[];
  mutate: () => Promise<void>;
  isLoading: boolean;
};

const Context = createContext<CompanyContextType>(null);
/**
 * For Permissions V1, the company ID is stored in the user object and is used to fetch company information.
 * For Permissions V2, the company ID is stored in the affiliationsByCompany object and company is also stored on user context as currentCompany.
 */
const CompanyProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<CompanyType>();
  const [employerCompanyNames, setEmployerCompanyNames] = useState([]);
  const {
    user,
    isLoading: isUserLoading,
    affiliationsByCompany,
    // permissionsVersion,
    currentCompany,
    roleType,
  } = useUser();

  const companyId = useCallback(() => {
    if (isUserLoading) {
      return null;
    }
    if (currentCompany?.companyID) {
      return currentCompany.companyID;
    }
    if (!affiliationsByCompany || !Object.keys(affiliationsByCompany)?.length) {
      // company still on permissions V1
      return user?.companyID;
    } else {
      // company on permissions V2
      // pick the first company permissions
      return affiliationsByCompany && Object.keys(affiliationsByCompany)[0];
    }
  }, [isUserLoading, affiliationsByCompany, user?.companyID, currentCompany]);

  const getCompanyInformation = useCallback(async () => {
    const companyID = companyId();
    if (!user || !companyID) return;
    setIsLoading(true);
    const isIANCompany = companyID === IAN_CompanyID;
    const isManager = roleType === 'manager';

    try {
      // Fetch company information and employees information in parallel
      // in order to reduce the time but catch errors separately as Promise.all
      // will reject if any of the promises reject.
      if (isManager) {
        await Promise.all([
          getCompanyEmployees(companyID)
            .then((employeesAndEmployment: IEmployeeContract[]) => {
              // Check if the company has any employees with EOR
              const eorEmployee = employeesAndEmployment.find((employee) => employee.employment.type === 'EMPLOYEE');
              const hasEOREmployees = eorEmployee !== undefined;
              setCompany((prevCompany) => ({
                ...prevCompany,
                employeesAndEmployment,
                hasEOREmployees,
              }));
            })
            .catch((error) => {
              logError(error);
              const errorMessage = formatErrorMessage(error, 'Unable to fetch employees and employment information.');
              theraToast.error(errorMessage, {
                id: 'fetch-employees-employment',
              });
            }),
          getCompany({ companyID: companyID })
            .then((company) => {
              setCompany((prevCompany) => ({
                ...prevCompany,
                ...company,
                isIANCompany,
              }));
            })
            .catch((error) => {
              logError(error);
              const errorMessage = formatErrorMessage(error, 'Unable to fetch company information.');
              theraToast.error(errorMessage, {
                id: 'fetch-company',
              });
            }),
        ]);
      } else {
        if (user?.isOnboarded) {
          await getEmployerCompanyNames()
            .then((companyNames) => {
              setEmployerCompanyNames(companyNames);
            })
            .catch((error) => {
              logError(error);
              const errorMessage = formatErrorMessage(error, 'Unable to fetch employer company names.');
              theraToast.error(errorMessage, {
                id: 'fetch-employer-company-names',
              });
            });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [companyId, user, roleType]);

  useEffect(() => {
    getCompanyInformation();
  }, [getCompanyInformation, user]);

  const exposed = { company, employerCompanyNames, mutate: getCompanyInformation, isLoading };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useCompany = () => useContext(Context);

export default CompanyProvider;
