import { IAddress, IZealPaySchedule } from 'types';
import { callApi } from '../config';
import { ICreateNewCompanyEmployeeRequest } from './types';
import { IW2PayrollInfo } from 'types';

export const createCompany = async (data) => {
  return await callApi(`/company`, 'post', data);
};

export const getCompany = async (data) => {
  return await callApi(`/company`, 'get', data);
};

export const getEmployerCompanyNames = async () => {
  return await callApi(`/employer-company-names`, 'get', {});
};

/** @deprecated */
export const createCompanyEmployee = async (data: ICreateNewCompanyEmployeeRequest, skipEmail?: boolean) => {
  if (skipEmail) {
    return await callApi(`/companies/employees?skipEmail=true`, 'post', data, true);
  }
  return await callApi(`/companies/employees`, 'post', data);
};

export const getCompanyAdmins = async (data) => {
  return await callApi(`/companies/admins`, 'post', data);
};

export const updateEmployeeAddress = async (employeeID: string, data: { address: IAddress }) => {
  return await callApi(`/companies/employees/${employeeID}`, 'patch', data);
};

export const updateCompanyAddress = async (companyId: string, data: { address: IAddress }) => {
  return await callApi(`/company/${companyId}`, 'patch', data);
};

export const updateInvoiceAvailability = async (
  companyId: string,
  data: { settings: { paymentAllowedDaysBeforeDueDate: number } },
) => {
  return await callApi(`/company/${companyId}`, 'patch', data);
};

/** @deprecated */
export const updateCompanyAdmin = async (adminUserID: string, data: any) => {
  return await callApi(`/companies/admins/${adminUserID}`, 'put', data);
};

export const deleteCompanyAdmin = async (adminUserID: string, data: any) => {
  return await callApi(`/companies/admins/${adminUserID}`, 'delete', data, true);
};

export const addCompanyAdmin = async (data: any, skipEmail?: boolean) => {
  if (skipEmail) {
    return await callApi(`/companies/admins?skipEmail=true`, 'post', data, true);
  }
  return await callApi(`/companies/admins`, 'post', data);
};

export const getEmployeeDetails = async (employeeID: string, companyID: string) => {
  return await callApi(`/companies/${companyID}/employees/${employeeID}`, 'get', {}, false);
};

export const getCompanyEmployees = async (companyID: string) => {
  return await callApi(`/employees`, 'get', {
    companyID,
  });
};

export const sendInviteEmail = (employeeID: string) => {
  return callApi(`/companies/employees/${employeeID}/invite`, 'post', {});
};

export const addZealEmployee = async ({
  companyID,
  zealEmployeeId,
  firstName,
  lastName,
  email,
  zealCompanyId,
}: {
  companyID: string;
  zealEmployeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  zealCompanyId: string;
}) => {
  const data = {
    companyID,
    zealEmployeeId,
    zealCompanyId,
    firstName,
    lastName,
    email,
  };
  return await callApi(`/companies/employees/w2`, 'post', data);
};

export const updateRecurrences = async (companyId: string, data: any) => {
  return await callApi(`/companies/${companyId}/recurrences`, 'post', data);
};

export const getCompanyW2PayrollInfo = async (companyID: string, startDate: string) => {
  const response: Promise<IW2PayrollInfo> = await callApi(
    `/companies/${companyID}/w2-payroll/info?startDate=${startDate}`,
    'get',
    {},
    false,
  );
  return response;
};

export const getNextCheckDate = async (companyID: string, startDate: string) => {
  return await callApi(`/companies/${companyID}/w2-payroll/info?startDate=${startDate}`, 'get', {}, false);
};

export const updateAutopilotStatus = (companyID: string, autopilotOn: boolean) => {
  return callApi(`/companies/${companyID}/w2-payroll/autopilot?autopilotOn=${autopilotOn}`, 'post', {});
};

export const updateCompanyW2Payroll = async (
  companyId: string,
  data: { w2Payroll: { firstCheckDate?: string; payFrequency: IZealPaySchedule; autopilotOn: boolean } },
) => {
  return await callApi(`/company/${companyId}`, 'patch', data);
};

export const getCompanyBankAccountDetails = async (companyId: string) => {
  return await callApi(`/companies/${companyId}/bank/account`, 'get', {}, false);
};
