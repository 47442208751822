import React from 'react';
import Link from 'next/link';
import { NavItem } from './navigations';
import { useNavigation } from './NavigationContext';

interface MainNavItemProps {
  item: NavItem;
  index: number;
}

export const MainNavItem: React.FC<MainNavItemProps> = ({ item, index }) => {
  const { activeMainNav, setActiveMainNav } = useNavigation();
  const isActive = activeMainNav === index;
  const defaultRoute = item.subNavs ? item.subNavs[0].href : item.href;
  const isComingSoon = defaultRoute === '/finance' || defaultRoute === '/apps';

  if (item.showInMainNav === false) {
    return null;
  }

  const handleClick = () => {
    setActiveMainNav(index);
  };

  return (
    <li key={item.name}>
      <Link href={isComingSoon ? '#' : defaultRoute}>
        <span
          onClick={() => {
            !isComingSoon && handleClick();
          }}
          className={`relative flex items-center rounded-md border px-3 py-2  ${
            isActive ? 'border-neutral-200 bg-neutral-50' : 'border-transparent'
          } ${isComingSoon ? 'disabled cursor-not-allowed' : 'hover:border-neutral-200'}`}
        >
          {item.icon}
          <span className="ml-2 text-sm font-medium">{item.name}</span>
          {isComingSoon && (
            <span className="absolute -top-1 left-12 ml-4 w-fit min-w-[50px] rounded border border-stone-200 bg-neutral-50 px-1 py-0.5 text-[6px] font-medium text-stone-600">
              Coming Soon
            </span>
          )}
        </span>
      </Link>
    </li>
  );
};
