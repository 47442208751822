import React from 'react';

const Invoices = () => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.07711 0.810941L9.32711 0.808567C10.7082 0.808042 11.8281 1.92748 11.8281 3.30856V12.8952C11.8281 14.2759 10.7088 15.3952 9.32806 15.3952H3.07806C1.69735 15.3952 0.578064 14.2759 0.578064 12.8952V3.31094C0.578064 1.9306 1.69677 0.811465 3.07711 0.810941Z"
        stroke="#1F2937"
        strokeWidth="1.04167"
      />
      <path d="M2.99988 4H6.33321" stroke="#1F2937" strokeWidth="1.04167" strokeLinecap="round" />
      <path d="M2.99988 7.33301H9.66654" stroke="#1F2937" strokeWidth="1.04167" strokeLinecap="round" />
      <path
        d="M10.1613 17.1917C13.0665 17.1917 15.4217 14.8366 15.4217 11.9313C15.4217 9.02607 13.0665 6.6709 10.1613 6.6709C7.25605 6.6709 4.90088 9.02607 4.90088 11.9313C4.90088 14.8366 7.25605 17.1917 10.1613 17.1917Z"
        fill="white"
        stroke="#1F2937"
        strokeWidth="1.04167"
      />
      <path
        d="M10.1611 14.5618V15.0879V14.5618ZM10.1611 8.77539V9.30143V8.77539ZM11.7393 10.6165C11.7393 9.8906 11.0328 9.30143 10.1611 9.30143C9.28948 9.30143 8.58301 9.8906 8.58301 10.6165C8.58301 11.3425 9.28948 11.9316 10.1611 11.9316C11.0328 11.9316 11.7393 12.5208 11.7393 13.2467C11.7393 13.9727 11.0328 14.5618 10.1611 14.5618C9.28948 14.5618 8.58301 13.9727 8.58301 13.2467"
        fill="white"
      />
      <path
        d="M10.1611 14.5618V15.0879M10.1611 14.5618C11.0328 14.5618 11.7393 13.9727 11.7393 13.2467C11.7393 12.5208 11.0328 11.9316 10.1611 11.9316C9.28948 11.9316 8.58301 11.3425 8.58301 10.6165C8.58301 9.8906 9.28948 9.30143 10.1611 9.30143M10.1611 14.5618C9.28948 14.5618 8.58301 13.9727 8.58301 13.2467M10.1611 8.77539V9.30143M10.1611 9.30143C11.0328 9.30143 11.7393 9.8906 11.7393 10.6165"
        stroke="#1F2937"
        strokeWidth="1.04167"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Invoices;
