import React from 'react';

const ReloadIcon = ({ className = '' }) => {
  return (
    <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M20.7603 13.2995C20.7603 18.956 16.1571 23.5597 10.5 23.5597C4.84236 23.5597 0.239746 18.9566 0.239746 13.2995C0.239746 8.03469 4.22581 3.68926 9.33844 3.1103L8.17427 1.94613C7.72896 1.50135 7.72896 0.778769 8.17427 0.333979C8.61957 -0.111326 9.3411 -0.111326 9.78642 0.333979L12.8261 3.37371C13.2715 3.81848 13.2715 4.54107 12.8261 4.98586L9.78642 8.02559C9.34482 8.46719 8.62066 8.47249 8.17373 8.02559C7.72842 7.58081 7.72842 6.85823 8.17373 6.41344L9.14334 5.44382C5.38839 6.09058 2.5198 9.36257 2.5198 13.2994C2.5198 17.7 6.09927 21.2795 10.4999 21.2795C14.9006 21.2795 18.4801 17.7 18.4801 13.2994C18.4801 11.1677 17.6504 9.16321 16.1432 7.65665C15.6979 7.21187 15.6979 6.48982 16.1432 6.0445C16.5896 5.59919 17.31 5.59919 17.7554 6.0445C19.693 7.98155 20.7603 10.5587 20.7603 13.2995Z"
        fill="#2571CF"
      />
    </svg>
  );
}

export default ReloadIcon;
