import React from 'react';

const TheraMonitorWithBg = () => {
  return (
    <svg width="80" height="82" viewBox="0 0 80 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.765869" width="79" height="80.4679" rx="39.5" fill="white" />
      <path d="M46.5085 62.195H33.3799L33.8249 56.4097H46.0634L46.5085 62.195Z" fill="#DFDFDF" />
      <path d="M47.3985 61.5271H32.7124V62.8622H47.3985V61.5271Z" fill="#DFDFDF" />
      <path
        d="M65.0889 19.1379H14.9113C14.5572 19.1379 14.2176 19.2786 13.9672 19.529C13.7168 19.7794 13.5762 20.119 13.5762 20.473V51.0692H66.424V20.473C66.424 20.2977 66.3895 20.1241 66.3224 19.9621C66.2553 19.8001 66.1569 19.653 66.033 19.529C65.909 19.405 65.7618 19.3067 65.5998 19.2396C65.4378 19.1725 65.2642 19.1379 65.0889 19.1379Z"
        fill="#333333"
      />
      <path
        d="M13.5762 51.0688V56.0755C13.5762 56.2508 13.6107 56.4244 13.6778 56.5864C13.7449 56.7484 13.8432 56.8956 13.9672 57.0196C14.0912 57.1435 14.2384 57.2419 14.4004 57.309C14.5623 57.3761 14.7359 57.4106 14.9113 57.4106H65.0889C65.443 57.4106 65.7826 57.2699 66.033 57.0196C66.2834 56.7692 66.424 56.4296 66.424 56.0755V51.0688H13.5762Z"
        fill="#DFDFDF"
      />
      <path d="M63.8648 21.9192H16.2461V48.6213H63.8648V21.9192Z" fill="#DFDFDF" />
      <path d="M62.4919 23.6545H17.4771V46.0799H62.4919V23.6545Z" fill="white" />
      <path
        d="M20.6399 39.4092C20.5528 39.4093 20.4692 39.4441 20.4077 39.5058C20.3461 39.5674 20.3115 39.651 20.3115 39.7382C20.3115 39.8253 20.3461 39.9089 20.4077 39.9706C20.4692 40.0323 20.5528 40.067 20.6399 40.0672H29.3738C29.4609 40.067 29.5445 40.0323 29.606 39.9706C29.6676 39.9089 29.7022 39.8253 29.7022 39.7382C29.7022 39.651 29.6676 39.5674 29.606 39.5058C29.5445 39.4441 29.4609 39.4093 29.3738 39.4092H20.6399Z"
        fill="#DFDFDF"
      />
      <path
        d="M20.525 41.156C20.4344 41.1566 20.3476 41.2728 20.2835 41.4792C20.2195 41.6856 20.1836 41.9653 20.1836 42.2569C20.1836 42.5485 20.2195 42.8283 20.2835 43.0347C20.3476 43.2411 20.4344 43.3573 20.525 43.3578H24.6128C24.7034 43.3573 24.7903 43.2411 24.8543 43.0347C24.9183 42.8283 24.9542 42.5485 24.9542 42.2569C24.9542 41.9653 24.9183 41.6856 24.8543 41.4792C24.7903 41.2728 24.7034 41.1566 24.6128 41.156H20.525Z"
        fill="#2571CF"
      />
      <path
        d="M20.6399 37.7932C20.5528 37.7934 20.4692 37.8281 20.4077 37.8898C20.3461 37.9515 20.3115 38.0351 20.3115 38.1222C20.3115 38.2094 20.3461 38.293 20.4077 38.3546C20.4692 38.4163 20.5528 38.4511 20.6399 38.4512H29.3738C29.4609 38.4511 29.5445 38.4163 29.606 38.3546C29.6676 38.293 29.7022 38.2094 29.7022 38.1222C29.7022 38.0351 29.6676 37.9515 29.606 37.8898C29.5445 37.8281 29.4609 37.7934 29.3738 37.7932H20.6399Z"
        fill="#DFDFDF"
      />
      <rect x="20.1836" y="26.1101" width="4.40367" height="1.46789" rx="0.366972" fill="#F5F5F5" />
      <rect x="26.4219" y="26.1101" width="4.40367" height="1.46789" rx="0.366972" fill="#F5F5F5" />
      <rect x="32.6606" y="26.1101" width="4.40367" height="1.46789" rx="0.366972" fill="#F5F5F5" />
      <rect x="38.8994" y="26.1101" width="4.40367" height="1.46789" rx="0.366972" fill="#F5F5F5" />
      <rect x="20.1836" y="29.7798" width="15.4128" height="6.6055" rx="0.366972" fill="#F5F5F5" />
      <rect x="37.064" y="29.7798" width="10.2752" height="6.6055" rx="0.366972" fill="#F5F5F5" />
      <rect x="48.4404" y="29.7798" width="10.2752" height="6.6055" rx="0.366972" fill="#F5F5F5" />
      <rect x="56.147" y="26.1101" width="4.40367" height="1.46789" rx="0.366972" fill="#F5F5F5" />
      <rect x="0.5" y="0.765869" width="79" height="80.4679" rx="39.5" stroke="#EEEEEE" />
    </svg>
  );
};

export default TheraMonitorWithBg;
