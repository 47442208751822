import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import Link from 'next/link';

import Navbar from 'components/container-components/navbar/Navbar';
import { NavigationProvider } from 'components/container-components/navbar/NavigationContext';
import { ContractorRoutes, AdminRoutes, AuthRoutes } from 'routes';
import { useUser } from 'context/user';
import NoTeam from '../NoTeam';
import { checkIfManagerWithoutTeams } from 'utils/affiliations';

const nonLayoutRoutes = [
  '/',
  ContractorRoutes.EmployeeOnboarding,
  AdminRoutes.PayrollOnboarding,
  ...Object.values(AuthRoutes),
];

const InvoiceSpecificRoutes = [
  AdminRoutes.PayApprovedInvoices,
  AdminRoutes.ReviewInvoices,
  AdminRoutes.AddTeam,
  AdminRoutes.ManageTeam,
];

const NormalLayout = ({ children }) => {
  return (
    <NavigationProvider>
      <header className="fixed top-0 z-10 w-[100vw]">
        <Navbar />
      </header>
      <main className="mt-[64px] flex flex-1 overflow-x-hidden">{children}</main>
    </NavigationProvider>
  );
};

const InvoicesLayout = ({ children }) => (
  <NavigationProvider>
    <header className="fixed top-0 z-10 h-[64px] w-[100vw] border-b border-neutral-200 bg-neutral-800 px-6 py-3">
      <div className="mx-auto flex max-w-screen-xl items-center py-2.5">
        <Link href={AdminRoutes.Contractors}>
          <img className="mr-3 h-6 w-auto" src="/logo/thera-logo-white.svg" alt="Thera-logo" />
        </Link>
      </div>
    </header>
    <main className="mt-[64px] px-6">
      <div className="mx-auto w-full max-w-screen-xl">{children}</div>
    </main>
  </NavigationProvider>
);

export default function Layout({ children }) {
  const { roleType, affiliationsByCompany, currentCompany, permissionsVersion } = useUser();
  const router = useRouter();
  const [isNormalLayoutRoute, setNormalLayoutRoute] = useState(() => {
    return !nonLayoutRoutes.includes(router.pathname);
  });
  const [isInvoiceSpecificRoute, setIsInvoiceSpecificRoute] = useState(() => {
    return InvoiceSpecificRoutes.includes(router.pathname);
  });
  const isManagerWithoutTeams = checkIfManagerWithoutTeams(roleType, affiliationsByCompany, currentCompany);
  const isSettingsRoute = router.pathname.includes('account');

  useEffect(() => {
    setNormalLayoutRoute(!nonLayoutRoutes.includes(router.pathname));
    setIsInvoiceSpecificRoute(InvoiceSpecificRoutes.includes(router.pathname));
  }, [router.pathname]);

  if (permissionsVersion === 2 && isManagerWithoutTeams && !isSettingsRoute) {
    return (
      <NormalLayout>
        <NoTeam />
      </NormalLayout>
    );
  }

  if (isInvoiceSpecificRoute) {
    return <InvoicesLayout>{children}</InvoicesLayout>;
  }

  if (isNormalLayoutRoute) {
    return <NormalLayout>{children}</NormalLayout>;
  }

  return <>{children}</>;
}
