import router from 'next/router';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import React, { useState } from 'react';

import { SearchDropdownProps } from './type';
import SearchIcon from 'icons/SearchIcon';
import { AdminRoutes } from 'routes';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const SearchDropdown = React.forwardRef<any, SearchDropdownProps>(
  (
    {
      filteredActionValues,
      filteredPeopleValues,
      filteredContractValues,
      handleInputChange,
      name,
      onSelectedItemChange,
      selectedItem,
      onEnterPress,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputPress = (value) => {
      handleInputChange(value);
      setInputValue(value);
    };

    return (
      <Combobox as="div" value={selectedItem} onChange={onSelectedItemChange} name={name} nullable className="z-10">
        <div className="relative w-72">
          <Combobox.Button as="div">
            <SearchIcon className="absolute left-3 top-3" />
            <Combobox.Input
              id="Search"
              className="focus:shadow-outline-neutral block w-full rounded-lg border border-neutral-200 bg-white py-2 pl-9 pr-3 leading-5 placeholder-neutral-400 transition duration-150 ease-in-out focus:border-neutral-300 focus:placeholder-neutral-500 focus:outline-none sm:text-sm"
              onChange={(event) => {
                handleInputPress(event.target.value);
              }}
              displayValue={(value: string) => {
                return value ?? '';
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onEnterPress();
                }
              }}
              name={name}
              ref={ref}
              placeholder={'Search'}
              defaultValue={''}
              autoComplete="off" // disable autocomplete
            />
          </Combobox.Button>

          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-neutral-100 text-base shadow-lg ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {inputValue != '' &&
              filteredActionValues.length == 0 &&
              filteredPeopleValues.length == 0 &&
              filteredContractValues.length == 0 && (
                <>
                  <Combobox.Option
                    key={'noResultsFound'}
                    value={'No results found'}
                    className={() =>
                      classNames(
                        'relative h-20 cursor-default select-none bg-white py-2 text-gray-900 hover:cursor-pointer',
                      )
                    }
                    disabled={true}
                  >
                    <div className="mt-2 flex flex-col items-center justify-center">
                      <span
                        className={classNames('truncate text-base font-medium text-neutral-900')}
                      >{`No results found`}</span>
                      <span className={classNames('mt-1 truncate text-sm font-normal text-neutral-500')}>
                        {`Try searching again`}
                      </span>
                    </div>
                  </Combobox.Option>
                </>
              )}
            {filteredActionValues.length > 0 && (
              <>
                <Combobox.Option
                  key={'ACTIONS'}
                  value={'ACTIONS'}
                  className={() =>
                    classNames(
                      'relative cursor-default select-none py-3 pl-3 text-xs font-medium leading-tight text-neutral-400 hover:cursor-pointer',
                    )
                  }
                  disabled={true}
                >
                  <div className="flex">
                    <span className={classNames('truncate')}>ACTIONS</span>
                  </div>
                </Combobox.Option>
                {filteredActionValues.map((value, index) => (
                  <Combobox.Option
                    key={index}
                    value={value.helperLabel}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none bg-white py-2 pl-3 pr-9 hover:cursor-pointer',
                        active ? '!bg-therablue text-white' : 'text-gray-900',
                      )
                    }
                    onClick={() => {
                      router.push(value.href);
                    }}
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex">
                          <span className={classNames('truncate', selected && 'font-semibold')}>
                            {value.helperLabel}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-blue-600',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
            {filteredPeopleValues.length > 0 && (
              <>
                <Combobox.Option
                  key={'PEOPLE'}
                  value={'PEOPLE'}
                  className={() =>
                    classNames(
                      'relative cursor-default select-none py-3 pl-3 text-xs font-medium leading-tight text-neutral-400 hover:cursor-pointer',
                    )
                  }
                  disabled={true}
                >
                  <div className="flex">
                    <span className={classNames('truncate')}>PEOPLE</span>
                  </div>
                </Combobox.Option>
                {filteredPeopleValues.map((value, index) => (
                  <Combobox.Option
                    key={index}
                    value={value.user.firstName + ' ' + value.user.lastName}
                    className={({ active }) =>
                      classNames(
                        'relative h-12 cursor-default select-none bg-white py-2 pl-3 pr-9 hover:cursor-pointer',
                        active ? '!bg-therablue text-white' : 'text-gray-900',
                      )
                    }
                    onClick={() => {
                      const isEmployee = value.employment?.w2Payroll?.zealEmployeeId;
                      const isContractor = value.user?.employeeInformation?.stripeID;
                      if (isContractor) {
                        router.push(`${AdminRoutes.ContractorProfile}/${value.user.userID}`);
                      } else if (isEmployee) {
                        router.push(`${AdminRoutes.EmployeeProfile}/${value.employment.w2Payroll?.zealEmployeeId}`);
                      }
                    }}
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex flex-col">
                          <span className={classNames('truncate', selected && 'font-semibold')}>
                            {`${value.user.firstName} ${value.user.lastName}`}
                          </span>
                          <span
                            className={classNames(
                              'truncate text-xs font-normal',
                              active ? 'text-white' : 'text-neutral-500',
                            )}
                          >
                            {value.employment.jobTitle}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-blue-600',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
            {filteredContractValues.length > 0 && (
              <>
                <Combobox.Option
                  key={'CONTRACTS'}
                  value={'CONTRACTS'}
                  className={() =>
                    classNames(
                      'relative cursor-default select-none py-3 pl-3 text-xs font-medium leading-tight text-neutral-400 hover:cursor-pointer',
                    )
                  }
                  disabled={true}
                >
                  <div className="flex">
                    <span className={classNames('truncate')}>CONTRACTS</span>
                  </div>
                </Combobox.Option>
                {filteredContractValues.map((value, index) => (
                  <Combobox.Option
                    key={index}
                    value={value?.company.companyName}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none bg-white py-2 pl-3 pr-9 hover:cursor-pointer',
                        active ? 'bg-therablue text-white' : 'text-gray-900',
                      )
                    }
                    onClick={() => {
                      router.push(`/contracts/${value?.contract.companyID}`);
                    }}
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex">
                          <span className={classNames('truncate', selected && 'font-semibold')}>
                            {value.company.companyName}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-blue-600',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    );
  },
);

export default SearchDropdown;
