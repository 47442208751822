import { useStytch } from '@stytch/nextjs';
import { useState } from 'react';

import { AuthRoutes } from 'routes';
import { logError } from 'utils/error';
import theraToast from 'components/generic-components/toast/TheraToast';

export const useStytchVerifyEmail = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}): {
  sendVerificationEmail: (emailAddress: string) => Promise<void>;
  isSending: boolean;
} => {
  const [isSending, setIsSending] = useState(false);
  const stytch = useStytch();
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const sendVerificationEmail = async (emailAddress: string) => {
    if (!emailAddress) return;
    if (isSending) return;
    setIsSending(true);
    stytch.magicLinks.email
      .send(emailAddress, {
        login_magic_link_url: origin + AuthRoutes.Authenticate + '?email=' + encodeURIComponent(emailAddress),
        login_expiration_minutes: 60,
        login_template_id: 'email_verification',
      })
      .then((_response) => {
        console.log('Verification Email sent!', _response);
        onSuccess && onSuccess();
      })
      .catch((_reason) => {
        theraToast.error('Failed to send email verification link', {
          id: 'email-sending-failed',
        });
        logError(_reason);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return {
    sendVerificationEmail,
    isSending,
  };
};

export default useStytchVerifyEmail;
