import LoadingSpinner from 'icons/LoadingSpinner';
import React from 'react';

interface IButton extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  htmlType?: 'submit' | 'button';
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'icon'
    | 'plain'
    | 'default'
    | 'theraOutline'
    | 'theraPay'
    | 'theraRedOutline';
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xsmall';
  loading?: boolean;
  disabled?: boolean;
  transition?: boolean;
}

export const btnClassesByVariant = {
  primary:
    'bg-therablue font-medium text-white transition hover:bg-therablue-light active:bg-therablue-dark focus:outline-none focus:ring-blue-500 focus:ring-offset-2 border border-therablue min-w-20',
  secondary:
    'bg-transparent border border-therablue font-medium text-therablue transition hover:bg-neutral-100 active:bg-blue-100 min-w-20',
  outline:
    'border border-gray-300 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 min-w-20',
  icon: 'ml-auto inline-flex items-center bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
  plain: 'p-0 bg-transparent dark:hover:text-white hover:opacity-70',
  default: 'hover:opacity-70',
  theraOutline: 'rounded-lg border border-blue-200 text-sm text-blue-700 transition hover:bg-blue-200 sm:w-auto',
  theraPay:
    'rounded-md bg-slate-900 py-2.5 font-semibold text-white transition hover:bg-darkColor-light active:bg-darkColor-active min-w-20 border border-slate-900',
  theraRedOutline:
    'text-red-600 justify-start rounded-[6px] border border-[#CF2E25] bg-white py-[8px] text-base font-medium first-line:border-opacity-40 hover:cursor-pointer hover:bg-red-50 focus:outline-none focus:ring-red-500 focus:ring-offset-2 min-w-20',
};

export const btnClassesBySize = {
  small: 'py-1.5 px-3.5 text-sm rounded-md !min-w-10',
  medium: 'py-2.5 px-3.5 text-sm rounded-md',
  large: 'py-3 px-5 text-lg rounded-lg',
  xsmall: 'rounded-lg p-1.5 text-sm',
};

type Ref = HTMLButtonElement;
// eslint-disable-next-line react/display-name
const Button = React.forwardRef<Ref, IButton>(
  (
    {
      variant = 'primary',
      children,
      className,
      htmlType = 'button',
      size = 'medium',
      loading,
      disabled,
      transition = false,
      ...rest
    },
    ref,
  ) => {
    const strokeColor = variant === 'primary' || variant === 'theraPay' ? '#ffffff' : '#191919';
    return (
      <button
        ref={ref}
        role="button"
        className={`${
          variant === 'default'
            ? `${btnClassesByVariant[variant]} ${className || ''} ${
                transition ? 'transition duration-300 ease-in-out hover:-translate-y-0.5' : ''
              }`
            : // : variant === 'theraPay' || variant === 'theraRedOutline'
              // ? btnClassesByVariant[variant]
              `inline-flex items-center justify-center text-center ${variant !== 'plain' && btnClassesBySize[size]} ${
                btnClassesByVariant[variant]
              } ${className || ''} ${transition ? 'transition duration-300 ease-in-out hover:-translate-y-0.5' : ''} ${
                disabled ? 'opacity-60 disabled:pointer-events-none' : ''
              }`
        }`}
        type={htmlType}
        disabled={disabled}
        {...rest}
        tabIndex={-1}
      >
        {loading ? <LoadingSpinner height={22} width={22} strokeColor={strokeColor} /> : children}
      </button>
    );
  },
);

export default Button;
