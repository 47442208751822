import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon, NoSymbolIcon } from '@heroicons/react/24/outline';
import { toast as reactToast } from 'react-hot-toast';

const successClassName = '!bg-green-600 text-sm font-medium !text-white !rounded-md !shadow-xl !px-4.5 !py-2.5 ';
const errorClassName = '!bg-therared text-sm font-medium !text-white !rounded-md !shadow-xl !px-4.5 !py-2.5 ';
const warningClassName = '!bg-yellow-600 text-sm font-medium !text-white !rounded-md !shadow-xl !px-4.5 !py-2.5';
const infoClassName = '!bg-therablue text-sm font-medium !text-white !rounded-md !shadow-xl !px-4.5 !py-2.5 ';

interface IOptions {
  id?: string;
  duration?: number;
  icon?: JSX.Element | string | null;
  className?: string;
}

const theraToast = {
  success: (message: ((id: any) => JSX.Element) | JSX.Element | string, options?: IOptions) => {
    return reactToast.success(message, {
      icon: options?.icon ? options?.icon : <CheckCircleIcon className="h-6 w-6 min-w-[2rem]" />,
      className: `${successClassName} ${options?.className}`,
      ...(options?.id && { id: options.id }),
      ...(options?.duration && { duration: options.duration }),
    });
  },
  error: (message: ((id: any) => JSX.Element) | JSX.Element | string, options?: IOptions) => {
    if (!message || message === 'UnAuthorized') {
      console.warn('UnAuthorized');
      return reactToast.dismiss(options?.id);
    }
    return reactToast.error(message, {
      icon: options?.icon ? options?.icon : <NoSymbolIcon className="h-6 w-6 min-w-[2rem]" />,
      className: `${errorClassName} ${options?.className}`,
      ...(options?.id && { id: options.id }),
      ...(options?.duration && { duration: options.duration }),
    });
  },
  warning: (message: ((id: any) => JSX.Element) | JSX.Element | string, options?: IOptions) => {
    return reactToast.error(message, {
      icon: options?.icon ? options?.icon : <ExclamationCircleIcon className="h-6 w-6 min-w-[2rem]" />,
      className: `${warningClassName} ${options?.className}`,
      ...(options?.id && { id: options.id }),
      ...(options?.duration && { duration: options.duration }),
    });
  },
  info: (message: ((id: any) => JSX.Element) | JSX.Element | string, options?: IOptions) => {
    return reactToast.success(message, {
      icon: options?.icon ? options?.icon : <ClockIcon className="h-6 w-6 min-w-[2rem]" />,
      className: `${infoClassName} ${options?.className}`,
      ...(options?.id && { id: options.id }),
      ...(options?.duration && { duration: options.duration }),
    });
  },
  dismiss: (id?: string) => {
    return reactToast.dismiss(id);
  },
};

export default theraToast;
