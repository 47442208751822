import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import CloseModalButton from './CloseModalButton';

const GenericModal = ({
  open,
  onClose,
  titleIcon,
  title,
  titleCenter = false,
  subTitle,
  footer,
  children,
  fullScreen,
  showHeaderBorder = true,
  position,
  width,
  maxHeight,
  rightSideButton,
  modalInnerScrollable = true,
}: {
  open: boolean;
  onClose: () => void;
  titleIcon?: React.ReactNode;
  title?: string | React.ReactNode;
  titleCenter?: boolean;
  subTitle?: string;
  footer?: React.ReactNode;
  children: React.ReactNode;
  fullScreen?: boolean;
  showHeaderBorder?: boolean;
  position?: string;
  /**
   * @description weight of the modal
   * @example weight="32rem" or weight="50%"
   */
  width?: string;
  /**
   * @description max height of the modal
   * @example maxHeight="80vh"
   */
  maxHeight?: string;
  rightSideButton?: React.ReactNode;
  modalInnerScrollable?: boolean;
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className={`fixed inset-0${modalInnerScrollable ? '' : ' overflow-y-auto'}`}>
            <div className={`flex ${position ? position : 'min-h-full items-center'} justify-center p-6 text-center`}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`${
                    fullScreen ? 'min-h-[80vh] w-full' : width ? width : ''
                  } transform rounded-md bg-white text-left align-middle shadow-xl transition-all ${
                    modalInnerScrollable ? 'max-h-[84vh] overflow-y-auto' : ''
                  } ${maxHeight ? `!max-h-[${maxHeight}] overflow-y-auto` : ''}`}
                >
                  {title && (
                    <div className="flex flex-row items-start px-6 py-4">
                      <div className="flex w-full flex-col">
                        <div className={`mt-1 flex items-center  gap-2 ${titleCenter ? 'flex-1 justify-center' : ''}`}>
                          {titleIcon && titleIcon}
                          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-neutral-900">
                            {title}
                          </Dialog.Title>
                        </div>
                        {subTitle && <p className="mt-2 max-w-lg text-sm text-gray-500">{subTitle}</p>}
                      </div>
                      <div className="ml-auto">
                        {rightSideButton ? rightSideButton : <CloseModalButton onClose={onClose} className="ml-auto" />}
                      </div>
                    </div>
                  )}
                  <div
                    className={`${
                      title && showHeaderBorder ? 'border-t border-gray-200 pt-6' : 'border-transparent pt-2'
                    } px-6 pb-6 dark:border-gray-600`}
                  >
                    {children}
                  </div>
                  {footer && <div className="border-t border-gray-200 p-6 dark:border-gray-600">{footer}</div>}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default GenericModal;
