import React from 'react'

const HomeDashboard = ({ className='' }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13 20.5714H5.84898H3.57143C2.88944 20.5714 2.23539 20.3005 1.75315 19.8183C1.27092 19.336 1 18.682 1 18V9.82843C1 9.29799 1.21071 8.78929 1.58579 8.41421L5.5 4.5L8.58579 1.41421C9.36684 0.633164 10.6332 0.633165 11.4142 1.41421L14.5 4.5L18.4142 8.41421C18.7893 8.78929 19 9.29799 19 9.82843V18C19 18.682 18.7291 19.336 18.2468 19.8183C17.7646 20.3005 17.1106 20.5714 16.4286 20.5714H13ZM13 20.5714V14.5C13 13.6716 12.3284 13 11.5 13H8.5C7.67157 13 7 13.6716 7 14.5V20.5"
        stroke="#404040"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeDashboard;
