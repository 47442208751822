import { Menu, Transition } from '@headlessui/react';
import Link from 'next/link';
import { Fragment, useMemo } from 'react';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/solid';

import ProfileCircle from 'components/generic-components/ProfileCircle';
import SearchBar from 'components/container-components/navbar/navSearchBar/SearchBar';
import { useUser } from 'context/user';
import ArrowDown from 'icons/ArrowDown';
import { AdminRoutes, AuthRoutes, ContractorRoutes } from 'routes';
import { getUserInitials } from 'lib/miscellaneous';
import Settings from 'icons/Settings';
import CopyableText from 'components/generic-components/copyableText';
import { useModal } from 'hooks/useModal';
import SwitchAccountModal from './SwitchAccountModal';
import { showIDToCopy } from 'utils/user';
import { getAccountSwitchingOptions } from 'utils/affiliations';

const MainNavRightSide = ({
  isFallback,
  handleFallbackRouting,
}: {
  isFallback?: boolean;
  handleFallbackRouting?: (href: string) => void;
  userRoleType?: 'manager' | 'worker';
}) => {
  const { user, logout, currentCompany, affiliationsByCompany, isLoading, roleType } = useUser() || {};
  const isWorker = roleType === 'worker';
  const { firstName = '', lastName = '', userID = '' } = user || {};
  const switchAccountModal = useModal();

  const accountSettingsRoute = isWorker ? ContractorRoutes.AccountPreferences : AdminRoutes.AccountPreferences;
  const userInitials = () =>
    getUserInitials({
      firstName,
      lastName,
    });
  const handleLogout = () => {
    !isFallback ? logout() : handleFallbackRouting(AuthRoutes.Logout);
  };
  const showUserIDToCopy = useMemo(() => showIDToCopy(), []);

  const goToHelpCenter = () => {
    window.open('https://intercom.help/thera/en/', '_blank');
  };

  const handleSwitchAccount = () => {
    switchAccountModal.handleOpen();
  };

  const canSwitchAccounts = useMemo(() => {
    if (!affiliationsByCompany) return false;
    const numberOfCompanies = Object.keys(affiliationsByCompany).length;
    if (numberOfCompanies > 1) {
      return true;
    } else {
      const companyId = Object.keys(affiliationsByCompany)[0];
      const affiliation = affiliationsByCompany[companyId];
      const accountSwitchOptions = getAccountSwitchingOptions(affiliation);
      if (accountSwitchOptions.length > 1) {
        return true;
      } else {
        return false;
      }
    }
  }, [affiliationsByCompany]);

  return (
    <div className="flex items-center">
      {!isFallback && user && <SearchBar />}
      {isWorker && currentCompany && (
        <div className="ml-4 max-w-[200px] truncate text-ellipsis rounded-full border border-therablue px-2 py-1 text-center text-sm font-medium text-neutral-500">
          {currentCompany?.dbaName}
        </div>
      )}

      <ul className="ml-4 flex items-center space-x-3">
        <li className="inline-flex">
          <Link
            onClick={() => handleFallbackRouting(accountSettingsRoute)}
            href={accountSettingsRoute}
            className="group flex h-8 w-8 items-center justify-center transition-all hover:rounded-full hover:bg-neutral-100"
            data-testid="account-settings-link"
          >
            <Settings className="h-5 w-5 fill-neutral-600 group-hover:fill-neutral-900" />
          </Link>
        </li>
        <li className="inline-flex">
          <Menu>
            <div className="relative flex justify-center">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-[100] mt-10 min-w-[200px] rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {!isFallback && (
                    <div className="px-4 py-2">
                      <p className="text-sm font-medium">
                        {user?.firstName} {user?.lastName}
                      </p>
                      {/* <p className="text-xs font-medium text-neutral-800">{userEmploymentType}</p> */}
                      <p className="mt-0.5 text-xs font-medium text-neutral-500">{user?.email}</p>
                      {showUserIDToCopy && (
                        <CopyableText
                          text={userID}
                          containerClassName="mt-2"
                          textClassName="text-xs text-[#295E73]"
                          showCopyIcon={false}
                        />
                      )}
                    </div>
                  )}
                  {canSwitchAccounts && (
                    <Menu.Item
                      as="a"
                      onClick={handleSwitchAccount}
                      disabled={isLoading || !affiliationsByCompany}
                      className="flex border-t border-neutral-100 px-4 py-2 text-sm text-neutral-800 hover:cursor-pointer hover:bg-gray-100"
                    >
                      Switch Accounts
                    </Menu.Item>
                  )}
                  <Menu.Item
                    as="a"
                    onClick={goToHelpCenter}
                    className="flex border-t border-neutral-100 px-4 py-2 text-sm text-neutral-800 hover:cursor-pointer hover:bg-gray-100"
                  >
                    Help Center
                  </Menu.Item>
                  <Menu.Item
                    as="a"
                    onClick={handleLogout}
                    className="flex border-t border-neutral-100 px-4 py-2 text-sm text-orange-700 hover:cursor-pointer hover:bg-gray-100"
                  >
                    <ArrowRightOnRectangleIcon className="-ml-0.5 mr-2 h-5 w-5" />
                    Logout
                  </Menu.Item>
                </Menu.Items>
              </Transition>
              <Menu.Button as={Fragment}>
                <a className="flex cursor-pointer items-center" data-testid="user-profile-link">
                  <ProfileCircle
                    text={isFallback ? '' : userInitials()}
                    textSize="text-sm"
                    id={(!isFallback && userID) || ''}
                    height="h-8"
                    width="w-8"
                  />
                  <ArrowDown className="ml-1" />
                </a>
              </Menu.Button>
            </div>
          </Menu>
        </li>
      </ul>

      {affiliationsByCompany && (
        <SwitchAccountModal isOpen={switchAccountModal.isOpen} onClose={switchAccountModal.handleClose} />
      )}
    </div>
  );
};

export default MainNavRightSide;
