import { isStytchProdEnv } from './user';

export const Intercom_APP_ID = !isStytchProdEnv ? 'g3tec45n' : 'i70ex4os';

export const updateIntercomSettings = ({ name, email, created_at, role, user_id }) => {
  if (typeof window === 'undefined') return;
  if (!(window as any)?.Intercom) return;
  (window as any).Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: Intercom_APP_ID,
    name, // Full name
    email, // Email address
    created_at, // Signup date as a Unix timestamp
    role,
    user_id,
  });
};

/**<script>
  var APP_ID = "WORKSPACE_ID"; // Replace this with your workspace ID
  window.intercomSettings = {
    app_id: APP_ID,
    name: "Jane Doe", // Full name
    email: "customer@example.com", // Email address
    created_at: 1312182000 // Signup date as a Unix timestamp
  };
</script>
<script>(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();</script>*/

export const addIntercomScript = ({ name, email, created_at }) => {
  const APP_ID = Intercom_APP_ID;

  // Intercom integration script
  const intercomScript = document.createElement('script');
  intercomScript.type = 'text/javascript';
  intercomScript.async = true;
  intercomScript.src = `https://widget.intercom.io/widget/${APP_ID}`;
  const x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(intercomScript, x);

  // Intercom settings
  (window as any).intercomSettings = {
    app_id: APP_ID,
    name, // Full name
    email, // Email address
    created_at, // Signup date as a Unix timestamp
  };

  // Reattach Intercom activator and update settings
  if (typeof (window as any).Intercom === 'function') {
    (window as any).Intercom('reattach_activator');
    (window as any).Intercom('update', (window as any).intercomSettings);
  } else {
    const i = function () {
      // eslint-disable-next-line prefer-rest-params
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    (window as any).Intercom = i;
  }

  /* if (typeof intercomFunction === 'function') {
    // If Intercom function is already defined, reattach and update settings
    intercomFunction('reattach_activator');
    intercomFunction('update', (globalWindow as any).intercomSettings);
    console.log('intercomFunction update');
  } else {
    const documentElement = document;

    // Define a new Intercom function if not already present
    const newIntercomFunction = function (...args) {
      newIntercomFunction.queue.push(args);
    };
    newIntercomFunction.queue = [];
    newIntercomFunction.c = function (args) {
      newIntercomFunction.queue.push(args);
    };

    // Replace the global Intercom function with the new one
    (globalWindow as any).Intercom = newIntercomFunction;

    // Load the Intercom widget script
    const loadIntercomWidget = function () {
      const scriptElement = documentElement.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.id = 'intercom-script';
      scriptElement.src = 'https://widget.intercom.io/widget/' + APP_ID;

      console.log('loadIntercomWidget');
      const existingScriptElement = documentElement.getElementsByTagName('script')[0];
      console.log(existingScriptElement);
      existingScriptElement.parentNode.insertBefore(scriptElement, existingScriptElement);
    };
    console.log('intercomFunction attach');

    // Attach script load event based on browser compatibility
    if ((globalWindow as any).attachEvent) {
      (globalWindow as any).attachEvent('onload', loadIntercomWidget);
      console.log('intercomFunction attach 2');
    } else {
      globalWindow.addEventListener('load', loadIntercomWidget, false);
      console.log('intercomFunction attach 1');
    }
  } */
};

/**
 * <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.intercomSettings = {
              app_id: "${APP_ID}",
            };
          `,
        }}
      />
      <script async src="https://widget.intercom.io/widget/i70ex4os" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `,
        }}
      />
    </Head>
*/

// Intercom Events Update
export const trackIntercomEvent = (eventName: string, metadata?: any) => {
  if (typeof window === 'undefined') return;
  if (!(window as any)?.Intercom) return;
  (window as any).Intercom('trackEvent', eventName, metadata);
};

export const trackIntercomAddPaymentMethod = (companyID: string, adminUserId: string) => {
  trackIntercomEvent('add-payment-method', {
    companyID,
    adminUserId,
  });
};

export const trackIntercomAddTeamMember = (companyID: string, adminUserId: string) => {
  trackIntercomEvent('add-team-member', {
    companyID,
    adminUserId,
  });
};

export const trackIntercomReviewedAInvoice = (companyID: string, adminUserId: string) => {
  trackIntercomEvent('reviewed-invoices', {
    companyID,
    adminUserId,
  });
};

export const trackIntercomPaidAInvoice = (companyID: string, adminUserId: string) => {
  trackIntercomEvent('paid-an-invoice', {
    companyID,
    adminUserId,
  });
};
