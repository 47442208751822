import { useRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';
import { EmploymentType } from '@thera-hr/api-sdk/api/resources/model/resources/thera';

import { useNavigation } from 'components/container-components/navbar/NavigationContext';
import { useCompany } from 'context/company';
import { IEmployeeContract } from 'hooks/useEmployeeContractSWR';
import SearchDropdown from './searchDropdown';
import { IEmployment, IUser } from 'types';
import { useUser } from 'context/user';
import { AdminRoutes } from 'routes';

interface IHelperLabels {
  helperLabel: string;
  href: string;
}

interface IEmployeeData {
  employment: IEmployment;
  user: IUser;
}

const SearchBar = () => {
  const router = useRouter();
  const { navigation } = useNavigation();
  const { company } = useCompany();
  const { user, currentContract, affiliationsByCompany } = useUser();
  const isEmployee = useMemo(() => currentContract?.w2Payroll?.zealEmployeeId, [currentContract]);
  const isContractor = useMemo(() => user?.userEmploymentType === EmploymentType.Contractor, [user]);
  const isW2Admin = useMemo(() => !user?.employeeInformation && !!company?.w2Payroll?.zealId, [user, company]);
  const { hasEOREmployees } = company || {};

  const employeeContracts = Object.values(affiliationsByCompany || {}).flatMap((item) =>
    item.contracts.map((contract) => ({
      company: item.company,
      contract: contract,
    })),
  ) as unknown as IEmployeeContract[];
  const employeeData = company?.employeesAndEmployment;
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredActionData, setFilteredActionData] = useState<IHelperLabels[]>([]);
  const [filteredPeopleData, setFilteredPeopleData] = useState<IEmployeeData[]>([]);
  const [filteredContractData, setFilteredContractData] = useState<IEmployeeContract[]>([]);

  const helperLabels = useMemo(() => {
    const helperLabelTexts: IHelperLabels[] = [];
    navigation.forEach((nav) => {
      nav.subNavs?.forEach((subNav) => {
        const isAllowed =
          subNav?.onlyShownTo === undefined ||
          (subNav.onlyShownTo && subNav.onlyShownTo === 'w2Admin' && isW2Admin) ||
          (subNav.onlyShownTo && subNav.onlyShownTo === 'hasEOREmployees' && hasEOREmployees) ||
          (subNav.onlyShownTo === 'contractor' && isContractor) ||
          (subNav.onlyShownTo === 'employee' && isEmployee);

        if (subNav.helperLabel && subNav.isDisabled !== true && isAllowed) {
          helperLabelTexts.push({
            helperLabel: subNav.helperLabel,
            href: subNav.href,
          });
        }
      });
    });
    return helperLabelTexts;
  }, [navigation, isEmployee, isContractor, isW2Admin, hasEOREmployees]);

  // const filterData = (arr, filterFn) => (inputValue.length === 0 ? [] : arr.filter(filterFn));
  // The below is the same as the next, but with typescript but it doesn't work
  // because the TS complains that JSX element 'T' has no corresponding closing tag.ts(17008
  // const filterData = <T>(arr: T[], filterFn: (item: T) => boolean) => (inputValue.length === 0 ? [] : arr.filter(filterFn));
  function filterData<T>(inputString: string, arr: T[], filterFn: (item: T) => boolean): T[] {
    return inputString.length === 0 ? [] : arr.filter(filterFn);
  }

  const handleInputChange = (textInputData: string) => {
    setInputValue(textInputData);
    setFilteredActionData(
      filterData(textInputData, helperLabels, (item: IHelperLabels) =>
        item.helperLabel.toLowerCase().includes(textInputData.toLowerCase()),
      ),
    );

    if (employeeData) {
      setFilteredPeopleData(
        filterData(textInputData, employeeData, (item: IEmployeeData) =>
          (item.user.firstName + item.user.lastName).toLowerCase().includes(textInputData.toLowerCase()),
        ),
      );
    }

    if (employeeContracts) {
      setFilteredContractData(
        filterData(textInputData, employeeContracts, (item: IEmployeeContract) =>
          item.company.companyName.toLowerCase().includes(textInputData.toLowerCase()),
        ),
      );
    }
  };

  const handleItemChange = (item: string) => {
    const input = item === null ? '' : item;
    setInputValue(input);
  };

  useEffect(() => {
    let targetHref = '';
    if (!inputValue) {
      return;
    } else {
      // find the item in the list
      // first check if it's an action item
      const actionItem = helperLabels?.find((item) => item.helperLabel === inputValue);
      if (actionItem) {
        targetHref = actionItem.href;
      } else {
        // check if it's a person
        const personItem = employeeData?.find((item) => `${item.user.firstName} ${item.user.lastName}` === inputValue);
        if (personItem) {
          const isEmployee = personItem.employment?.w2Payroll?.zealEmployeeId;
          const isContractor = personItem.user?.employeeInformation?.stripeID;
          if (isContractor) {
            targetHref = `${AdminRoutes.ContractorProfile}/${personItem.user.userID}`;
          } else if (isEmployee) {
            targetHref = `${AdminRoutes.EmployeeProfile}/${personItem.employment.w2Payroll?.zealEmployeeId}`;
          }
        } else {
          // check if it's a contract
          const contractItem = employeeContracts?.find((item) => item.company.companyName === inputValue);
          if (contractItem) {
            targetHref = `/contracts/${contractItem.company.companyID}`;
          }
        }
      }
      // if targetHref is not empty, redirect to the targetHref
      if (targetHref) {
        router.push(targetHref);
        // reset the input value
        setTimeout(() => {
          setInputValue('');
        }, 0);
      }
    }
  }, [employeeContracts, employeeData, helperLabels, inputValue, router]);

  return (
    <SearchDropdown
      name="SearchDropdown"
      selectedItem={inputValue}
      handleInputChange={handleInputChange}
      onSelectedItemChange={handleItemChange}
      filteredActionValues={filteredActionData}
      filteredPeopleValues={filteredPeopleData}
      filteredContractValues={filteredContractData}
      onEnterPress={() => {
        console.log('enter pressed');
      }}
    />
  );
};

export default SearchBar;
