
const SecurityLockIcon = ({ className }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M0.752441 12.302C0.752441 9.96964 0.752441 8.80261 1.4774 8.07847C2.20154 7.35352 3.36856 7.35352 5.70097 7.35352H12.299C14.6314 7.35352 15.7984 7.35352 16.5226 8.07847C17.2475 8.80261 17.2475 9.96964 17.2475 12.302C17.2475 14.6345 17.2475 15.8015 16.5226 16.5256C15.7984 17.2506 14.6314 17.2506 12.299 17.2506H5.70097C3.36856 17.2506 2.20154 17.2506 1.4774 16.5256C0.752441 15.8015 0.752441 14.6345 0.752441 12.302Z" stroke="black" strokeWidth="1.04" />
      <path d="M4.05127 7.35294V5.70343C4.05008 4.49814 4.48883 3.33384 5.28516 2.42908C6.08149 1.52432 7.18065 0.941296 8.37634 0.789443C9.57203 0.63759 10.782 0.927347 11.7793 1.60433C12.7765 2.28131 13.4923 3.29897 13.7925 4.4663M8.9998 10.652V13.951" stroke="black" strokeWidth="1.04" strokeLinecap="round" />
    </svg>
  );
}

export default SecurityLockIcon;
