import { useUser } from 'context/user';
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { adminNavigation, NavItem, userNavigation } from './navigations';

interface NavigationState {
  activeMainNav: number;
  activeSubNav: number;
  activeSubSideNav: number;
}

interface NavigationAction {
  type: 'SET_MAIN_NAV' | 'SET_SUB_NAV' | 'SET_SUB_SIDE_NAV';
  value: number;
}

interface NavigationProviderProps {
  children: React.ReactNode;
}

const initialState: NavigationState = {
  activeMainNav: -1,
  activeSubNav: -1,
  activeSubSideNav: -1,
};

const NavigationContext = createContext<{
  state: NavigationState;
  dispatch: React.Dispatch<NavigationAction>;
  navigation: NavItem[];
}>({
  state: initialState,
  dispatch: () => undefined,
  navigation: [],
});

const navigationReducer = (state: NavigationState, action: NavigationAction): NavigationState => {
  switch (action.type) {
    case 'SET_MAIN_NAV':
      return {
        ...state,
        activeMainNav: action.value,
      };
    case 'SET_SUB_NAV':
      return {
        ...state,
        activeSubNav: action.value,
      };
    case 'SET_SUB_SIDE_NAV':
      return {
        ...state,
        activeSubSideNav: action.value,
      };
    default:
      return state;
  }
};

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const { user, roleType } = useUser();
  const [state, dispatch] = useReducer(navigationReducer, initialState);
  const isWorker = roleType === 'worker';
  const navigation = useMemo(() => (user ? (isWorker ? userNavigation : adminNavigation) : []), [user, isWorker]);

  return <NavigationContext.Provider value={{ state, dispatch, navigation }}>{children}</NavigationContext.Provider>;
};

export const useNavigation = () => {
  const { state, dispatch, navigation } = useContext(NavigationContext);
  const { activeMainNav, activeSubNav, activeSubSideNav } = state;

  const setActiveMainNav = useCallback(
    (value: number) => {
      dispatch({ type: 'SET_MAIN_NAV', value });
    },
    [dispatch],
  );
  const setActiveSubNav = useCallback(
    (value: number) => {
      dispatch({ type: 'SET_SUB_NAV', value });
    },
    [dispatch],
  );
  const setActiveSubSideNav = useCallback(
    (value: number) => {
      dispatch({ type: 'SET_SUB_SIDE_NAV', value });
    },
    [dispatch],
  );

  return {
    activeMainNav,
    activeSubNav,
    activeSubSideNav,
    setActiveMainNav,
    setActiveSubNav,
    setActiveSubSideNav,
    navigation,
  };
};
