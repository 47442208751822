import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useStytch, useStytchSession, useStytchUser } from '@stytch/nextjs';
import posthog from 'posthog-js';
import { Api } from '@thera-hr/api-sdk/api/resources/api/client/Client';
import { CompanyAffiliation } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/nextjs';

import { getUserAffiliations, getUserProfile } from 'services/userApi';
import { AdminRoutes, AuthRoutes } from 'routes';
import { ICompany, IEmployment, IUser, IZealEmployee } from 'types';
import { canRunPosthog } from 'utils/window';
import { getEmployeeContracts } from 'services/employeeApi';
import { getEmployeeOnboardingLink, getZealEmployee } from 'services/zealApi';
import { createTheraClient } from 'services/fern';
import { hasSentTheraWelcomeEmail_LS_key } from 'pages/verify-email';
import {
  initialTheraRoute_LS_key,
  initialTheraRouteTimestamp_LS_key,
} from 'components/container-components/LoginRoutingHandler';
import { EmployeeType, StripeOnboardingStatus } from 'lib/constants';
import { updateIntercomSettings } from 'utils/intercom';
import { cancelAxiosRequests } from 'services/config';
import { SESSION_DURATION_MINUTES } from 'pages/authenticate';
import { logError } from 'utils/error';

import {
  checkIfEmailVerified,
  isSuperAdminMode as _isSuperAdminMode,
  checkIfNewInvitedUserWithoutPasswordAndNoOAuth,
  groupContractsByCompanyId,
  isStytchProdEnv,
} from 'utils/user';
import {
  LS_key_Current_loggedIn_as_RoleType,
  LS_key_Current_loggedIn_companyId,
  getAccountSwitchingOptions,
  get_ls_saved_values,
} from 'utils/affiliations';
import { checkIfUserHasAccessToCompanyAndRole } from 'utils/rolesPermissions';

// remove this later once all imports are updated
export const isSuperAdminMode = _isSuperAdminMode;
// IAN user id in prod
export const IAN_SuperAdmin_UserID = 'user-live-7d2f6e25-e9f9-4342-9104-c460e60ac6eb';

type _IUser = IUser & {
  isSuperAdmin?: boolean;
  shouldSkipEmailVerification?: boolean;
  isEmailVerified?: boolean;
  userEmploymentType?: EmployeeType;
  isIAN_SuperAdmin?: boolean;
  isOnboarded?: boolean;
};

type UserContextType = {
  user: _IUser | null;
  setUser: (user: _IUser | null) => void;
  logout: (redirectToSignIn?: boolean) => Promise<void>;
  mutate: (refreshSession?: boolean) => Promise<void>;
  isLoading: boolean;
  isLoggingOut: boolean;
  theraFernApi?: Api;
  affiliationsByCompany?: Record<string, CompanyAffiliation>;
  permissionsVersion?: number;
  setPermissionsVersion?: (version: number) => void;
  currentTeamId: string;
  setCurrentTeamId: (teamId: string) => void;
  allUseContractsWithCompany?: { contract: IEmployment; company: ICompany }[];
  currentContractId?: string;
  setCurrentContractId?: (contractId: string) => void;
  currentContract?: IEmployment;
  setCurrentContract?: (contract: IEmployment) => void;
  /** currentCompany to be only used for workers now, for managers/admin, please use company from the company context */
  currentCompany?: ICompany;
  setCurrentCompany?: (company: ICompany) => void;
  roleType?: 'manager' | 'worker';
  setRoleType?: (roleType: 'manager' | 'worker') => void;
  showAccountSwitcher?: boolean;
  setShowAccountSwitcher?: (show: boolean) => void;
  showAllTeamsContracts?: boolean;
  setShowAllTeamsContracts?: (show: boolean) => void;
};

const UserContext = createContext<UserContextType>(null);

/**
 * Workflows:
 * The UserProvider is a wrapper component that provides the user context to all the children components.
 * It also fetches the user profile from the API and sets it in the context.
 * After fetching the user profile, it also checks if the user is a contractor or an employee and redirects them to the appropriate route.
 * If email is not verified, it redirects the user to the verify email page.
 * In case of a 404, it assumes that stytch user exists but user profile does not exist and redirects the user to the admin onboarding page.
 */

const UserProvider = ({ children }) => {
  const { user: stytchUser } = useStytchUser();
  const { session } = useStytchSession();
  const [user, setUser] = useState<_IUser>();
  const router = useRouter();
  const stytch = useStytch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [theraApi, setTheraApi] = useState<Api>();
  const [affiliationsByCompany, setAffiliationsByCompany] = useState<{
    [companyId: string]: CompanyAffiliation;
  }>();
  const [permissionsVersion, setPermissionsVersion] = useState<number>(null);
  const [currentTeamId, setCurrentTeamId] = useState<string>(null);
  const [currentContractId, setCurrentContractId] = useState<string>(null);
  const [currentCompany, setCurrentCompany] = useState<ICompany>(null);
  const [currentContract, setCurrentContract] = useState<IEmployment>(null);
  const [roleType, setRoleType] = useState<'manager' | 'worker'>(null);
  const [showAccountSwitcher, setShowAccountSwitcher] = useState<boolean>(false);
  const [userAllContractsWithCompany, setUserAllContractsWithCompany] =
    useState<{ contract: IEmployment; company: ICompany }[]>(null);
  const [showAllTeamsContracts, setShowAllTeamsContracts] = useState<boolean>(true);

  const logout = useCallback(
    async (redirectToSignIn = true) => {
      try {
        setIsLoading(true);
        setIsLoggingOut(true);
        await stytch.session.revoke({ forceClear: true });
        canRunPosthog() && posthog.reset();
        Sentry.setUser(null);
        setTheraApi(undefined);
        setRoleType(undefined);
        setAffiliationsByCompany(undefined);
        setCurrentCompany(undefined);
        setCurrentContract(undefined);
        setCurrentContractId(undefined);
        cancelAxiosRequests();
      } catch (error) {
        console.error(error);
      } finally {
        setUser(null);
        setIsLoading(false);
        setIsLoggingOut(false);
        // also remove initial route from local storage, to be safe
        localStorage.removeItem(initialTheraRoute_LS_key);
        localStorage.removeItem(initialTheraRouteTimestamp_LS_key);
        localStorage.removeItem(hasSentTheraWelcomeEmail_LS_key);
        localStorage.removeItem(LS_key_Current_loggedIn_as_RoleType);
        localStorage.removeItem(LS_key_Current_loggedIn_companyId);
        if (redirectToSignIn) {
          await router.push(AuthRoutes.SignIn);
        }
      }
    },
    [router, stytch],
  );

  const refreshSession = useCallback(async () => {
    const sess = stytch.session.getSync();
    if (sess) {
      console.info('refreshing session!');
      await stytch.session
        .authenticate({
          session_duration_minutes: SESSION_DURATION_MINUTES,
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [stytch]);

  const fetchUserProfile = useCallback(
    async (shouldRefreshSession = false) => {
      setIsLoading(true);
      shouldRefreshSession && (await refreshSession());
      let isEmailVerified = false;
      if (stytchUser && session) {
        const theraApi_ = createTheraClient();
        setTheraApi(theraApi_);
        const email = stytchUser.emails ? stytchUser.emails[0].email : '';
        canRunPosthog() &&
          posthog.identify(stytchUser.user_id, {
            email,
          });
        Sentry.setUser({ email, userId: stytchUser.user_id });

        isEmailVerified = await checkIfEmailVerified(stytchUser);
        const isNewInvitedUserWithoutPasswordAndNoOAuth = checkIfNewInvitedUserWithoutPasswordAndNoOAuth(stytchUser);
        const isIAN_SuperAdmin = stytchUser.user_id === IAN_SuperAdmin_UserID;

        getUserProfile(stytchUser.user_id)
          .then(async (userResponse: IUser) => {
            const userId = userResponse?.userID;
            const name = `${userResponse.firstName} ${userResponse.lastName}`;
            const createAt = DateTime.fromISO(stytchUser?.created_at).toSeconds();
            let _roleType;
            let permissionsV;
            let _currentContract;
            // set the user profile in context
            setUser((user) => ({
              ...user,
              ...userResponse,
              isEmailVerified,
              ...(isSuperAdminMode() && { isSuperAdmin: true, shouldSkipEmailVerification: isEmailVerified }),
              isIAN_SuperAdmin,
            }));

            const intercomValues = {
              email: userResponse.email,
              name: name,
              created_at: createAt,
              role: roleType,
              user_id: userId,
            };

            if (isNewInvitedUserWithoutPasswordAndNoOAuth) {
              // if user is invited and has not set password and has no oauth,
              // and redirect to set password page only if user is not in super admin mode
              setIsLoading(false);
              if (!isSuperAdminMode()) {
                if (router.pathname === AuthRoutes.ResetPassword) return;
                return await router.replace({
                  pathname: AuthRoutes.ResetPassword,
                  query: { create_new_password: true },
                });
              }
            }

            // for both worker and manager, if email is not verified, redirect to email verification page
            if (!isEmailVerified) {
              setIsLoading(false);
              if (router.pathname === AuthRoutes.SuperAdmin) return;
              if (router.pathname === AuthRoutes.EmailVerification) return;
              await router.replace({
                pathname: AuthRoutes.EmailVerification,
                query: { email },
              });
              return;
            } else {
              // if email is verified, remove hasSentFirstEmail from local storage
              localStorage.removeItem(hasSentTheraWelcomeEmail_LS_key);
            }

            // load affiliations
            const userAffiliationsRes = await getUserAffiliations(userId).catch((error) => {
              logError(error);
            });

            const ls_affiliations_saved_values = get_ls_saved_values();
            const saved_companyId = ls_affiliations_saved_values?.companyId;
            const saved_roleType = ls_affiliations_saved_values?.roleType;
            const { affiliationsByCompany } = userAffiliationsRes || {};

            if (affiliationsByCompany && Object.keys(affiliationsByCompany).length > 0) {
              const numberOfCompanies = Object.keys(affiliationsByCompany).length;
              const loadedFromLocalStorage = checkIfUserHasAccessToCompanyAndRole(
                saved_companyId,
                saved_roleType,
                affiliationsByCompany,
              );
              // set permissions version to 2 as user is on permissions V2
              permissionsV = 2;
              setPermissionsVersion(2);
              setAffiliationsByCompany(affiliationsByCompany);
              // if there are multiple companies, show switch account modal
              // else load the company if user has the manager role
              // or load the worker role
              if (loadedFromLocalStorage) {
                // load the saved company and role type from local storage
                const saved_company = affiliationsByCompany[saved_companyId]?.company;
                const companyPermissionsVersion = saved_company?.flags?.permissionsVersion;
                // Update the permissions version if the company has a permissions version
                if (companyPermissionsVersion) {
                  setPermissionsVersion(companyPermissionsVersion);
                  permissionsV = companyPermissionsVersion;
                }
                setCurrentCompany(saved_company as unknown as ICompany);
                setRoleType(saved_roleType);
                _roleType = saved_roleType;
                console.info('loaded from local storage', saved_company, saved_roleType);
                // if user has manager role, load the first team, else load the first contract
                if (saved_roleType === 'manager') {
                  const firstTeamId = Object.keys(affiliationsByCompany[saved_companyId]?.teams)[0];
                  setCurrentTeamId(firstTeamId);
                } else {
                  const firstContract = affiliationsByCompany[saved_companyId]?.contracts?.[0];
                  const employmentType = firstContract?.type;
                  _currentContract = firstContract;
                  setCurrentContract(firstContract as unknown as IEmployment);
                  setCurrentContractId(firstContract?.id);

                  setUser((user) => ({
                    ...user,
                    userEmploymentType: employmentType as EmployeeType,
                  }));
                }
              } else {
                if (numberOfCompanies > 1) {
                  setShowAccountSwitcher(true);
                  // go to home page and show switch account modal to select the company/account
                  router.push(AdminRoutes.Dashboard);
                } else {
                  const companyId = Object.keys(affiliationsByCompany)[0];
                  const affiliation = affiliationsByCompany[companyId];
                  const companyPermissionsVersion = affiliation?.company?.flags?.permissionsVersion;
                  // Update the permissions version if the company has a permissions version
                  if (companyPermissionsVersion) {
                    setPermissionsVersion(companyPermissionsVersion);
                    permissionsV = companyPermissionsVersion;
                  }
                  const accountSwitchOptions = getAccountSwitchingOptions(affiliation);
                  // if user has manager role, load the company
                  // else load the worker role
                  const managerRole = accountSwitchOptions.find((option) => option.type === 'manager');
                  if (managerRole) {
                    setRoleType('manager');
                    _roleType = 'manager';
                    updateIntercomSettings({
                      ...intercomValues,
                      role: 'manager',
                    });
                    const firstTeamId = Object.keys(affiliation?.teams)[0];
                    setCurrentTeamId(firstTeamId);
                    setCurrentCompany(affiliation?.company as unknown as ICompany);
                    // router.push(AdminRoutes.Dashboard);
                  } else {
                    // load the first contract
                    setRoleType('worker');
                    _roleType = 'worker';
                    const firstContract = affiliation?.contracts?.[0];
                    const currentCompany = affiliation?.company;
                    const employmentType = firstContract?.type;
                    _currentContract = firstContract;
                    setCurrentContract(firstContract as unknown as IEmployment);
                    setCurrentContractId(firstContract?.id);
                    setCurrentCompany(currentCompany as unknown as ICompany);
                    // const firstContract = affiliation?.contracts?.[0];
                    // const employmentType = firstContract?.type;
                    setUser((user) => ({
                      ...user,
                      userEmploymentType: employmentType as EmployeeType,
                    }));
                    updateIntercomSettings({
                      ...intercomValues,
                      role: 'worker',
                    });
                    // router.push(ContractorRoutes.Dashboard);
                  }
                }
              }
            } else {
              // set permissions version to 1 as user seems to be on permissions V1
              setPermissionsVersion(1);
              permissionsV = 1;

              // check if the user is a worker (contractor/employee) or manager/admin
              const isWorker = !!userResponse?.employeeInformation;
              setRoleType(isWorker ? 'worker' : 'manager');
              _roleType = isWorker ? 'worker' : 'manager';
              // load intercom
              updateIntercomSettings({
                ...intercomValues,
                role: isWorker ? 'worker' : 'manager',
              });
              if (isWorker) {
                const employeeContracts = await getEmployeeContracts(userId).catch((error) => {
                  logError(error);
                });
                if (!employeeContracts || employeeContracts.length === 0) {
                  // if user is employee but has no contracts, redirect to onboarding
                  setIsLoading(false);
                  return;
                }
                setUserAllContractsWithCompany(employeeContracts);
                // group contracts by companyId
                const contractsByCompanyId = groupContractsByCompanyId(employeeContracts);
                setAffiliationsByCompany(contractsByCompanyId as unknown as Record<string, CompanyAffiliation>);

                // take the first contract and set it as current contract
                const firstContract = employeeContracts?.[0]?.contract;
                const contractCompany = employeeContracts?.[0]?.company;
                _currentContract = firstContract;
                setCurrentContract(firstContract);
                setCurrentContractId(firstContract?.id);
                setCurrentCompany(contractCompany);
              }
            }
            if (_roleType === 'worker') {
              const employmentType = _currentContract?.type;
              // set the userEmploymentType, contract and company in context if user is employee to avoid extra API calls
              const isEOREmployee = employmentType === EmployeeType.EMPLOYEE;
              const isZealUser = !!_currentContract?.w2Payroll?.zealEmployeeId;
              const isWorkerOnboarded =
                (permissionsV === 2 &&
                  !!userResponse?.employeeInformation &&
                  userResponse?.employeeInformation?.address !== null) ||
                (userResponse?.employeeInformation?.paymentsVersion === 1 &&
                  userResponse?.employeeInformation?.onboardingStatus ===
                    StripeOnboardingStatus.STRIPE_LINK_COMPLETED) ||
                (userResponse?.employeeInformation?.paymentsVersion === 2 &&
                  userResponse?.employeeInformation?.address !== null);
              setUser((user) => ({
                ...user,
                ...userResponse,
                isEmailVerified,
                isOnboarded: isWorkerOnboarded,
                userEmploymentType: isEOREmployee
                  ? EmployeeType.EMPLOYEE
                  : isZealUser
                  ? EmployeeType.US_EMPLOYEE
                  : EmployeeType.CONTRACTOR,
              }));

              // if the user is zeal employee, redirect to zeal dashboard
              if (isZealUser && isEmailVerified) {
                const zealEmployee: IZealEmployee = await getZealEmployee(
                  _currentContract?.w2Payroll?.zealCompanyId,
                  _currentContract?.w2Payroll?.zealEmployeeId,
                )
                  .then((response) => response?.data)
                  .catch((error) => {
                    logError(error);
                  });
                const isZealEmployeeOnboarded = zealEmployee?.onboarded;
                setUser((user) => ({
                  ...user,
                  isOnboarded: isZealEmployeeOnboarded,
                }));
                // if the zeal employee is not onboarded, redirect to onboarding
                if (!isZealEmployeeOnboarded) {
                  const zealOnboardingUrl = await getEmployeeOnboardingLink({
                    zealId: _currentContract?.w2Payroll?.zealCompanyId,
                    employeeID: _currentContract?.w2Payroll?.zealEmployeeId,
                  })
                    .then((response) => response?.data)
                    .catch((error) => {
                      logError(error);
                    });
                  await router.push(`/employee-onboarding?zealOnboardingUrl=${zealOnboardingUrl}`);
                }
                setIsLoading(false);
                return;
              }

              if (!isWorkerOnboarded) {
                await router.replace(AuthRoutes.SignUpContractorOnboarding);
                setIsLoading(false);
                return;
              }
            }
            setIsLoading(false);
          })
          .catch(async (error) => {
            // stytch user exists but user profile on Thera does not
            if (error?.httpStatus === 404) {
              if (!isStytchProdEnv || isSuperAdminMode()) {
                // if user is not in prod, redirect to admin onboarding
                setIsLoading(false);
                await router.replace(AuthRoutes.SignUpOnboarding);
              } else {
                try {
                  // Check if the user is not authorized
                  const authorizationResponse = await theraApi_.thera.company.companyApi.checkCanCreateAccount({
                    email,
                  });
                  if (!authorizationResponse?.authorized) {
                    // User is not authorized, redirect to the booking page
                    await router.push(AuthRoutes.SignUpNeedsBooking);
                  } else {
                    // Redirect to the regular sign-up page
                    await router.push(AuthRoutes.SignUpOnboarding);
                  }
                } catch (authorizationError) {
                  console.error(authorizationError);
                } finally {
                  setIsLoading(false);
                }
              }
            } else {
              logError(error);
            }
          });
      } else {
        setIsLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshSession, stytchUser, session],
  );

  useEffect(() => {
    if (!stytchUser || isLoggingOut) return;
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserProfile, stytchUser]);

  const exposed = {
    user,
    setUser,
    logout,
    mutate: fetchUserProfile,
    isLoading,
    isLoggingOut,
    theraFernApi: theraApi,
    affiliationsByCompany,
    permissionsVersion,
    setPermissionsVersion,
    currentTeamId,
    setCurrentTeamId,
    currentContractId,
    setCurrentContractId,
    currentContract,
    setCurrentContract,
    currentCompany,
    setCurrentCompany,
    roleType,
    setRoleType,
    showAccountSwitcher,
    setShowAccountSwitcher,
    userAllContractsWithCompany,
    showAllTeamsContracts,
    setShowAllTeamsContracts,
  };

  return <UserContext.Provider value={exposed}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
