import React from 'react'

const Resources = ({ className=''}) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.12038 1.79115H5.91781C5.41037 1.79125 4.91631 1.95397 4.50813 2.25545C4.09995 2.55694 3.79914 2.9813 3.64984 3.46629L1.10549 11.7354C1.0358 11.9613 1.00024 12.1963 1 12.4327V16.8231C1 17.4526 1.25006 18.0563 1.69517 18.5014C2.14028 18.9465 2.74398 19.1965 3.37346 19.1965H19.1965C19.826 19.1965 20.4297 18.9465 20.8748 18.5014C21.3199 18.0563 21.57 17.4526 21.57 16.8231V12.4327C21.57 12.1964 21.5341 11.9612 21.4645 11.7354L18.9223 3.46629C18.773 2.9813 18.4722 2.55694 18.064 2.25545C17.6558 1.95397 17.1617 1.79125 16.6543 1.79115H14.4496M1 12.0762H5.07181C5.51253 12.0763 5.94452 12.1991 6.31937 12.4309C6.69423 12.6626 6.99715 12.9942 7.19421 13.3884L7.46425 13.9285C7.66139 14.3229 7.96447 14.6546 8.33954 14.8863C8.7146 15.1181 9.14681 15.2409 9.58771 15.2408H12.9823C13.4232 15.2409 13.8554 15.1181 14.2305 14.8863C14.6055 14.6546 14.9086 14.3229 15.1057 13.9285L15.3758 13.3884C15.5729 12.994 15.876 12.6624 16.2511 12.4306C16.6261 12.1988 17.0583 12.0761 17.4993 12.0762H21.57M11.285 1V9.70269M11.285 9.70269L8.12038 6.53808M11.285 9.70269L14.4496 6.53808"
        stroke="#404040"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Resources;
