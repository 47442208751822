import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { CompanyAffiliation } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';

import { useUser } from './user';
import { getUserAffiliations } from 'services/userApi';
import { getAccountSwitchingOptions } from 'utils/affiliations';

type CompanyAffiliationsContext = {
  affiliationsByCompany?: Record<string, CompanyAffiliation>;
  mutate: () => Promise<void>;
  isLoading: boolean;
  currentTeamId: string;
  setCurrentTeamId: (teamId: string) => void;
  currentUserRoleType?: 'manager' | 'worker';
};

const Context = createContext<CompanyAffiliationsContext>(null);

/**
 * @deprecated use `useUser` instead
 */
const CompanyAffiliationsProvider = ({ children }) => {
  const { user, isLoading: isUserLoading } = useUser();
  const [isLoading, setIsLoading] = useState(isUserLoading || false);
  const [currentTeamId, setCurrentTeamId] = useState<string>(null);
  const [currentUserRoleType, setCurrentUserRoleType] = useState<'manager' | 'worker'>(null);
  const [affiliationsByCompany, setAffiliationsByCompany] = useState<{
    [companyId: string]: CompanyAffiliation;
  }>();

  const getCompanyAffiliations = useCallback(async () => {
    // if user is not logged in
    if (!user || isUserLoading) return;
    setIsLoading(true);
    const userId = user.userID;

    try {
      // const response = await theraFernApi.thera.company.companyApi.getAllCompanyAffiliations(userId);
      const response = await getUserAffiliations(userId);
      setAffiliationsByCompany(response.affiliationsByCompany);
      // set current team id to the first team id
      if (response?.affiliationsByCompany && Object.keys(response.affiliationsByCompany).length > 0) {
        const firstCompanyId = Object.keys(response.affiliationsByCompany)[0];
        const firstCompany = response.affiliationsByCompany[firstCompanyId];
        const switchingOptions = getAccountSwitchingOptions(firstCompany);
        if (switchingOptions.length > 0) {
          setCurrentUserRoleType('manager');
        } else {
          const roleType = switchingOptions[0].type;
          setCurrentUserRoleType(roleType);
        }
        const firstCompanyFirstTeamId = Object.keys(response.affiliationsByCompany[firstCompanyId]?.teams)[0];
        setCurrentTeamId(firstCompanyFirstTeamId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user, isUserLoading]);

  useEffect(() => {
    getCompanyAffiliations();
  }, [getCompanyAffiliations, user]);

  const exposed = {
    affiliationsByCompany,
    mutate: getCompanyAffiliations,
    isLoading,
    currentTeamId,
    setCurrentTeamId,
    currentUserRoleType,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

/**
 * @deprecated use `useUser` instead
 */
export const useCompanyAffiliations = () => useContext(Context);

export default CompanyAffiliationsProvider;
