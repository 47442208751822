import { useCompany } from 'context/company';
import { TheraManagerExtraPermissions, TheraOrgRoles, TheraTeamRoles } from 'lib/constants';
import { useUser } from 'context/user';
import { TeamPermission } from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';

/** Only Team Managers and Team Admins can create or edit invoices */
export const usePermissions = () => {
  const { company } = useCompany() || {};
  const { affiliationsByCompany, currentTeamId, setCurrentTeamId, permissionsVersion } = useUser() || {};
  const companyId = company?.companyID;
  const companyPermissions = affiliationsByCompany?.[companyId];
  const isV2 = permissionsVersion === 2;

  const isOrgAdmin = isV2 ? companyPermissions?.orgRoleAndDetails?.role === TheraOrgRoles.role_admin : true;
  /**
   * Only Org Admins can add or remove payment methods, change payment method details, etc.
   */
  const canManagePaymentMethods = isV2 ? isOrgAdmin : true;
  /**
   * Only Org Admins can manage managers - add or remove members, change roles, etc.
   */
  const canManageManagers = isV2 ? isOrgAdmin : true;
  /**
   * Only Org Admins can manage teams - add or remove members, change team name, etc.
   */
  const canManageTeams = isV2 ? isOrgAdmin : true;

  const canManageSettings = isV2 ? isOrgAdmin : true;

  /**
   * Only Team Admins can manage their own team
   **/
  const canManageTeamById = (teamId: string) =>
    isV2 ? companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_admin : true;

  /**
   * Only Team Admins/Managers with permissions can review invoices for their own team
   **/
  const canReviewInvoiceByTeamId = (teamId: string) => {
    if (!isV2) return true;
    return (
      // if Team Admin
      companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_admin ||
      // if Team Manager/Payer but have permission to review (approve/reject) invoices
      ((companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_people_manager ||
        companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_people_manager_and_payer) &&
        companyPermissions?.teams[teamId]?.permissions?.includes(
          TheraManagerExtraPermissions.perm_approve_payroll_and_payments.id as TeamPermission,
        ))
    );
  };

  const canPayInvoiceByTeamId = (teamId: string) => {
    console.log('canPayInvoiceByTeamId', teamId, companyPermissions?.teams[teamId]?.role);
    return isV2
      ? companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_admin ||
          companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_people_manager_and_payer
      : true;
  };

  const canCreateEditInvoiceByTeamId = (teamId: string) => {
    if (!isV2) return true;
    return (
      // if Team Admin
      companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_admin ||
      // if Team Manager/Payer but have permission to review (approve/reject) invoices
      ((companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_people_manager ||
        companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_people_manager_and_payer) &&
        companyPermissions?.teams[teamId]?.permissions?.includes(
          TheraManagerExtraPermissions.perm_adjust_payroll_and_payments.id as TeamPermission,
        ))
    );
  };

  const isViewerByTeamId = (teamId: string) =>
    isV2 ? companyPermissions?.teams[teamId]?.role === TheraTeamRoles.role_viewer_sensitive : true;

  return {
    isOrgAdmin,
    companyPermissions,
    canManageManagers,
    canManagePaymentMethods,
    canManageTeams,
    canManageTeamById,
    canReviewInvoiceByTeamId,
    canPayInvoiceByTeamId,
    canCreateEditInvoiceByTeamId,
    currentTeamId,
    isViewerByTeamId,
    setCurrentTeamId,
    canManageSettings,
  };
};
