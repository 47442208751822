// tailwind neutral-900 #171717 is used currently as the default color for the spinner
export default function LoadingSpinner({
  height = 40,
  width = 40,
  strokeColor = '#171717',
  pageLoader = false,
  className = '',
}) {
  return (
    <div
      className={`loader${pageLoader ? ' page-loader' : ''}${className ? ` ${className}` : ''}`}
      data-testid="loadingSpinner"
    >
      <svg viewBox="0 0 32 32" width={width} height={height}>
        <circle id="spinner" cx="16" cy="16" r="14" stroke={strokeColor} fill="none"></circle>
      </svg>
    </div>
  );
}
