import * as Sentry from '@sentry/nextjs';

// const isStytchProd = process.env.NEXT_PUBLIC_STYTCH_PROJECT_ENV !== 'test';
const isProd = process.env.NODE_ENV === 'production';

/**
 * This function is used to format the error message. Note: Pass error.body if the error is coming from fern
 * @param error
 * @param defaultMessage
 * @returns {string}
 */
export const formatErrorMessage = (error: any, defaultMessage: string) => {
  let message = defaultMessage || 'Something went wrong. Please try again later.';
  if (error?.displayMessage) {
    message = error.displayMessage;
    return message;
  }
  if (error?.body) {
    if (error.body?.message || error.body?.displayMessage) {
      message = error.body.message || error.body.displayMessage;
      return message;
    }
  }
  if (error?.message) {
    message = error.message;
    // If the message directly contains the error string
    if (!message.includes('{')) {
      return message;
    } else {
      // If the message contains a stringified JSON, might be coming from 3rd party API
      // parse it and return the error message
      const errorMsg = parseErrorMessageWhenStringifiedJSON(error);
      if (errorMsg) {
        return errorMsg;
      }
    }
  }
  if (error?.response) {
    if (error.response.data && error.response.data.message) {
      message = error.response.data.message;
      return message;
    }
    if (error.response.message) {
      message = error.response.message;
      return message;
    }
  }
  return message;
};

export const parseErrorMessageWhenStringifiedJSON = (errorResponse) => {
  const embeddedMessage = errorResponse?.message;

  if (!embeddedMessage) return null;

  try {
    const startIndex = embeddedMessage.indexOf('{');
    const endIndex = embeddedMessage.lastIndexOf('}') + 1;
    const embeddedJson = embeddedMessage.slice(startIndex, endIndex);

    const parsedMessage = JSON.parse(embeddedJson);
    // trying to get the error message from the parsed message, since it might be coming from 3rd party API
    const msg =
      parsedMessage?.errors?.[0]?.message ||
      parsedMessage?.message ||
      parsedMessage?.error?.message ||
      parsedMessage?.error_message ||
      parsedMessage?.error_description ||
      parsedMessage?.error;
    return msg;
  } catch (error) {
    console.error('Error while parsing the error message:', error);
    return null;
  }
};

export const captureErrorInSentry = (error: any, data: any, context_name: string) => {
  // capture error in sentry in production
  Sentry.configureScope((scope) => {
    scope.setContext(context_name, {
      data,
      // ...other context data
    });
    // scope.setTag('my_custom_tag', 'tag_value');
  });

  // Capture the custom error
  Sentry.captureException(error, {
    tags: {
      capturedBy: 'Manually Captured Error',
    },
  });
};

export const logError = (error) => {
  console.error(error);
  // if error is string, convert it to Error object
  if (typeof error === 'string') {
    error = new Error(error);
  }
  // if in production, capture error in sentry
  if (isProd) captureErrorInSentry(error, null, 'manual_caught_error');
};
