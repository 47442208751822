import { GetServerSideProps } from 'next';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import LoginLayout from 'components/container-components/layout/LoginLayout';
import { AuthRoutes, CommonRoutes } from 'routes';
import Button from 'components/generic-components/button';
import LoadingSpinner from 'icons/LoadingSpinner';
import CheckMark from 'icons/CheckMark';
import { useUser } from 'context/user';
import LoadingScreen from 'components/container-components/LoadingScreen';
import { IUser } from 'types';
import useStytchVerifyEmail from 'hooks/useVerifyEmail';
import CloseIcon from 'icons/Close';

type _IUser = IUser & { isEmailVerified?: boolean };

export const hasSentTheraWelcomeEmail_LS_key = `hasSentTheraWelcomeEmail`;

export default function VerifyEmailPage({ email }: { email?: string }) {
  const { user, isLoading, logout, isLoggingOut } = useUser();
  const router = useRouter();
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [timeLastEmailSent, setTimeLastEmailSent] = useState(null);
  const [countdown, setCountdown] = useState(30);
  const [firstEmailSent, setFirstEmailSent] = useState<boolean>();

  const { sendVerificationEmail, isSending } = useStytchVerifyEmail({
    onSuccess: () => {
      setMagicLinkSent(true);
      setTimeLastEmailSent(new Date().getTime());
    },
  });

  const sendEmail = useCallback(async () => {
    if (!isSending) {
      await sendVerificationEmail(email);
      setFirstEmailSent(true);
    }
  }, [email, isSending, sendVerificationEmail]);

  useEffect(() => {
    if (user?.isEmailVerified) {
      localStorage.removeItem(hasSentTheraWelcomeEmail_LS_key);
      router.replace(CommonRoutes.Dashboard);
    } else {
      // Retrieve hasSentFirstEmail from local storage and check if it's expired
      const stored = localStorage?.getItem(hasSentTheraWelcomeEmail_LS_key);
      let hasSentFirstEmail = stored ? JSON.parse(stored) : null;
      if (hasSentFirstEmail) {
        if (hasSentFirstEmail.expiry && new Date().getTime() > hasSentFirstEmail.expiry) {
          // Item is expired
          // Remove item from localStorage and set hasSentFirstEmail to null
          localStorage.removeItem(hasSentTheraWelcomeEmail_LS_key);
          hasSentFirstEmail = null;
        } else {
          // Item is not expired
          hasSentFirstEmail = hasSentFirstEmail.value ?? null;
          setFirstEmailSent(hasSentFirstEmail);
        }
      }
    }
  }, [router, user]);

  useEffect(() => {
    let interval;
    if (timeLastEmailSent !== null) {
      interval = setInterval(() => {
        const secondsPassed = Math.floor((new Date().getTime() - timeLastEmailSent) / 1000);
        const remainingSeconds = 30 - secondsPassed;
        setCountdown(remainingSeconds);
        if (remainingSeconds <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timeLastEmailSent]);

  const canResendEmail = timeLastEmailSent === null || countdown <= 0;

  const resendEmail = () => {
    if (canResendEmail) {
      setMagicLinkSent(false);
      sendEmail();
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="relative">
          <div className="fixed right-12 top-12">
            <Button
              variant="default"
              className="flex h-12 w-12 items-center justify-center"
              onClick={() => logout()}
              loading={isLoggingOut}
              disabled={isLoggingOut}
            >
              <CloseIcon className="h-7 w-7" />
            </Button>
          </div>
          <LoginLayout pageTitle="Please verify your email" showRightContainer={false}>
            <div className="mt-6 flex flex-col items-center justify-center">
              {isSending ? (
                <LoadingSpinner width={20} height={20} className="mb-4 opacity-0" />
              ) : magicLinkSent ? (
                <CheckMark className="mb-4 h-7 w-7" />
              ) : null}
              <p className="text-center text-base font-normal text-neutral-800">
                Welcome! We&apos;{firstEmailSent ? 've sent' : 'll send'} you an email to{' '}
                <span className="font-semibold">{email}</span> with a verification link to get started. Click the link
                and you&apos;re all set.
              </p>
              <div className="mt-4">
                <p className="flex items-center text-sm">
                  {firstEmailSent ? 'Didn’t receive a link?' : 'Ready to verify?'}{' '}
                  <Button
                    className="ml-2"
                    onClick={firstEmailSent ? resendEmail : sendEmail}
                    variant={firstEmailSent ? 'plain' : 'primary'}
                    disabled={!canResendEmail || isSending}
                    loading={isSending}
                  >
                    <span className={firstEmailSent && canResendEmail ? 'underline' : ''}>
                      {firstEmailSent ? (canResendEmail ? 'Resend Email' : `Resend in ${countdown}s`) : 'Send Email'}
                    </span>
                  </Button>
                </p>
              </div>
            </div>
          </LoginLayout>
        </div>
      )}
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { email } = context.query;

  if (!email) {
    return {
      redirect: {
        destination: AuthRoutes.SignIn,
        permanent: false,
      },
    };
  }

  return {
    props: {
      email,
    },
  };
};
