import { BuildingOffice2Icon, DocumentTextIcon, GlobeAltIcon, UserIcon, UsersIcon } from '@heroicons/react/24/outline';
import AccountManagementIcon from 'icons/AccountManagementIcon2';
import AppsIcon from 'icons/Apps';
import ComplianceDocsIcon from 'icons/ComplianceDocsIcon';
import ContractorsIcon from 'icons/ContractorsIcon';
import Finance from 'icons/Finance';
import HomeDashboard from 'icons/HomeDashboard';
import Invoices from 'icons/Invoices';
import PaymentMethodIcon from 'icons/PaymentMethodIcon';
import Payroll from 'icons/Payroll';
import Resources from 'icons/Resources';
import SecurityLockIcon from 'icons/SecurityLockIcon';
import Settings from 'icons/Settings';
import TeamIcon from 'icons/TeamIcon';
import TeamsIcon from 'icons/TeamsIcon';
import Transactions from 'icons/Transactions';
import { AdminRoutes, ContractorRoutes } from 'routes';

export interface SubNavItem {
  name: string;
  icon?: React.ReactNode;
  category?: string;
  href?: string;
  isCustomElement?: boolean;
  helperLabel?: string;
  isShownInBar?: boolean;
  onlyShownTo?:
    | 'admin'
    | 'contractor'
    | 'employee'
    | 'all'
    | 'none'
    | 'w2Admin'
    | 'hasEOREmployees'
    | 'isUSCompany'
    | 'orgAdmin'
    | 'permV2';
  isDisabled?: boolean;
  subNavs?: SubNavItem[];
  userFlags?: { [name: string]: string | boolean | number };
}

export interface NavItem {
  name: string;
  icon?: React.ReactNode;
  href: string;
  subNavs?: SubNavItem[];
  showInMainNav?: boolean;
  isTabbedQuery?: boolean;
  helperLabel?: string;
  onlyShownTo?:
    | 'contractor'
    | 'eorEmployee'
    | 'employee'
    | 'all'
    | 'none'
    | 'w2Admin'
    | 'isUSCompany'
    | 'orgAdmin'
    | 'managerWithTeams';
  userFlags?: { [name: string]: string | boolean | number };
}

export const adminNavigation: NavItem[] = [
  {
    name: 'Home',
    icon: <HomeDashboard />,
    href: AdminRoutes.Dashboard,
    showInMainNav: true,
  },
  {
    name: 'Payroll',
    icon: <Payroll />,
    href: AdminRoutes.Team,
    onlyShownTo: 'managerWithTeams',
    subNavs: [
      {
        name: 'teamMenu',
        isShownInBar: true,
        isCustomElement: true,
        href: AdminRoutes.Team,
      },
      {
        name: 'Team',
        icon: <TeamIcon />,
        href: AdminRoutes.Team,
        helperLabel: 'Team, Employees, Contractors',
        isShownInBar: true,
        subNavs: [
          {
            name: 'Add Contractor',
            href: AdminRoutes.AddContractor,
            helperLabel: 'Add New Contractor',
            isShownInBar: false,
          },
          {
            name: 'Add Employee',
            href: AdminRoutes.AddEmployee,
            helperLabel: 'Add New Employee',
            isShownInBar: false,
          },
          {
            name: 'EOR Employee',
            href: AdminRoutes.EOREmployeeProfileByID,
            helperLabel: '',
            isShownInBar: false,
          },
          {
            name: 'Contractor',
            href: AdminRoutes.ContractorProfileByID,
            helperLabel: '',
            isShownInBar: false,
          },
          {
            name: 'Employee',
            href: AdminRoutes.EmployeeProfileByID,
            helperLabel: '',
            isShownInBar: false,
          },
        ],
      },
      {
        name: 'W2 Payroll',
        icon: <Payroll />,
        href: AdminRoutes.Payroll,
        helperLabel: 'Run Payroll, Deductions, Employee Checks',
        isShownInBar: true,
        onlyShownTo: 'w2Admin',
        subNavs: [
          {
            name: 'State Taxes',
            href: AdminRoutes.Taxes,
            helperLabel: 'Taxes, W2, 1099',
          },
          {
            name: 'History',
            href: AdminRoutes.PayrollHistory,
            helperLabel: 'Payroll History, Regular Payroll History',
          },
        ],
      },
      {
        name: 'Contractors',
        icon: <ContractorsIcon className="h-6 w-6" />,
        href: AdminRoutes.Contractors,
        helperLabel: 'Contractors, Recent Invoices',
        isShownInBar: true,
        subNavs: [
          {
            name: 'History',
            href: AdminRoutes.Invoices,
            helperLabel: 'Invoices History',
          },
          {
            name: 'Review',
            href: AdminRoutes.ReviewInvoices,
            helperLabel: 'Review Invoices',
            isShownInBar: false,
          },
          {
            name: 'Approve',
            href: AdminRoutes.PayApprovedInvoices,
            helperLabel: 'Pay Approve Invoices',
            isShownInBar: false,
          },
        ],
      },
      {
        name: 'EOR',
        icon: <GlobeAltIcon className="h-6 w-6" />,
        href: AdminRoutes.EOR,
        helperLabel: 'EOR, EOR Employees, EOR Help & Support',
        isShownInBar: true,
        onlyShownTo: 'hasEOREmployees',
        subNavs: [
          {
            name: 'History',
            href: AdminRoutes.EORPayrollHistory,
            helperLabel: 'EOR Payroll History',
          },
        ],
      },
      {
        name: 'Documents',
        icon: <DocumentTextIcon className="h-6 w-6" />,
        href: AdminRoutes.Documents,
        helperLabel: 'Documents, W8',
        isShownInBar: true,
        onlyShownTo: 'isUSCompany',
      },
      {
        name: 'Deductions',
        href: AdminRoutes.Deductions,
        helperLabel: 'Manage Deductions, 401k, ira, hsa',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Add Contractor',
        href: AdminRoutes.AddContractor,
        helperLabel: 'Add New Contractor',
        isShownInBar: false,
      },
      {
        name: 'Add Employee',
        href: AdminRoutes.AddEmployee,
        helperLabel: 'Add New Employee',
        isShownInBar: false,
      },
      {
        name: 'Earnings',
        href: AdminRoutes.Earnings,
        helperLabel: 'Manage earnings, Reimbursements, Bonuses',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'History',
        href: AdminRoutes.RegularPayrollHistory,
        helperLabel: '',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Work Locations',
        href: AdminRoutes.WorkLocations,
        helperLabel: 'Work Locations',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Regular Payroll',
        href: AdminRoutes.PayrollRun,
        helperLabel: 'Run Payroll',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Employee Checks',
        href: AdminRoutes.EmployeeChecks,
        helperLabel: 'Employee Checks, Pending Checks',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Pending Checks',
        href: AdminRoutes.PendingEmployeeCheck,
        helperLabel: '',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Tax Setup',
        href: AdminRoutes.ZeroWageFilings,
        helperLabel: 'Tax Setup',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Reimbursements',
        href: AdminRoutes.EarningTypes,
        helperLabel: '',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Deductions',
        href: AdminRoutes.DeductionTypes,
        helperLabel: '',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Off-Cycle Payroll',
        href: AdminRoutes.PayrollRunOffCycle,
        helperLabel: 'Run Off-Cycle Payroll',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
      {
        name: 'Review Invoices',
        href: AdminRoutes.ReviewInvoices,
        helperLabel: 'Review Invoices',
        isShownInBar: false,
      },
      {
        name: 'Pay Invoices',
        href: AdminRoutes.PayApprovedInvoices,
        helperLabel: 'Pay Approved Invoices',
        isShownInBar: false,
      },
      {
        name: 'Invoice History',
        href: AdminRoutes.InvoicesHistory,
        helperLabel: 'Invoice History',
        isShownInBar: false,
      },
      {
        name: 'EOR Payroll History',
        href: AdminRoutes.EORPayrollHistory,
        helperLabel: 'EOR Payroll History',
        isShownInBar: false,
        onlyShownTo: 'hasEOREmployees',
      },
      {
        name: 'W2 Payroll History',
        href: AdminRoutes.PayrollHistory,
        helperLabel: 'W2 Payroll History',
        isShownInBar: false,
        onlyShownTo: 'w2Admin',
      },
    ],
    showInMainNav: true,
  },
  { name: 'Finance', href: AdminRoutes.Finance, icon: <Finance />, showInMainNav: true },
  { name: 'Apps', href: AdminRoutes.Apps, icon: <AppsIcon />, showInMainNav: true },
  {
    name: '',
    icon: <Settings />,
    href: AdminRoutes.AccountPreferences,
    subNavs: [
      {
        name: 'personal',
        href: '/',
        isShownInBar: true,
        category: 'Personal',
        subNavs: [
          {
            name: 'Profile',
            icon: <UserIcon className="h-5 w-5" />,
            href: AdminRoutes.ProfileSettings,
            helperLabel: 'Profile',
            isShownInBar: true,
          },
          {
            name: 'Security',
            icon: <SecurityLockIcon className="h-5 w-5" />,
            href: AdminRoutes.SecuritySettings,
            helperLabel: 'Security',
            isShownInBar: true,
          },
        ],
      },
      {
        name: 'company',
        href: '/',
        isShownInBar: true,
        category: 'Company',
        subNavs: [
          {
            name: 'General',
            icon: <BuildingOffice2Icon className="h-5 w-5" />,
            href: AdminRoutes.AccountPreferences,
            helperLabel: 'General Settings',
            isShownInBar: true,
          },
          {
            name: 'Managers',
            icon: <UsersIcon className="h-5 w-5" />,
            href: AdminRoutes.Managers,
            helperLabel: 'Company Admins',
            isShownInBar: true,
            onlyShownTo: 'orgAdmin',
          },
          {
            name: 'Teams',
            icon: <TeamsIcon />,
            href: AdminRoutes.Teams,
            helperLabel: 'Teams',
            isShownInBar: true,
            onlyShownTo: 'permV2',
          },
          {
            name: 'Payment Methods',
            href: AdminRoutes.PaymentMethods,
            helperLabel: 'Payment Methods',
            isShownInBar: true,
            icon: <PaymentMethodIcon />,
          },
          { name: 'W2 Settings', href: AdminRoutes.W2Settings, helperLabel: 'W2 Settings', isShownInBar: false },
        ],
      },
    ],
    showInMainNav: false,
    isTabbedQuery: true,
  },
];

export const userNavigation: NavItem[] = [
  {
    name: 'Home',
    icon: <HomeDashboard />,
    href: ContractorRoutes.Dashboard,
    // subNavs: [{ name: 'Dashboard', href: ContractorRoutes.Dashboard, helperLabel: 'Dashboard', isShownInBar: true }],
  },
  {
    name: 'Payroll',
    onlyShownTo: 'contractor',
    icon: <Payroll />,
    href: ContractorRoutes.InvoiceHistory,
    subNavs: [
      {
        name: 'Invoices',
        href: ContractorRoutes.InvoiceHistory,
        helperLabel: 'All Invoices',
        isShownInBar: true,
        icon: <Invoices />,
      },
      {
        name: 'Withdrawals',
        href: ContractorRoutes.Withdrawals,
        helperLabel: 'Withdrawals',
        isShownInBar: true,
        icon: <Transactions />,
        userFlags: {
          paymentsVersion: 2,
        },
      },
      {
        name: 'Compliance',
        href: ContractorRoutes.ComplianceDocs,
        helperLabel: 'Compliance Documents, ID Verification',
        isShownInBar: true,
        icon: <ComplianceDocsIcon />,
      },
    ],
  },
  {
    name: 'Payroll',
    onlyShownTo: 'eorEmployee',
    icon: <Payroll />,
    href: ContractorRoutes.EOREmployeePayslips,
  },
  {
    name: 'Payroll',
    onlyShownTo: 'employee',
    icon: <Payroll />,
    href: ContractorRoutes.InvoiceHistory,
    subNavs: [
      {
        name: 'Paystubs',
        href: ContractorRoutes.EmployeePaystubs,
        helperLabel: 'All Paystubs',
        onlyShownTo: 'employee',
        isShownInBar: true,
      },
      // { name: 'Benefits', href: ContractorRoutes.EmployeeBenefits, helperLabel: 'All Benefits', isShownInBar: true },
    ],
  },
  {
    name: 'Withdraw',
    href: ContractorRoutes.Withdraw,
    helperLabel: 'Withdraw Balance',
    showInMainNav: false,
    userFlags: {
      paymentsVersion: 2,
    },
  },
  {
    name: 'Resources',
    onlyShownTo: 'contractor',
    href: ContractorRoutes.WithdrawalMethods,
    icon: <Resources />,
    showInMainNav: false,
    subNavs: [
      {
        name: 'Withdrawal Methods',
        href: ContractorRoutes.WithdrawalMethods,
        helperLabel: 'Manage Withdrawal Methods',
        isShownInBar: true,
        icon: <AccountManagementIcon />,
      },
    ],
  },
  { name: 'Finance', href: ContractorRoutes.Finance, icon: <Finance />, showInMainNav: true },
  { name: 'Apps', href: ContractorRoutes.Apps, icon: <AppsIcon /> },
  {
    name: '',
    icon: <Settings />,
    href: ContractorRoutes.AccountPreferences,
    subNavs: [
      {
        name: 'General',
        href: ContractorRoutes.AccountPreferences,
        helperLabel: 'General Settings',
        isShownInBar: true,
        icon: <BuildingOffice2Icon className="h-5 w-5" />,
      },
      {
        name: 'Security',
        icon: <SecurityLockIcon className="h-5 w-5" />,
        href: ContractorRoutes.SecuritySettings,
        helperLabel: 'Security',
        isShownInBar: true,
      },
    ],
    showInMainNav: false,
    isTabbedQuery: true,
  },
];
