import {
  CompanyAffiliation,
  CompanyPermissions,
  TeamAndDetails,
  TeamRoleAndDetails,
} from '@thera-hr/api-sdk/api/resources/api/resources/thera/resources/company';
import { logError } from './error';
import { ICompany, IEmployeeContract, IEmployeeWithContractDetails, IUser } from 'types';
import { DropdownValue } from 'components/generic-components/dropdown/type';

export const LS_key_Current_loggedIn_companyId = 'currentLoggedInAsCompanyId';
export const LS_key_Current_loggedIn_as_RoleType = 'currentLoggedInAsRoleType';

type TeamId = string;

interface ChangeResult {
  rolesToAdd?: Record<TeamId, TeamRoleAndDetails>;
  teamsToRemove?: TeamId[];
}

export function calculateTeamRoleChanges(
  currentAssignedTeams: Record<TeamId, TeamRoleAndDetails>,
  initialAssignedTeams: Record<TeamId, TeamRoleAndDetails>,
): ChangeResult | null {
  const result: ChangeResult = {};

  // Check for teams to remove
  result.teamsToRemove = Object.keys(initialAssignedTeams).filter((teamId) => !(teamId in currentAssignedTeams));

  // Check for roles to add
  result.rolesToAdd = {};
  for (const teamId in currentAssignedTeams) {
    const currentTeam = currentAssignedTeams[teamId];
    const initialTeam = initialAssignedTeams[teamId];

    if (!initialTeam) {
      // Team is not present in initialAssignedTeams, add it to rolesToAdd
      result.rolesToAdd[teamId] = currentTeam;
    } else if (
      currentTeam.role !== initialTeam.role ||
      JSON.stringify(currentTeam.permissions) !== JSON.stringify(initialTeam.permissions)
    ) {
      // Team role or permissions have changed, add it to rolesToAdd
      result.rolesToAdd[teamId] = currentTeam;
    }
  }

  // If no changes detected, return null
  if (Object.keys(result.rolesToAdd).length === 0 && result.teamsToRemove.length === 0) {
    return null;
  }

  return result;
}

export interface IOption {
  label: 'Admin' | 'Manager' | 'Contractor' | 'Employee';
  type: 'manager' | 'worker';
  companyId: string;
}

export const getAccountSwitchingOptions = (affiliation: CompanyAffiliation) => {
  const canUseAsContractor = affiliation.contracts?.length > 0;
  const isOrgAdmin = affiliation?.orgRoleAndDetails?.role === 'role_admin';
  const canUseAsManager =
    Object.keys(affiliation?.teams).length > 0 ||
    affiliation?.orgRoleAndDetails?.role === 'role_admin' ||
    affiliation?.orgRoleAndDetails?.role === 'role_manager';
  const options: IOption[] = [];
  if (canUseAsManager) {
    options.push({
      label: isOrgAdmin ? 'Admin' : 'Manager',
      type: 'manager',
      // @ts-ignore
      companyId: affiliation.company.companyID,
    });
  }
  const workerLabel = affiliation.contracts.find((contract) => contract.type === 'CONTRACTOR')
    ? 'Contractor'
    : 'Employee';
  if (canUseAsContractor) {
    options.push({
      label: workerLabel,
      type: 'worker',
      // @ts-ignore
      companyId: affiliation.company.companyID,
    });
  }
  return options;
};

// check local storage for current role type and company id
export const get_ls_saved_values = () => {
  if (typeof window === 'undefined') return null;
  if (!window?.localStorage) return null;
  try {
    const saved_roleType = window.localStorage.getItem(LS_key_Current_loggedIn_as_RoleType);
    const saved_companyId = window.localStorage.getItem(LS_key_Current_loggedIn_companyId);
    return {
      roleType: saved_roleType as 'manager' | 'worker',
      companyId: saved_companyId as string,
    };
  } catch (error) {
    logError(error);
  }
};

export const getEmployeeWithContractAndDocument = (
  contractId: string,
  employeeContracts: IEmployeeWithContractDetails,
) => {
  const contractAndContractDocument = employeeContracts?.contractsAndContractDocuments?.find(
    (item) => item.contract.id === contractId,
  );
  const employeeWithContract = {
    employee: employeeContracts?.employee,
    contract: contractAndContractDocument?.contract,
    document: contractAndContractDocument?.document,
    company: employeeContracts?.company,
  };
  return employeeWithContract;
};

export const checkIfManagerWithoutTeams = (
  roleType: 'manager' | 'worker',
  affiliationsByCompany: Record<string, CompanyAffiliation>,
  currentCompany: ICompany,
) => {
  return (
    roleType === 'manager' &&
    (!affiliationsByCompany?.[currentCompany?.companyID]?.teams ||
      Object.keys(affiliationsByCompany?.[currentCompany?.companyID]?.teams)?.length === 0)
  );
};

export const getTeamAdminsOptions = (
  managers: {
    user: IUser;
    companyPermissions: CompanyPermissions;
  }[],
  selectedTeamId: string,
): DropdownValue[] => {
  const options = [];
  managers?.forEach((manager) => {
    const isTeamAdmin = manager.companyPermissions.teams[selectedTeamId]?.role === 'role_admin';
    // if not team admin, don't show
    if (isTeamAdmin)
      options.push({
        label: `${manager.user.firstName} ${manager.user.lastName}`,
        value: manager.user.userID,
        id: manager.user.userID,
        additionalValue: manager.user.email,
      });
  });
  return options;
};

export const getOrgAdminsOptions = (
  managers: {
    user: IUser;
    companyPermissions: CompanyPermissions;
  }[],
): DropdownValue[] => {
  const options = [];
  managers?.forEach((manager) => {
    const isOrgAdmin = manager.companyPermissions.org.role === 'role_admin';
    // if not org admin, don't show
    if (isOrgAdmin)
      options.push({
        label: `${manager.user.firstName} ${manager.user.lastName}`,
        value: manager.user.userID,
        id: manager.user.userID,
        additionalValue: manager.user.email,
      });
  });
  return options;
};

export const getAvailableTeamsToSwitch = (
  allTeams: Record<string, TeamAndDetails>,
  companyTeams: Record<string, TeamRoleAndDetails>,
) => {
  if (!allTeams) return {};
  // collect only teams that are available in the company
  const availableTeams: Record<string, TeamAndDetails> = Object.keys(allTeams).reduce((acc, teamId) => {
    if (companyTeams?.[teamId]) {
      acc[teamId] = allTeams[teamId];
    }
    return acc;
  }, {});
  return availableTeams;
};

/**
 * Get the number of active contracts for a team
 * @param teamContractIds - array of contract ids string[] (both active and inactive)
 * @param companyContracts - array of company contracts IEmployeeContract[]
 * @returns number
 * */
export const getActiveContractsCount = (teamContractIds: string[], companyContracts: IEmployeeContract[]) => {
  if (!teamContractIds || !teamContractIds?.length) return 0;
  if (!companyContracts || !companyContracts?.length) return 0;
  const activeCompanyContracts = companyContracts?.filter(
    (contract) => contract.employment.employmentStatus === 'ACTIVE',
  );
  const activeContracts = activeCompanyContracts?.filter((contract) =>
    teamContractIds?.includes(contract.employment.id),
  );
  return activeContracts?.length;
};
