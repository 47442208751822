import { useStytchUser } from '@stytch/nextjs';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import GenericModal from 'components/app-components/modals/GenericModal';
import ProfileCircle from 'components/generic-components/ProfileCircle';
import Button from 'components/generic-components/button';
import theraToast from 'components/generic-components/toast/TheraToast';
import { useUser } from 'context/user';
import CheckMark from 'icons/CheckMark';
import { EmployeeType } from 'lib/constants';
import { ICompany, IEmployment } from 'types';
import { logError } from 'utils/error';
import { getInitialsFromName } from 'utils/string';
import {
  LS_key_Current_loggedIn_as_RoleType,
  LS_key_Current_loggedIn_companyId,
  getAccountSwitchingOptions,
} from 'utils/affiliations';
import { updateIntercomSettings } from 'utils/intercom';
import { CommonRoutes } from 'routes';
import CopyableText from 'components/generic-components/copyableText';
import { showIDToCopy } from 'utils/user';

const SwitchAccountModal = ({ isOpen, onClose }) => {
  const { user: stytchUser } = useStytchUser();
  const {
    user,
    setUser,
    setPermissionsVersion,
    currentCompany,
    affiliationsByCompany,
    isLoading,
    roleType: currentUserRoleType,
    setRoleType,
    setCurrentContract,
    setCurrentCompany,
    setCurrentContractId,
    setCurrentTeamId,
    setShowAccountSwitcher,
    logout,
  } = useUser();

  const showCompanyIDToCopy = useMemo(() => {
    return showIDToCopy(affiliationsByCompany);
  }, [affiliationsByCompany]);

  if (isLoading || !affiliationsByCompany) return null;

  const handleOnClose = () => {
    // return if no current role
    if (!currentUserRoleType) {
      theraToast.info('Please select a role to sign in as', {
        id: 'no-current-role',
      });
      return;
    }
    onClose();
  };

  const createAt = DateTime.fromISO(stytchUser?.created_at).toSeconds();
  const intercomValues = {
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: currentUserRoleType,
    user_id: user.userID,
    created_at: createAt,
  };

  const handleSignInAs = async (roleType: 'worker' | 'manager', companyId: string) => {
    const company = affiliationsByCompany[companyId].company;
    // set current company and role type
    setCurrentCompany(company as unknown as ICompany);
    setRoleType(roleType);

    // set local storage
    try {
      window.localStorage.setItem(LS_key_Current_loggedIn_companyId, companyId);
      window.localStorage.setItem(LS_key_Current_loggedIn_as_RoleType, roleType);
    } catch (error) {
      logError(error);
    }

    if (roleType === 'worker') {
      // load worker first contract
      const firstContract = affiliationsByCompany[companyId].contracts[0];
      setCurrentContract(firstContract as unknown as IEmployment);
      setCurrentContractId(firstContract.id);
      const employmentType = firstContract.type as EmployeeType;
      // @ts-ignore
      setUser((user) => ({
        ...user,
        userEmploymentType: employmentType as EmployeeType,
      }));
    } else if (roleType === 'manager') {
      // if manager, set roleType to manager, set first team as current team
      const firstTeamId = Object.keys(affiliationsByCompany[companyId].teams)[0];
      // set permissions version to current company's permissions version
      const companyPermissionsVersion = company?.flags?.permissionsVersion;
      setPermissionsVersion(companyPermissionsVersion);
      setCurrentTeamId(firstTeamId);
    } else {
      logError('Invalid role type');
    }
    updateIntercomSettings({
      ...intercomValues,
      role: roleType,
    });
    window.location.replace(CommonRoutes.Dashboard);
    setShowAccountSwitcher(false);
    // redirect to home page and reload
    // await router.replace(CommonRoutes.Dashboard);
    onClose();
  };

  return (
    <GenericModal
      open={isOpen}
      onClose={handleOnClose}
      title="Select Account to Use"
      showHeaderBorder={false}
      width="min-w-lg"
    >
      <div className="space-y-2">
        {Object.keys(affiliationsByCompany).map((companyId) => {
          const companyName = affiliationsByCompany[companyId].company.companyName;
          const companyInitials = getInitialsFromName(companyName);
          const isCurrentCompany = currentCompany?.companyID === companyId;
          const currentlySignedInRoleType = currentUserRoleType;
          const affiliation = affiliationsByCompany[companyId];
          const switchingOptions = getAccountSwitchingOptions(affiliation);

          return (
            <div key={companyId} className="rounded-md border border-neutral-100">
              <div className="flex w-full items-center justify-between space-x-3">
                <div className="flex items-center space-x-3 p-4">
                  <ProfileCircle text={companyInitials} height="h-8" width="w-8" id={companyInitials} />
                  <p className="text-base font-medium text-neutral-900">{companyName}</p>
                  {showCompanyIDToCopy && (
                    <CopyableText text={companyId} textClassName="text-xs font-medium text-neutral-500 mt-1" />
                  )}
                </div>
              </div>
              {switchingOptions.length > 0 &&
                switchingOptions.map((option) => {
                  // if current role at current company, don't show option
                  const isSignedInAs = isCurrentCompany && option.type === currentlySignedInRoleType;
                  if (isSignedInAs) {
                    return (
                      <div
                        key={option.type}
                        className={`flex items-center justify-between space-x-1 px-4 py-3 ${
                          isSignedInAs ? 'bg-neutral-100' : ''
                        }`}
                      >
                        <span
                          className="text-sm font-medium text-neutral-600"
                          onClick={() => handleSignInAs(option.type, companyId)}
                        >
                          Signed in as {option.label}
                        </span>
                        <div className="flex items-center space-x-1">
                          <CheckMark fill="blue" className="h-5 w-5" />
                          <span className="text-xs font-medium text-neutral-900">Signed in</span>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <Button
                      variant="plain"
                      key={option.type}
                      className={`flex w-full items-center justify-between space-x-1 border-t border-neutral-100 px-4 py-3 ${
                        isSignedInAs ? 'bg-neutral-100' : ''
                      }`}
                      onClick={() => handleSignInAs(option.type, companyId)}
                    >
                      <span className="text-sm font-medium text-therablue underline">Sign in as {option.label}</span>
                    </Button>
                  );
                })}
            </div>
          );
        })}
      </div>
      {!currentUserRoleType && (
        <div className="mt-3">
          <Button variant="plain" onClick={() => logout()}>
            <span className="text-xs text-therablue underline">Logout</span>
          </Button>
        </div>
      )}
    </GenericModal>
  );
};

export default SwitchAccountModal;
