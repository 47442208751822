import React from 'react'

const AppsIcon = ({ className='' }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.71503 7.66175V3.0007C1.71503 1.89613 2.61046 1.0007 3.71503 1.0007H8.37608C9.48065 1.0007 10.3761 1.89613 10.3761 3.0007V7.66176C10.3761 8.76632 9.48065 9.66175 8.37608 9.66175H3.71503C2.61046 9.66175 1.71503 8.76632 1.71503 7.66175Z"
        stroke="#404040"
        strokeWidth="1.5"
      />
      <path
        d="M1.71503 19.5707V14.9096C1.71503 13.8051 2.61046 12.9096 3.71503 12.9096H8.37608C9.48065 12.9096 10.3761 13.8051 10.3761 14.9096V19.5707C10.3761 20.6753 9.48065 21.5707 8.37608 21.5707H3.71503C2.61046 21.5707 1.71503 20.6753 1.71503 19.5707Z"
        stroke="#404040"
        strokeWidth="1.5"
      />
      <path
        d="M13.624 19.5707V14.9096C13.624 13.8051 14.5194 12.9096 15.624 12.9096H20.285C21.3896 12.9096 22.285 13.8051 22.285 14.9096V19.5707C22.285 20.6753 21.3896 21.5707 20.285 21.5707H15.624C14.5194 21.5707 13.624 20.6753 13.624 19.5707Z"
        stroke="#404040"
        strokeWidth="1.5"
      />
      <path
        d="M18.1824 9.66175V5.07649M18.1824 1.0007V5.07649M13.624 5.07649H18.1824M18.1824 5.07649H22.285"
        stroke="#404040"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default AppsIcon;
