import Button from 'components/generic-components/button';
import CloseIcon from 'icons/Close';

interface CloseModalButtonProps {
  onClose: () => void;
  className?: string;
}

const CloseModalButton: React.FC<CloseModalButtonProps> = ({ onClose, className }) => {
  return (
    <Button
      variant="default"
      onClick={onClose}
      className={`inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white ${className}`}
      data-modal-toggle="small-modal"
    >
      <CloseIcon />
      <span className="sr-only">Close modal</span>
    </Button>
  );
};

export default CloseModalButton;
