import Link from 'next/link';

export const AuthContainer = ({ children, verticallyCentered = true }) => {
  return (
    <div className="auth-layout-container min-h-full bg-white">
      <div className={`flex min-h-screen flex-col items-center${verticallyCentered ? ' justify-center' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export const OnboardingContainer = ({ children }) => {
  return (
    <div className="auth-layout-container flex min-h-full flex-col items-center bg-white p-12">
      <div className="mb-12">
        <img className="mx-auto mb-6 h-9" src="/logo/thera-logo.svg" alt="Thera-logo" />
      </div>
      <div
        className={`mb-12 min-h-[700px] min-w-[1024px] max-w-screen-lg items-center justify-center rounded-xl border border-gray-200 p-12`}
      >
        {children}
      </div>
    </div>
  );
};

const PageLogoTitle = ({ pageTitle }) => {
  return (
    <>
      <Link href="/">
        <img className="mx-auto mb-6 h-9" src="/logo/thera-logo.svg" alt="Thera-logo" />
      </Link>
      <h1 className="text-center text-xl font-semibold text-neutral-800" data-testid="pageTitle">
        {pageTitle}
      </h1>
    </>
  );
};

const LoginLayout = ({ children, pageTitle = 'Welcome to Thera', showRightContainer = true }) => {
  return (
    <AuthContainer>
      <div className="mx-auto max-w-screen-lg">
        <div className="w-full rounded-xl border border-gray-200 bg-white p-12">
          <div className={`${showRightContainer ? 'grid grid-cols-2 gap-24' : 'min-w-sm max-w-sm'}`}>
            <div>
              {!showRightContainer && (
                <div className="mb-12 flex flex-col items-center justify-center">
                  <PageLogoTitle pageTitle={pageTitle} />
                </div>
              )}
              {children}
            </div>
            {showRightContainer && (
              <div className="flex flex-col items-start justify-start">
                <PageLogoTitle pageTitle={pageTitle} />
                <p className="mt-6 text-sm font-medium text-neutral-500">
                  Hire, pay, and manage your distributed workforce from Thera's world class platform. Hire anyone,
                  anywhere. Industry-leading exchange rates. Available in 135+ countries.
                  <br />
                  <br />
                  Let’s begin.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default LoginLayout;
