export default function OrganisationIcon({ className = '' }) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="10.498" y="6.5" width="10.8576" height="6.57176" rx="1.85725" stroke="black" strokeWidth="2" />
      <path
        d="M15.9298 13.5361V17.1076M23.7872 19.9649V19.9649C23.7872 18.3869 22.508 17.1076 20.93 17.1076H9.36003C7.89914 17.1076 6.71484 18.2919 6.71484 19.7528V19.7528"
        stroke="black"
        strokeWidth="2"
      />
      <rect x="2" y="20.6523" width="10.8576" height="6.57176" rx="1.85725" stroke="black" strokeWidth="2" />
      <rect x="19.1445" y="20.7528" width="10.8576" height="6.57176" rx="1.85725" stroke="black" strokeWidth="2" />
    </svg>
  );
}
