import React from 'react'

const Finance = ({ className=''}) => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M4.07742 8.91214V18.4059M8.69231 8.91214V18.4059M13.3072 8.91214V18.4059M17.9221 8.91214V18.4059M18.8358 18.4061H3.16422C2.78143 18.4061 2.43223 18.6246 2.26487 18.9689L1.69871 20.1335C1.37581 20.7977 1.85954 21.5707 2.59806 21.5707H19.4019C20.1405 21.5707 20.6242 20.7977 20.3013 20.1335L19.7351 18.9689C19.5678 18.6246 19.2186 18.4061 18.8358 18.4061ZM21 7.12801V7.91213C21 8.46442 20.5523 8.91213 20 8.91213H2C1.44772 8.91213 1 8.46442 1 7.91213V7.12801C1 6.76436 1.19741 6.42938 1.51553 6.2532L10.5155 1.269C10.8169 1.10208 11.1831 1.10208 11.4845 1.269L20.4845 6.2532C20.8026 6.42938 21 6.76436 21 7.12801Z"
        stroke="#404040"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Finance;
