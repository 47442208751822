export default function TeamIcon({ className = '' }) {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="6.96922" y="0.531719" width="7.96" height="4.96" rx="1.48" stroke="black" strokeWidth="1.04" />
      <path
        d="M10.9492 5.63672V8.13672M16.4492 10.1367V10.1367C16.4492 9.03215 15.5538 8.13672 14.4492 8.13672H6.35059C5.328 8.13672 4.49902 8.96569 4.49902 9.98828V9.98828"
        stroke="black"
      />
      <rect x="1.02" y="10.438" width="7.96" height="4.96" rx="1.48" stroke="black" strokeWidth="1.04" />
      <rect x="13.019" y="10.5083" width="7.96" height="4.96" rx="1.48" stroke="black" strokeWidth="1.04" />
    </svg>
  );
}
