/**
 * This file contains all the routes used in the application.
 * It is used to avoid hardcoding the routes in the application.
 * Update the routes here and use them in the application.
 */

/**
 * Auth routes for the application. i.e. Sign In, Sign Up, Reset Password, etc.
 */
export const AuthRoutes = {
  SignIn: '/sign-in',
  SignUp: '/sign-up',
  SignUpOnboarding: '/sign-up/onboarding',
  SignUpContractorOnboarding: '/sign-up/contractor-onboarding',
  SignUpNeedsBooking: '/sign-up/book-meeting',
  ResetPassword: '/reset-password',
  Authenticate: '/authenticate',
  Logout: '/logout',
  EmailVerification: '/verify-email',
  SuperAdmin: '/super-admin',
};

/**
 * Admin routes for the application.
 * These routes are only accessible to the admin users.
 */
export const AdminRoutes = {
  Dashboard: '/home',
  PayrollOnboarding: '/payroll-onboarding',
  Team: '/team',
  AddTeam: '/team/add',
  ManageTeam: '/team/manage',
  Contractors: '/contractors',
  EOR: '/eor',
  EORPayrollHistory: '/eor/payroll-history',
  Payments: '/payments',
  Documents: '/documents',
  Invoices: '/admin-invoice',
  OpenInvoice: '/admin-invoice?action=openInvoice',
  InvoicesHistory: '/admin-invoice?selectedTab=history',
  ReviewInvoices: '/admin-invoice/review',
  PayApprovedInvoices: '/admin-invoice/pay-approved',
  AddEmployee: '/team/employee/add',
  AddContractor: '/team/contractor/add',
  AccountPreferences: '/account-preferences',
  ProfileSettings: '/account-preferences/profile',
  SecuritySettings: '/account-preferences/security',
  Managers: '/account-preferences/managers',
  Teams: '/account-preferences/teams-settings',
  W2Settings: '/account-preferences/w2-settings',
  PaymentMethods: '/account-preferences/payments',
  Billing: '/account-preferences?selectedTab=billing',
  EOREmployeeProfile: '/team/eor-employee',
  EOREmployeeProfileByID: '/team/eor-employee/[userID]',
  ContractorProfile: '/team/contractor',
  ContractorProfileByID: '/team/contractor/[userID]',
  EmployeeProfile: '/team/employee',
  EmployeeProfileByID: '/team/employee/[employeeID]',
  Taxes: '/taxes',
  FederalTaxes: '/taxes/federal',
  StateTaxes: '/taxes/[state]',
  ZeroWageFilings: '/taxes/zero-wage-filings',
  RegisterNewState: '/taxes/register-state',
  Calendly: '/calendly',
  Finance: '/finance',
  Apps: '/apps',
  // History: '/history',
  PayrollHistory: '/payroll/history',
  RegularPayrollHistory: '/payroll/history/[employerCheckID]',
  Payroll: '/payroll',
  PayrollRun: '/payroll/run',
  PayrollRunOffCycle: '/payroll/off-cycle',
  WorkLocations: '/payroll/work-locations',
  EmployeeChecks: '/payroll/employee-checks',
  PendingEmployeeCheck: '/payroll/employee-checks/[employeeCheckID]',
  Deductions: '/payroll/deductions',
  Earnings: '/payroll/earnings',
  DeductionTypes: '/payroll/deductions/[deductionType]', // Garnishment, Section 125, HSA, 401k
  EarningTypes: '/payroll/earnings/[earningType]', // Garnishment, Section 125, HSA, 401k
  Garnishment: '/payroll/deductions/garnishment',
  Section125: '/payroll/deductions/section125',
  HSA: '/payroll/deductions/hsa',
  '401k': '/payroll/deductions/401k',
  Reimbursements: '/payroll/earnings/reimbursements',
};

/**
 * Contractor routes for the application.
 * These routes are only accessible to the contractor users.
 */
export const ContractorRoutes = {
  Dashboard: '/home',
  EmployeeOnboarding: '/employee-onboarding',
  InvoiceHistory: '/invoice-history',
  Apps: '/apps',
  Finance: '/finance',
  AccountPreferences: '/account-preferences',
  SecuritySettings: '/account-preferences/security',
  Withdraw: '/withdraw',
  Withdrawals: '/withdrawals',
  WithdrawalMethods: '/withdrawal-methods',
  ComplianceDocs: '/compliance-documents',
  CompanyProfile: '/contracts/[contractID]',
  ContractSign: '/contracts/[contractID]/sign-contracts',
  Contracts: '/contracts',
  EmployeePaystubs: '/paystubs',
  EmployeeBenefits: '/benefits',
  EmployerProfile: '/employer-profile/[companyID]',
  EOREmployeePayslips: '/payslips',
};

/**
 * Common routes for the application.
 */
export const CommonRoutes = {
  Dashboard: '/home',
  NotFound: '/404',
};
