import ReloadIcon from 'icons/Reload';
import Button from 'components/generic-components/button';
import Navbar from './navbar/Navbar';
import Head from 'next/head';

// Handling Routing inside ErrorBoundary
// Buttons are used to navigate to different pages here because routing doesn't work in the fallback UI due to the error boundary
// Alternate solution:
// https://dev.to/tylerlwsmith/error-boundary-causes-react-router-links-to-stop-working-50gb

const FallbackUI = () => {
  return (
    <>
      <Head>
        <title>Oops, something went wrong | Thera</title>
      </Head>
      <header>
        <Navbar isFallback={true} />
      </header>
      <main className="grid flex-1 grid-cols-[1fr] grid-rows-[1fr]">
        <div className="flex flex-col items-center justify-center">
          <div className="flex max-w-lg flex-col items-center justify-center text-center">
            <h1 className="mb-5 text-xl font-medium text-neutral-800">Oops, something went wrong. </h1>
            <p className="mb-5 text-neutral-600">
              It looks like there was an error while processing your request.
              <br />
              Reach out via Slack if this issue continues.
            </p>
            <div>
              <Button
                variant="default"
                className="flex items-center text-therablue"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <ReloadIcon className="mr-2 h-5 w-5" />
                <span>Refresh Page</span>
              </Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default FallbackUI;
