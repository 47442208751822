export default function ContractorsIcon({ className = '' }) {
  return (
    <svg
      className={className}
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.07718 0.811917L9.32718 0.809544C10.7083 0.809019 11.8281 1.92846 11.8281 3.30954V12.8962C11.8281 14.2769 10.7088 15.3962 9.32812 15.3962H3.07812C1.69741 15.3962 0.578125 14.2769 0.578125 12.8962V3.31192C0.578125 1.93158 1.69683 0.812441 3.07718 0.811917Z"
        stroke="#1F2937"
        strokeWidth="1.04167"
      />
      <path d="M3 4H6.33333" stroke="#1F2937" strokeWidth="1.04167" strokeLinecap="round" />
      <path d="M3 7.33398H9.66667" stroke="#1F2937" strokeWidth="1.04167" strokeLinecap="round" />
      <path
        d="M10.1613 17.1927C13.0665 17.1927 15.4217 14.8375 15.4217 11.9323C15.4217 9.02704 13.0665 6.67188 10.1613 6.67188C7.25605 6.67188 4.90088 9.02704 4.90088 11.9323C4.90088 14.8375 7.25605 17.1927 10.1613 17.1927Z"
        fill="white"
        stroke="#1F2937"
        strokeWidth="1.04167"
      />
      <path
        d="M10.1611 14.5618V15.0879V14.5618ZM10.1611 8.77539V9.30143V8.77539ZM11.7393 10.6165C11.7393 9.8906 11.0328 9.30143 10.1611 9.30143C9.28948 9.30143 8.58301 9.8906 8.58301 10.6165C8.58301 11.3425 9.28948 11.9316 10.1611 11.9316C11.0328 11.9316 11.7393 12.5208 11.7393 13.2467C11.7393 13.9727 11.0328 14.5618 10.1611 14.5618C9.28948 14.5618 8.58301 13.9727 8.58301 13.2467"
        fill="white"
      />
      <path
        d="M10.1611 14.5618V15.0879M10.1611 14.5618C11.0328 14.5618 11.7393 13.9727 11.7393 13.2467C11.7393 12.5208 11.0328 11.9316 10.1611 11.9316C9.28948 11.9316 8.58301 11.3425 8.58301 10.6165C8.58301 9.8906 9.28948 9.30143 10.1611 9.30143M10.1611 14.5618C9.28948 14.5618 8.58301 13.9727 8.58301 13.2467M10.1611 8.77539V9.30143M10.1611 9.30143C11.0328 9.30143 11.7393 9.8906 11.7393 10.6165"
        stroke="#1F2937"
        strokeWidth="1.04167"
        strokeLinecap="round"
      />
    </svg>
  );
}
