import Link from 'next/link';

import Button from 'components/generic-components/button';
import MaintenanceIcon from 'icons/MaintenanceIcon';
import { CommonRoutes } from 'routes';

const MaintenanceMode = ({ children }) => {
  const isMaintenanceMode = process.env.NEXT_PUBLIC_THERA_MAINTENANCE_MODE === 'true';
  const openIntercom = () => {
    if (!(window as any).Intercom) return;
    (window as any).Intercom('show');
  };

  if (!isMaintenanceMode) return children;

  return (
    <>
      <header className="fixed top-0 z-10 h-[64px] w-[100vw] border-b border-neutral-200 bg-neutral-800 px-6 py-3">
        <div className="mx-auto flex max-w-screen-lg items-center justify-center py-2.5">
          <Link href={CommonRoutes.Dashboard} className="flex items-center justify-center">
            <img className="h-6 w-auto" src="/logo/thera-logo-white.svg" alt="Thera-logo" />
          </Link>
        </div>
      </header>
      <main className="h-screen overflow-x-hidden px-6 pt-[64px]">
        <div className="mx-auto flex h-full w-full max-w-lg flex-col items-center justify-center">
          <div className="flex h-12 w-12 items-center justify-center rounded-md border border-neutral-200 bg-neutral-50">
            <MaintenanceIcon />
          </div>
          <h1 className="mb-4 mt-6 text-center text-2xl font-medium text-neutral-800">Scheduled Maintenance</h1>
          <div className="mb-6 rounded-md border border-neutral-100 bg-neutral-50 p-6 text-center">
            <p className="mb-1 font-medium text-neutral-800">We have a scheduled maintenance going on</p>
            <p className="text-sm font-normal text-neutral-500">Check back in 30 minutes to continue using the app</p>
          </div>
          <p className="text-center text-sm font-normal text-neutral-600">
            Have any questions? Reach out to
            <br />
            <a href="mailto:support@getthera.com" className="underline">
              support@getthera.com
            </a>{' '}
            or{' '}
            <Button variant="plain" onClick={openIntercom}>
              chat with us here
            </Button>
          </p>
        </div>
      </main>
    </>
  );
};

export default MaintenanceMode;
