import { User } from 'stytch';
import { ICompany, IEmployment } from 'types';

export const THERA_SkipEmailVerify = 'thera__skipVerifyEmail';
export const THERA_SuperAdmin = 'isThera__superAdmin';
export const dummyEmail = `dummyEmailForSU@thera.com`;
export const SuperAdminPassword = process.env.NEXT_PUBLIC_THERA_SUPER_ADMIN_PWD;

export const isStytchProdEnv = process.env.NEXT_PUBLIC_STYTCH_PROJECT_ENV === 'test' ? false : true;
export const isAdvancedSuperAdminMode =
  process.env.NEXT_PUBLIC_THERA_IS_ADVANCED_SUPER_ADMIN_MODE === 'true' ? true : false;

/**
 * Checks if the user is in super admin mode
 * @returns boolean
 * */
export const isSuperAdminMode = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const isSuperAdmin = window.localStorage.getItem(THERA_SuperAdmin) === 'true';
    return isSuperAdmin;
  }
  return false;
};

/**
 * Checks if the user's email is verified, ignores email verification if super admin mode is on
 * @param stytchUser
 * @returns boolean
 */
export const checkIfEmailVerified = async (stytchUser: User) => {
  let isEmailVerified = false;
  const email = stytchUser?.emails ? stytchUser.emails[0].email : '';
  if (!stytchUser || !email) {
    return isEmailVerified;
  }
  // if super admin mode is on, skip email verification for the email in local storage
  if (isSuperAdminMode()) {
    // advanced super admin mode off means admin can skip any user's email verification
    if (isAdvancedSuperAdminMode) {
      const getEmailtoSkipVerification = window.localStorage.getItem(THERA_SkipEmailVerify);
      if (getEmailtoSkipVerification === email) {
        isEmailVerified = true;
      } else {
        isEmailVerified = false;
      }
    } else {
      isEmailVerified = true;
    }
  } else {
    isEmailVerified = stytchUser.emails ? stytchUser.emails[0].verified : false;
  }
  return isEmailVerified;
};

export const checkIfNewInvitedUserWithoutPasswordAndNoOAuth = (stytchUser: User) => {
  if (stytchUser && !stytchUser.password && !stytchUser?.providers?.length) {
    return true;
  }
  return false;
};

export const groupContractsByCompanyId = (
  contractsWithCompany: {
    contract: IEmployment;
    company: ICompany;
  }[],
): {
  [companyId: string]: { contracts: IEmployment[]; companyName: string; teams?: any };
} => {
  const groupedContracts = contractsWithCompany.reduce((acc, contractAndCompany) => {
    const { company, contract } = contractAndCompany;
    const { companyID, companyName = '' } = company || {};
    if (!acc[companyID]) {
      acc[companyID] = { contracts: [], companyName };
    }
    acc[companyID].contracts.push(contract);
    return acc;
  }, {});
  return groupedContracts;
};

export const showIDToCopy = (...dependencies: any[]) => {
  // return false if any of the arguments is null or undefined
  if (dependencies.some((dependency) => !dependency)) {
    return false;
  }
  if (isSuperAdminMode()) {
    return true;
  }
  if (!isStytchProdEnv) {
    return true;
  }
  return false;
};
