export function getCookie(key, req?: any) {
  if (typeof window === 'undefined') {
    // Server-side
    if (!req || !req.headers || !req.headers.cookie) {
      console.warn('No request object or headers found');
      return null;
    }
    const maybeRow = req.headers.cookie.split('; ').find((row) => row.startsWith(key + '='));
    if (!maybeRow) {
      return null;
    }
    return maybeRow.split('=')[1];
  } else {
    // Client-side
    const maybeRow = document.cookie.split('; ').find((row) => row.startsWith(key + '='));
    if (!maybeRow) {
      return null;
    }
    return maybeRow.split('=')[1];
  }
}

export function setCookie(key, value, options = {}) {
  let updatedCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);
  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }
  document.cookie = updatedCookie;
}
